import { createReducer } from '@reduxjs/toolkit';
import type { CompanyDepartmentDto } from 'generated-api/scenes/api.js';
import { getAllDepartments, getAllDepartmentsSuccess, getAllDepartmentsFailure } from 'app/actions/companyManagment/departmentActions.js';

interface DepartmentState {
  departments: CompanyDepartmentDto[];
  loading: boolean;
  error: string | null;
}

const initialState: DepartmentState = {
  departments: [],
  loading: false,
  error: null,
};

export const departmentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllDepartments, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAllDepartmentsSuccess, (state, action) => {
      state.departments = action.payload;
      state.loading = false;
      state.error = null;
    })
    .addCase(getAllDepartmentsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});
