import * as yup from 'yup';
import { RoleId } from '../../../models/users/role.js';

import type { UserFormData } from './types.js';
import { phoneRegExp } from 'app/components/CallManagement/schema.js';
import type { UserDto } from '@/generated-api/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScheduledCallsNs } from '@/i18n/dictionaries/interfaces.js';

const baseInitialValues: UserFormData = {
  id: 0,
  email: '',
  name: '',
  role: RoleId.MEMBER,
  companyId: 0,
  phone: '',
  departmentId: 0,
  deleted: false,
  companyName: null,
  hideScenarioContent: false,
  showCadForm: false,
};

function getInitialValues(currentUser: UserDto) {
  if (currentUser.role?.valueOf() === RoleId.SUPERADMIN) {
    return baseInitialValues;
  }

  if (currentUser.role?.valueOf() === RoleId.OWNER) {
    return {
      ...baseInitialValues,
      companyId: currentUser.companyId
    };
  }

  return {
    ...baseInitialValues,
    companyId: currentUser.companyId,
    departmentId: currentUser.departmentId ?? null
  };
}

const useUserFormValidationSchema = () => {
  const [translate] = useTranslation([I18nNamespace.ScheduledCalls]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return yup.object<Partial<UserFormData>>({
    role: yup
      .mixed<RoleId>()
      // .oneOf(Object.values(RoleId) as number[]) // TODO https://github.com/jquense/yup/issues/1013
      .required(translateCommon('This field is required')),
    phone: yup
      .string()
      .ensure()
      .matches(phoneRegExp, {
        message: translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.phoneNumberMissing)),
        excludeEmptyString: true
    })
  });
};

export const UserFormUtils = { getInitialValues, useUserFormValidationSchema };
