import type { I18nUserManagementNs } from './interfaces.js';

export const UserManagementDictionaryEn: I18nUserManagementNs = {
  search: 'Search',
  inviteUser: 'Invite user',
  inviteUserInExternalTenant: 'Invite user in external tenant',
  userTable: {
    id: 'ID',
    email: 'Email',
    name: 'Name',
    role: 'Role',
    organization: 'Organization',
    department: 'Department',
    status: 'Status',
    actions: 'Actions'
  },
  labels: {
    editUser: 'Edit user',
    deleteUser: 'Delete user',
    users: 'Users',
    invitations: 'Invitations'
  },
  userForm: {
    email: 'Email',
    name: 'Name',
    roles: 'Roles',
    company: 'Organization',
    phone: 'Phone number',
    department: 'Department',
    memberDescriptrion: 'Can use scenarios and attend courses.',
    adminDescription: 'Can manage users on their team, create scenarios, organize courses and schedule calls.',
    ownerDescription: 'Can do all of the above, plus manage organization and teams.',
    superAdminDescription: 'Can do all of the above, plus manage all organizations.',
    manageDepartments: 'Manage departments',
    departmentManager: 'Department manager',
    addDepartment: 'Add department',
    editDepartment: 'Edit department',
    deleteDepartment: 'Delete department',
    manageOrganizations: 'Manage organizations',
    organizationManager: 'Organization manager',
    addOrganization: 'Add organization',
    editOrganization: 'Edit organization',
    deleteOrganization: 'Delete organization',
    closeManager: 'Close manager',
    deleteUsers: 'Delete users',
    moveUsersTo: 'Move users to',
    theDepartmentContainsUsers: 'The department contains users',
    advancedSettings: 'Advanced Settings',
    hideScenarioContent: 'Hide scenario content',
    hideScenarioContentTooltip: 'Hide intent texts for this user when they start a scenario',
    showCadForm: 'Show CAD Form'
  },
  deleteUser: {
    confirmationText: 'Are you really sure you want to delete',
    cannotBeUndone: 'This cannot be undone!'
  },
  invitationsTable: {
    inviteeEmail: "Invitee Email",
    inviter: "Inviter",
    systemAdmin: "System Admin",
    systemAdminTooltip: "This invitation was made by someone outside your organization",
    status: "Status",
    createdTime: "Created Time",
    expirationTime: "Expiration Time",
    acceptedTime: "Accepted Time",
    na: "N/A",
    company: "Organization",
    department: "Department"
  },
};

export const UserManagementDictionaryDe: I18nUserManagementNs = {
  search: 'Suchen',
  inviteUser: 'Benutzer einladen',
  inviteUserInExternalTenant: 'Benutzer in externem Mandanten einladen',
  userTable: {
    id: 'ID',
    email: 'E-Mail',
    name: 'Name',
    role: 'Rollen',
    organization: 'Organisation',
    department: 'Abteilung',
    status: 'Status',
    actions: 'Aktionen'
  },
  labels: {
    editUser: 'Benutzer bearbeiten',
    deleteUser: 'Benutzer löschen',
    users: 'Benutzer',
    invitations: 'Einladungen'
  },
  userForm: {
    email: 'E-Mail',
    name: 'Name',
    roles: 'Rollen',
    company: 'Organisation',
    phone: 'Telefonnummer',
    department: 'Abteilung',
    memberDescriptrion: 'Kann Szenarien verwenden und an Kursen teilnehmen.',
    adminDescription: 'Kann Benutzer in ihrem Team verwalten, Szenarien erstellen, Kurse organisieren und Anrufe planen.',
    ownerDescription: 'Kann alles oben Genannte tun, plus Organisation und Teams verwalten.',
    superAdminDescription: 'Kann alles oben Genannte tun, plus alle Organisationen verwalten.',
    manageDepartments: 'Abteilungen verwalten',
    departmentManager: 'Abteilungsleiter',
    addDepartment: 'Abteilung hinzufügen',
    editDepartment: 'Abteilung bearbeiten',
    deleteDepartment: 'Abteilung löschen',
    manageOrganizations: 'Organisationen verwalten',
    organizationManager: 'Organisationsmanager',
    addOrganization: 'Organisation hinzufügen',
    editOrganization: 'Organisation bearbeiten',
    deleteOrganization: 'Organisation löschen',
    closeManager: 'Manager schließen',
    deleteUsers: 'Benutzer löschen',
    moveUsersTo: 'Benutzer verschieben nach',
    theDepartmentContainsUsers: 'Die Abteilung enthält Benutzer',
    advancedSettings: 'Erweiterte Einstellungen',
    hideScenarioContent: 'Szenario-Inhalt ausblenden',
    hideScenarioContentTooltip: 'Absichtstexte für diesen Benutzer ausblenden, wenn sie ein Szenario starten',
    showCadForm: 'CAD-Formular anzeigen'
  },
  deleteUser: {
    confirmationText: 'Sind Sie wirklich sicher, dass Sie löschen möchten',
    cannotBeUndone: 'Dies kann nicht rückgängig gemacht werden!'
  },
  invitationsTable: {
    inviteeEmail: "E-Mail des Eingeladenen",
    inviter: "Eingeladen von",
    systemAdmin: "System Administrator",
    systemAdminTooltip: "Diese Einladung wurde von jemandem außerhalb Ihrer Organisation erstellt",
    status: "Status",
    createdTime: "Erstellungszeit",
    expirationTime: "Ablaufzeit",
    acceptedTime: "Akzeptierte Zeit",
    na: "N/V",
    company: "Organisation",
    department: "Abteilung"
  },
};

export const UserManagementDictionarySw: I18nUserManagementNs = {
  search: 'Search',
  inviteUser: 'Invite user',
  inviteUserInExternalTenant: 'Invite user in external tenant',
  userTable: {
    id: 'ID',
    email: 'Email',
    name: 'Name',
    role: 'Roles',
    organization: 'Organization',
    department: 'Department',
    status: 'Status',
    actions: 'Actions'
  },
  labels: {
    editUser: 'Edit user',
    deleteUser: 'Delete user',
    users: 'Användare',
    invitations: 'Inbjudningar'
  },
  userForm: {
    email: 'Email',
    name: 'Name',
    roles: 'Role',
    company: 'Organization',
    phone: 'Phone number',
    department: 'Department',
    memberDescriptrion: 'Can use scenarios and attend courses.',
    adminDescription: 'Can manage users on their team, create scenarios, organize courses and schedule calls.',
    ownerDescription: 'Can do all of the above, plus manage organization and teams.',
    superAdminDescription: 'Can do all of the above, plus manage all organizations.',
    manageDepartments: 'Manage departments',
    departmentManager: 'Department manager',
    addDepartment: 'Add department',
    editDepartment: 'Edit department',
    deleteDepartment: 'Delete department',
    manageOrganizations: 'Manage organizations',
    organizationManager: 'Organization manager',
    addOrganization: 'Add organization',
    editOrganization: 'Edit organization',
    deleteOrganization: 'Delete organization',
    closeManager: 'Close manager',
    deleteUsers: 'Delete users',
    moveUsersTo: 'Move users to',
    theDepartmentContainsUsers: 'The department contains users',
    advancedSettings: 'Advanced Settings',
    hideScenarioContent: 'Hide scenario content',
    hideScenarioContentTooltip: 'Dölj avsiktstexter för den här användaren när de startar ett scenario',
    showCadForm: 'Show CAD Form'
  },
  deleteUser: {
    confirmationText: 'Are you really sure you want to delete',
    cannotBeUndone: 'This cannot be undone!'
  },
  invitationsTable: {
    inviteeEmail: "Invitee Email",
    inviter: "Inbjuden av",
    systemAdmin: "Systemadministratör",
    systemAdminTooltip: "Denna inbjudan gjordes av någon utanför din organisation",
    status: "Status",
    createdTime: "Created Time",
    expirationTime: "Expiration Time",
    acceptedTime: "Accepted Time",
    na: "N/A",
    company: "Organization",
    department: "Department"
  },
};

export const UserManagementDictionaryNo: I18nUserManagementNs = {
  search: 'Søk',
  inviteUser: 'Inviter bruker',
  inviteUserInExternalTenant: 'Inviter bruker fra ekstern tenant',
  userTable: {
    id: 'ID',
    email: 'Epost',
    name: 'Navn',
    role: 'Roller',
    organization: 'Organisasjon',
    department: 'Avdeling',
    status: 'Status',
    actions: 'Handlinger'
  },
  labels: {
    editUser: 'Rediger bruker',
    deleteUser: 'Slett bruker',
    users: 'Brukere',
    invitations: 'Invitasjoner'
  },
  userForm: {
    email: 'Epost',
    name: 'Navn',
    roles: 'Rolle',
    company: 'Organisasjon',
    phone: 'Telefonnummer',
    department: 'Avdeling',
    memberDescriptrion: 'Kan spille av scenarier og delta i kurs.',
    adminDescription: 'Kan administrere brukere i teamet, lage scenarier, organisere kurs og sette opp telefonsamtaler.',
    ownerDescription: 'Kan gjøre alt over, og i tillegg administrere organisasjonen og teamene.',
    superAdminDescription: 'Kan gjøre alt over, og i tillegg administrere alle organisasjoner.',
    manageDepartments: 'Rediger avdelinger',
    departmentManager: 'Avdelingsstyring',
    addDepartment: 'Legg til avdeling',
    editDepartment: 'Rediger avdeling',
    deleteDepartment: 'Slett avdeling',
    manageOrganizations: 'Rediger organisasjoner',
    organizationManager: 'Organisasjonsstyring',
    addOrganization: 'Legg til organisasjon',
    editOrganization: 'Rediger organisasjon',
    deleteOrganization: 'Slett organisasjon',
    closeManager: 'Lukk',
    deleteUsers: 'Slett brukere',
    moveUsersTo: 'Flytt brukere til',
    theDepartmentContainsUsers: 'Avdelingen har brukere',
    advancedSettings: 'Avanserte innstillinger',
    hideScenarioContent: 'Skjul scenarioinnhold',
    hideScenarioContentTooltip: 'Skjul intensjonstekster for denne brukeren når de starter et scenario',
    showCadForm: 'Vis CAD-skjema'
  },
  deleteUser: {
    confirmationText: 'Er du sikker på at du vil slette',
    cannotBeUndone: 'Brukerdata blir permanent slettet!'
  },
  invitationsTable: {
    inviteeEmail: "Epost til inviterte",
    inviter: "Invitert av",
    systemAdmin: "Systemadministrator",
    systemAdminTooltip: "Denne invitasjonen ble laget av noen utenfor organisasjonen din",
    status: "Status",
    createdTime: "Opprettelsestid",
    expirationTime: "Utløpstid",
    acceptedTime: "Akseptert tid",
    na: "N/A",
    company: "Organisasjon",
    department: "Avdeling"
  },
};

export const UserManagementDictionaryNl: I18nUserManagementNs = {
  search: 'Search',
  inviteUser: 'Invite user',
  inviteUserInExternalTenant: 'Invite user in external tenant',
  userTable: {
    id: 'ID',
    email: 'Email',
    name: 'Name',
    role: 'Roles',
    organization: 'Organization',
    department: 'Department',
    status: 'Status',
    actions: 'Actions'
  },
  labels: {
    editUser: 'Edit user',
    deleteUser: 'Delete user',
    users: 'Gebruikers',
    invitations: 'Uitnodigingen'
  },
  userForm: {
    email: 'Email',
    name: 'Name',
    roles: 'Roles',
    company: 'Organization',
    phone: 'Phone number',
    department: 'Department',
    memberDescriptrion: 'Can use scenarios and attend courses.',
    adminDescription: 'Can manage users on their team, create scenarios, organize courses and schedule calls.',
    ownerDescription: 'Can do all of the above, plus manage organization and teams.',
    superAdminDescription: 'Can do all of the above, plus manage all organizations.',
    manageDepartments: 'Manage departments',
    departmentManager: 'Department manager',
    addDepartment: 'Add department',
    editDepartment: 'Edit department',
    deleteDepartment: 'Delete department',
    manageOrganizations: 'Manage organizations',
    organizationManager: 'Organization manager',
    addOrganization: 'Add organization',
    editOrganization: 'Edit organization',
    deleteOrganization: 'Delete organization',
    closeManager: 'Close manager',
    deleteUsers: 'Delete users',
    moveUsersTo: 'Move users to',
    theDepartmentContainsUsers: 'The department contains users',
    advancedSettings: 'Geavanceerde instellingen',
    hideScenarioContent: 'Verberg scenario-inhoud',
    hideScenarioContentTooltip: 'Verberg intentieteksten voor deze gebruiker wanneer ze een scenario starten',
    showCadForm: 'Toon CAD-formulier'
  },
  deleteUser: {
    confirmationText: 'Are you really sure you want to delete',
    cannotBeUndone: 'This cannot be undone!'
  },
  invitationsTable: {
    inviteeEmail: "Email van genodigde",
    inviter: "Uitgenodigd door",
    systemAdmin: "Systeembeheerder",
    systemAdminTooltip: "Deze uitnodiging is gedaan door iemand buiten uw organisatie",
    status: "Status",
    createdTime: "Aangemaakt op",
    expirationTime: "Verlooptijd",
    acceptedTime: "Geaccepteerde tijd",
    na: "N/A",
    company: "Organization",
    department: "Department"
  },
};

export const UserManagementDictionaryIs: I18nUserManagementNs = {
  search: 'Search',
  inviteUser: 'Invite user',
  inviteUserInExternalTenant: 'Invite user in external tenant',
  userTable: {
    id: 'ID',
    email: 'Email',
    name: 'Name',
    role: 'Roles',
    organization: 'Organization',
    department: 'Department',
    status: 'Status',
    actions: 'Actions'
  },
  labels: {
    editUser: 'Edit user',
    deleteUser: 'Delete user',
    users: 'Notendur',
    invitations: 'Boð'
  },
  userForm: {
    email: 'Email',
    name: 'Name',
    roles: 'Roles',
    company: 'Organization',
    phone: 'Phone number',
    department: 'Department',
    memberDescriptrion: 'Can use scenarios and attend courses.',
    adminDescription: 'Can manage users on their team, create scenarios, organize courses and schedule calls.',
    ownerDescription: 'Can do all of the above, plus manage organization and teams.',
    superAdminDescription: 'Can do all of the above, plus manage all organizations.',
    manageDepartments: 'Manage departments',
    departmentManager: 'Department manager',
    addDepartment: 'Add department',
    editDepartment: 'Edit department',
    deleteDepartment: 'Delete department',
    manageOrganizations: 'Manage organizations',
    organizationManager: 'Organization manager',
    addOrganization: 'Add organization',
    editOrganization: 'Edit organization',
    deleteOrganization: 'Delete organization',
    closeManager: 'Close manager',
    deleteUsers: 'Delete users',
    moveUsersTo: 'Move users to',
    theDepartmentContainsUsers: 'The department contains users',
    advancedSettings: 'Ítarlegar stillingar',
    hideScenarioContent: 'Fela sviðsmyndainnihald',
    hideScenarioContentTooltip: 'Fela ásetningartexta fyrir þennan notanda þegar þeir hefja sviðsmynd',
    showCadForm: 'Sýna CAD-eyðublað'
  },
  deleteUser: {
    confirmationText: 'Are you really sure you want to delete',
    cannotBeUndone: 'This cannot be undone!'
  },
  invitationsTable: {
    inviteeEmail: "Netfang boðsgest",
    inviter: "Boðið af",
    systemAdmin: "Kerfisstjóri",
    systemAdminTooltip: "Þetta boð var gert af einhverjum utan þinnar stofnunar",
    status: "Staða",
    createdTime: "Stofnunartími",
    expirationTime: "Rennur út",
    acceptedTime: "Samþykkt tíma",
    na: "N/A",
    company: "Organization",
    department: "Department"
  },
};

export const UserManagementDictionaryUk: I18nUserManagementNs = {
  search: 'Search',
  inviteUser: 'Invite user',
  inviteUserInExternalTenant: 'Invite user in external tenant',
  userTable: {
    id: 'ID',
    email: 'Email',
    name: 'Name',
    role: 'Roles',
    organization: 'Organization',
    department: 'Department',
    status: 'Status',
    actions: 'Actions'
  },
  labels: {
    editUser: 'Edit user',
    deleteUser: 'Delete user',
    users: 'Users',
    invitations: 'Invitations'
  },
  userForm: {
    email: 'Email',
    name: 'Name',
    roles: 'Roles',
    company: 'Organization',
    phone: 'Phone number',
    department: 'Department',
    memberDescriptrion: 'Can use scenarios and attend courses.',
    adminDescription: 'Can manage users on their team, create scenarios, organize courses and schedule calls.',
    ownerDescription: 'Can do all of the above, plus manage organization and teams.',
    superAdminDescription: 'Can do all of the above, plus manage all organizations.',
    manageDepartments: 'Manage departments',
    departmentManager: 'Department manager',
    addDepartment: 'Add department',
    editDepartment: 'Edit department',
    deleteDepartment: 'Delete department',
    manageOrganizations: 'Manage organizations',
    organizationManager: 'Organization manager',
    addOrganization: 'Add organization',
    editOrganization: 'Edit organization',
    deleteOrganization: 'Delete organization',
    closeManager: 'Close manager',
    deleteUsers: 'Delete users',
    moveUsersTo: 'Move users to',
    theDepartmentContainsUsers: 'The department contains users',
    advancedSettings: 'Advanced Settings',
    hideScenarioContent: 'Hide scenario content',
    hideScenarioContentTooltip: 'Hide intent texts for this user when they start a scenario',
    showCadForm: 'Show CAD form'
  },
  deleteUser: {
    confirmationText: 'Are you really sure you want to delete',
    cannotBeUndone: 'This cannot be undone!'
  },
  invitationsTable: {
    inviteeEmail: "Invitee Email",
    inviter: "Invited By",
    systemAdmin: "System Admin",
    systemAdminTooltip: "This invitation was made by someone outside your organization",
    status: "Status",
    createdTime: "Created Time",
    expirationTime: "Expiration Time",
    acceptedTime: "Accepted Time",
    na: "N/A",
    company: "Organization",
    department: "Department"
  },
};
