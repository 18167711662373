import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IConfig, IFlowChartComponents, IFlowChartProps } from '@mrblenny/react-flow-chart';
import { FlowChart } from '@mrblenny/react-flow-chart';

import {
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import CustomInnerNodeComponent from './CustomDiagramComponents/CustomInnerNodeComponent/index.js';
import IntentEditingComponent from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingComponent.js';
import CustomPortsComponent from './CustomDiagramComponents/CustomPortsComponent/index.js';
import CustomNodeComponent from './CustomDiagramComponents/CustomNodeComponent/index.js';
import CustomLink from './CustomDiagramComponents/CustomLink/index.js';

import NodeActionMenu from './NodeActionMenu/index.js';

import * as ScenarioActions from '../../../actions/scenarios/scenarioAction.js';
import { ScenarioChartSelectors, ScenarioSelectors } from '../../../selectors/index.js';

import { onActionButtonClickPropName } from '../../../utils/flowChart/configManager.js';
import { getFlowChartCallbacks, useNodeActionCallbacks } from './utils.js';

import useStyles from './styles.js';
import RouteLeavingGuard from 'app/utils/router/RouteLeavingGuard.js';
import { useHistory } from 'react-router-dom';

import { LoadMode } from 'app/store/types.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export const ScenarioDisplay: React.FC = () => {
  const currentChart = useSelector(ScenarioChartSelectors.scenarioChart);
  const isLoading = useSelector(ScenarioSelectors.detailedScenarioLoading);
  const scenarioHasNotBeenModified = useSelector(ScenarioSelectors.scenarioHasNotBeenModifiedSelector);

  const currentScenario = useSelector(ScenarioSelectors.currentScenario);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const dispatch = useDispatch();
  const chartContainerRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    dispatch(globalAssetsLibrarySlice.actions.load(LoadMode.Soft));
    dispatch(
      ScenarioActions.getDetailedScenario({
        sceneId: currentScenario.sceneId,
        versionId: currentScenario.versionId,
        chartDOMWidth: chartContainerRef.current?.clientWidth ?? 0,
        chartDOMHeight: chartContainerRef.current?.clientHeight ?? 0
      })
    );
  }, [currentScenario?.sceneId, currentScenario?.versionId, dispatch]);

  const [
    nodeMenuAnchor,
    nodeId,
    handleNodeActionButtonClick,
    handleNodeActionMenuClose,
    handleCloneClick,
    handleDeleteClick,
    handleSetIntentTypeAsStart,
    handleCloneToScenario
  ] = useNodeActionCallbacks(dispatch);

  const components: IFlowChartComponents = {
    Node: CustomNodeComponent,
    NodeInner: CustomInnerNodeComponent,
    Link: CustomLink,
    Ports: CustomPortsComponent
  };

  const chartProps: IFlowChartProps = {
    Components: components,
    chart: currentChart,
    callbacks: React.useMemo(() => getFlowChartCallbacks(dispatch), [dispatch]),
    config: React.useMemo<IConfig>(
      () => ({
        nodeProps: {
          [onActionButtonClickPropName]: handleNodeActionButtonClick
        }
      }),
      [handleNodeActionButtonClick]
    )
  };

  const flowChart = (): JSX.Element => {
    if (!!currentChart) return <FlowChart {...chartProps} />;
    else return <div></div>;
  };

  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <RouteLeavingGuard
        navigate={(path) => {
          history.push(path);
        }}
        when={!scenarioHasNotBeenModified}
        shouldBlockNavigation={() => !scenarioHasNotBeenModified}
        render={({ visible, confirm, cancel }) => {
          return (
            <Dialog open={visible} maxWidth={'sm'} fullWidth>
              <DialogTitle>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioDisplay.leavePage))}
              </DialogTitle>
              <DialogContent>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioDisplay.changesYouMadeWillNotBeSaved))}
              </DialogContent>
              <DialogActions>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => {
                    dispatch(ScenarioActions.setScenarioHasNotBeenModified(true));
                    confirm();
                  }}
                >
                  Leave
                </Button>
                <Button
                  onClick={() => {
                    cancel();
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          );
        }}
      />

      <Backdrop open={isLoading} className={classes.backdropElevated}>
        <CircularProgress />
      </Backdrop>
      <IntentEditingComponent />
      <div className={classes.flowChartContainer} ref={chartContainerRef}>
        {flowChart()}
      </div>
      <NodeActionMenu
        anchorEl={nodeMenuAnchor}
        nodeId={nodeId}
        currentScenarioId={currentScenario?.sceneId}
        onClose={handleNodeActionMenuClose}
        onClickClone={handleCloneClick}
        onClickDelete={handleDeleteClick}
        onClickSetIntentTypeAsStart={handleSetIntentTypeAsStart}
        onClickCloneToScenario={handleCloneToScenario}
      />
    </>
  );
};
