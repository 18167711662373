import { makeStyles } from '@material-ui/core/styles/index.js';

const userFormWidth = '30%';

export default makeStyles({
  userFormContainer: {
    width: userFormWidth,
    minWidth: 350,
    padding: 8
  }
});
