import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../reducers/rootReducer.js';
import type { CompanyDepartmentDto } from 'generated-api/scenes/api.js';

const selectDepartmentState = (state: RootState) => state.department;

export const departmentsSelector = createSelector(
  selectDepartmentState,
  (departmentState): CompanyDepartmentDto[] => departmentState.departments
);

export const departmentsLoadingSelector = createSelector(
  selectDepartmentState,
  (departmentState) => departmentState.loading
);

export const departmentsErrorSelector = createSelector(
  selectDepartmentState,
  (departmentState) => departmentState.error
);
