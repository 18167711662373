import { Drawer, Box, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useStyles from './styles.js';
import useDelete from 'app/hooks/useDelete.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { UserManagementSelectors } from 'app/selectors/index.js';
import UsersTable from 'app/components/UsersPage/UserTable/index.js';
import UserEditForm from 'app/components/UsersPage/UserForm/index.js';
import useEditUser from 'app/components/UsersPage/UserForm/useEditUser.js';
import UserDeleteDialog from 'app/components/UsersPage/UserDelete/UserDeleteDialog.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import UserInvitationDialog from 'app/components/UsersPage/UserInvitation/UserInvitationDialog.js';
import InvitationsTable from 'app/components/UsersPage/InvitationsTable/InvitationsTable.js';

const UsersPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation('userManagement');

  const {
    isRightPaneOpen,
    handleCloseForm,
    selectedUserId: selectedUserIdToEdit,
    onSelectUserId: onSelectUserIdToEdit
  } = useEditUser();

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    selectedItem: selectedUserIdToDelete,
    onSelectItem: onSelectUserIdToDelete,
    resetSelectedItem: resetSelectedUserIdToDelete
  } = useDelete<number>();

  const [isUserInviteDialogOpen, openUserInviteDialog, closeUserInviteDialog] = useBoolState();

  const dispatch = useDispatch();

  const refreshInvitations = useCallback(() => {
    dispatch(UserManagementActions.getInvitations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(UserManagementActions.getUsers());
    refreshInvitations();

    return () => {
      handleCloseForm();
    };
  }, [dispatch, handleCloseForm, closeConfirmationDialog, resetSelectedUserIdToDelete, refreshInvitations]);

  const userToDelete = useSelector(UserManagementSelectors.userById(selectedUserIdToDelete));

  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleInvitationDialogClose = () => {
    closeUserInviteDialog();
    refreshInvitations();
  };

  return (
    <Box>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label={t('labels.users')} />
        <Tab label={t('labels.invitations')} />
      </Tabs>
      <UserInvitationDialog open={isUserInviteDialogOpen} onClose={handleInvitationDialogClose} />
      {activeTab === 0 ? (
        <UsersTable
          onSelect={onSelectUserIdToEdit}
          onDelete={onSelectUserIdToDelete}
          openUserInviteDialog={openUserInviteDialog}
        />
      ) : (
        <InvitationsTable openUserInviteDialog={openUserInviteDialog} />
      )}
      <Drawer
        anchor="right"
        variant="persistent"
        onClose={handleCloseForm}
        open={isRightPaneOpen && !!selectedUserIdToEdit}
        PaperProps={{
          square: true,
          className: classes.userFormContainer
        }}
      >
        <UserEditForm userId={selectedUserIdToEdit} onClose={handleCloseForm} />
      </Drawer>
      <UserDeleteDialog
        user={userToDelete}
        onClose={closeConfirmationDialog}
        onExited={resetSelectedUserIdToDelete}
        open={isConfirmationDialogOpen && !!userToDelete}
      />
    </Box>
  );
};

export default UsersPage;
