import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, TextField, Tooltip } from '@material-ui/core';
import { GridToolbarExport } from '@material-ui/data-grid';
import { InfoOutlined } from '@material-ui/icons';

import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import { invitations } from 'app/selectors/userManagementSelectors.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import useStyles from 'app/components/DashboardPage/Table/components/kpiFiltersStyle.js';
import useDebouncedValue from 'app/hooks/useDebouncedValue.js';
import { users } from 'app/selectors/userManagementSelectors.js';
import { departmentsSelector } from 'app/selectors/departmentSelector.js';
import { getAllDepartments } from 'app/actions/companyManagment/departmentActions.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';
import { InvitationStatus } from 'app/models/userManagement/userManaged.js';
import type { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';

const RowsPerPage = 25;

type InvitationsTableProps = {
  openUserInviteDialog: () => void;
};

const InvitationsTable: React.FC<InvitationsTableProps> = ({ openUserInviteDialog }) => {
  const dispatch = useDispatch();
  const invitationsList = useSelector(invitations);
  const companies = useSelector(companiesSelector);
  const usersList = useSelector(users);
  const departments = useSelector(departmentsSelector);
  const [search, setSearch] = useState<string>('');
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.UserManagement]);

  const searchDebounced = useDebouncedValue(search, 400);

  useEffect(() => {
    // Fetch all departments once instead of per company
    dispatch(getAllDepartments());
  }, []); // Added empty dependency array to ensure it only runs once

  const hasCompanyData = useMemo(() => 
    invitationsList.some(invitation => invitation.companyId != null), 
    [invitationsList]
  );

  const hasDepartmentData = useMemo(() => 
    invitationsList.some(invitation => invitation.departmentId != null), 
    [invitationsList]
  );

  const columns = useMemo(() => {
    const baseColumns: GridColDef[] = [
      { field: 'inviteeEmail', headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.inviteeEmail)), flex: 1 },
      { field: 'inviterId', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.inviter)), 
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
          const inviter = usersList.find(u => u.id === params.value);
          if (!inviter) {
            return (
              <Box display="flex" alignItems="center">
                <span>{translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.systemAdmin))}</span>
                <Tooltip title={translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.systemAdminTooltip))}>
                  <InfoOutlined fontSize="small" color="action" style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Box>
            );
          }
          return inviter.name;
        }
      },
    ];

    if (hasCompanyData) {
      baseColumns.push({ 
        field: 'companyId', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.company)), 
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          const company = companies.find(c => c.id === params.value);
          return company ? company.name : translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.na));
        }
      });
    }

    if (hasDepartmentData) {
      baseColumns.push({ 
        field: 'departmentId', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.department)), 
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          const department = departments.find(d => d.id === params.value && d.companyId === params.row.companyId);
          return department ? department.name : translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.na));
        }
      });
    }

    return [
      ...baseColumns,
      { 
        field: 'invitationStatus', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.status)), 
        flex: 1, 
        valueGetter: (params: GridValueGetterParams) => InvitationStatus[params.value as number] 
      },
      { 
        field: 'createdTime', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.createdTime)), 
        flex: 1, 
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.value) return translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.na));
          return new Date(params.value as string).toLocaleString();
        }
      },
      { 
        field: 'expirationTime', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.expirationTime)), 
        flex: 1, 
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.value) return translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.na));
          return new Date(params.value as string).toLocaleString();
        }
      },
      { 
        field: 'acceptedTime', 
        headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.acceptedTime)), 
        flex: 1, 
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.value) return translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.na));
          return new Date(params.value as string).toLocaleString();
        }
      },
    ];
  }, [translate, companies, departments, hasCompanyData, hasDepartmentData, usersList]);

  const rowsFiltered = useMemo(() => {
    if (!searchDebounced) return invitationsList;

    return invitationsList.filter((invitation) => {
      const searchLower = searchDebounced.toLowerCase();
      const company = companies.find(c => c.id === invitation.companyId);
      const department = departments.find(d => d.id === invitation.departmentId && d.companyId === invitation.companyId);
      const inviter = usersList.find(u => u.id === invitation.inviterId);
      const inviterName = inviter ? inviter.name : translate(nameof.full<I18nUserManagementNs>((n) => n.invitationsTable.systemAdmin));
      
      return invitation.inviteeEmail.toLowerCase().includes(searchLower) ||
             InvitationStatus[invitation.invitationStatus].toLowerCase().includes(searchLower) ||
             (company?.name.toLowerCase().includes(searchLower) || false) ||
             (department?.name.toLowerCase().includes(searchLower) || false) ||
             inviterName.toLowerCase().includes(searchLower);
    });
  }, [searchDebounced, invitationsList, companies, departments, usersList, translate]);

  return (
    <Box>
      <Grid container justifyContent="flex-end" alignItems="center" className={classes.container}>
        <Grid item>
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={translate(nameof.full<I18nUserManagementNs>((n) => n.search))}
            variant="outlined"
            inputProps={{
              className: classes.searchInput
            }}
          />
        </Grid>
      </Grid>
      <SimpleDataGrid
        autoHeight
        rows={rowsFiltered}
        columns={columns}
        pageSize={RowsPerPage}
        rowsPerPageOptions={[RowsPerPage]}
        disableSelectionOnClick
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <GridToolbarExport />
                <Button variant="contained" color="primary" onClick={openUserInviteDialog}>
                  {translate(nameof.full<I18nUserManagementNs>((n) => n.inviteUser))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
    </Box>
  );
};

export default InvitationsTable;
