import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import type { TextFieldProps } from '@material-ui/core';
import { Grid, IconButton, Tooltip, CircularProgress, makeStyles } from '@material-ui/core';
import type { UseFormMethods } from 'react-hook-form';
import ListAltIcon from '@material-ui/icons/ListAlt.js';

import { CompaniesSelector } from 'app/selectors/index.js';
import { useToggleState } from 'app/utils/customHooks/index.js';
import type UserManaged from 'app/models/userManagement/userManaged.js';
import CompanySelectField from 'app/components/utils/CompanySelectField/index.js';
import CompanyManager from 'app/components/UsersPage/UserForm/UserCompanyField/CompanyManager/CompanyManager.js';

type UserFormData = UserManaged & {
  id: number;
};

type UserCompanyFieldProps = Pick<UseFormMethods<UserFormData>, 'control'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText'>;

const UserCompanyField: React.FC<UserCompanyFieldProps> = ({ control, error, label, helperText }) => {
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const companiesLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);
  const [companyManagerOpen, toggleCompanyManager] = useToggleState();

  const classes = useStyles();

  return (
    <Grid item container data-cy="companyGrid">
      {companiesLoading ? (
        <Grid item container xs alignItems="center" justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <CompanySelectField
            isForm
            classes={{
              root: classNames({ [classes.displayNone]: companyManagerOpen })
            }}
            companies={companies}
            companiesLoading={companiesLoading}
            control={control}
            name={nameof<UserFormData>((f) => f.companyId)}
            textFieldProps={{
              label,
              error,
              helperText,
              disabled: true
            }}
          />
          <Grid item style={{ paddingTop: 10 }} className={classNames({ [classes.displayNone]: companyManagerOpen })}>
            <Tooltip title="Manage companies" placement="left">
              <IconButton onClick={toggleCompanyManager}>
                <ListAltIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {companyManagerOpen && (
            <Grid item xs>
              <CompanyManager onClose={toggleCompanyManager} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  displayNone: {
    display: 'none'
  }
});

export default UserCompanyField;
