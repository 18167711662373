/* tslint:disable */
/* eslint-disable */
/**
 * HiSklls API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration.js';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base.js';

/**
 * 
 * @export
 * @interface AddBlockCommand
 */
export interface AddBlockCommand {
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddBlockCommand
     */
    name?: string | null;
    /**
     * 
     * @type {BlockType}
     * @memberof AddBlockCommand
     */
    type?: BlockType;
    /**
     * 
     * @type {string}
     * @memberof AddBlockCommand
     */
    description?: string | null;
    /**
     * 
     * @type {QualityLevel}
     * @memberof AddBlockCommand
     */
    qualityLevel?: QualityLevel;
    /**
     * 
     * @type {Array<GlobalAssetIntent>}
     * @memberof AddBlockCommand
     */
    globalAssetIntents?: Array<GlobalAssetIntent> | null;
    /**
     * 
     * @type {Array<AiVoiceResponse>}
     * @memberof AddBlockCommand
     */
    aiResponses?: Array<AiVoiceResponse> | null;
    /**
     * 
     * @type {Array<IntentPhrase>}
     * @memberof AddBlockCommand
     */
    intentPhrases?: Array<IntentPhrase> | null;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    posX?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    posY?: number;
    /**
     * 
     * @type {Array<MedicalProtocolAction>}
     * @memberof AddBlockCommand
     */
    medicalProtocolActions?: Array<MedicalProtocolAction> | null;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    secondsFromDialogStartEstimate?: number;
    /**
     * 
     * @type {Scene}
     * @memberof AddBlockCommand
     */
    scene?: Scene;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    scores?: number;
    /**
     * 
     * @type {string}
     * @memberof AddBlockCommand
     */
    frontendBlockId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddBlockCommand
     */
    personaId?: number | null;
    /**
     * 
     * @type {SceneContextPersona}
     * @memberof AddBlockCommand
     */
    persona?: SceneContextPersona;
}
/**
 * 
 * @export
 * @interface AddBlockLinkCommand
 */
export interface AddBlockLinkCommand {
    /**
     * 
     * @type {number}
     * @memberof AddBlockLinkCommand
     */
    id?: number;
    /**
     * 
     * @type {Block}
     * @memberof AddBlockLinkCommand
     */
    origin?: Block;
    /**
     * 
     * @type {number}
     * @memberof AddBlockLinkCommand
     */
    originId?: number;
    /**
     * 
     * @type {Block}
     * @memberof AddBlockLinkCommand
     */
    target?: Block;
    /**
     * 
     * @type {number}
     * @memberof AddBlockLinkCommand
     */
    targetId?: number;
    /**
     * 
     * @type {Scene}
     * @memberof AddBlockLinkCommand
     */
    scene?: Scene;
    /**
     * 
     * @type {number}
     * @memberof AddBlockLinkCommand
     */
    sceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddBlockLinkCommand
     */
    frontendOriginId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddBlockLinkCommand
     */
    frontendTargetId?: string | null;
}
/**
 * 
 * @export
 * @interface AddCompanyVariableCommand
 */
export interface AddCompanyVariableCommand {
    /**
     * 
     * @type {string}
     * @memberof AddCompanyVariableCommand
     */
    value?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddCompanyVariableCommand
     */
    variableId?: number;
}
/**
 * 
 * @export
 * @interface AddDialogStatisticCommand
 */
export interface AddDialogStatisticCommand {
    /**
     * 
     * @type {string}
     * @memberof AddDialogStatisticCommand
     */
    sessionId?: string;
    /**
     * 
     * @type {EndReason}
     * @memberof AddDialogStatisticCommand
     */
    endReason?: EndReason;
}
/**
 * 
 * @export
 * @interface AddScenarioCommand
 */
export interface AddScenarioCommand {
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof AddScenarioCommand
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof AddScenarioCommand
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    versionId?: number;
    /**
     * 
     * @type {Company}
     * @memberof AddScenarioCommand
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    description?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof AddScenarioCommand
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof AddScenarioCommand
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof AddScenarioCommand
     */
    sttProvider?: SttProvider;
    /**
     * 
     * @type {Array<MedicalProtocolChapter>}
     * @memberof AddScenarioCommand
     */
    medicalProtocolChapters?: Array<MedicalProtocolChapter> | null;
    /**
     * 
     * @type {Array<Block>}
     * @memberof AddScenarioCommand
     */
    blocks?: Array<Block> | null;
    /**
     * 
     * @type {Array<BlockLink>}
     * @memberof AddScenarioCommand
     */
    blockLinks?: Array<BlockLink> | null;
    /**
     * 
     * @type {Array<TransitionScoresSettings>}
     * @memberof AddScenarioCommand
     */
    transitionScoresSettings?: Array<TransitionScoresSettings> | null;
    /**
     * 
     * @type {Array<Course>}
     * @memberof AddScenarioCommand
     */
    courses?: Array<Course> | null;
    /**
     * 
     * @type {SceneGroup}
     * @memberof AddScenarioCommand
     */
    sceneGroup?: SceneGroup;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    centerX?: number;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    centerY?: number;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    scale?: number;
    /**
     * 
     * @type {string}
     * @memberof AddScenarioCommand
     */
    scenarioContextIntro?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddScenarioCommand
     */
    playScenarioContextOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    sceneGroupId?: number | null;
    /**
     * 
     * @type {Array<SceneContext>}
     * @memberof AddScenarioCommand
     */
    contexts?: Array<SceneContext> | null;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    defaultPersonaId?: number | null;
    /**
     * 
     * @type {SceneContextPersona}
     * @memberof AddScenarioCommand
     */
    defaultPersona?: SceneContextPersona;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof AddScenarioCommand
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {LLM}
     * @memberof AddScenarioCommand
     */
    llm?: LLM;
    /**
     * 
     * @type {Array<Company>}
     * @memberof AddScenarioCommand
     */
    sharedCompanies?: Array<Company> | null;
    /**
     * 
     * @type {Array<SceneCompany>}
     * @memberof AddScenarioCommand
     */
    sceneCompanies?: Array<SceneCompany> | null;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof AddScenarioCommand
     */
    variables?: Array<Variable> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddScenarioCommand
     */
    useStreaming?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddScenarioCommand
     */
    maxTokens?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddScenarioCommand
     */
    isLlmEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface AddUsersToGroupCommand
 */
export interface AddUsersToGroupCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof AddUsersToGroupCommand
     */
    users?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface AddVariableCommand
 */
export interface AddVariableCommand {
    /**
     * 
     * @type {string}
     * @memberof AddVariableCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddVariableCommand
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface AiVoiceResponse
 */
export interface AiVoiceResponse {
    /**
     * 
     * @type {number}
     * @memberof AiVoiceResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceResponse
     */
    text?: string | null;
    /**
     * 
     * @type {Emotion}
     * @memberof AiVoiceResponse
     */
    emotion?: Emotion;
    /**
     * 
     * @type {VoiceResponseUsecase}
     * @memberof AiVoiceResponse
     */
    voiceResponseUsecase?: VoiceResponseUsecase;
}
/**
 * 
 * @export
 * @interface AiVoiceResponseDto
 */
export interface AiVoiceResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AiVoiceResponseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceResponseDto
     */
    text?: string | null;
    /**
     * 
     * @type {Emotion}
     * @memberof AiVoiceResponseDto
     */
    emotion?: Emotion;
    /**
     * 
     * @type {VoiceResponseUsecase}
     * @memberof AiVoiceResponseDto
     */
    voiceResponseUsecase?: VoiceResponseUsecase;
}
/**
 * 
 * @export
 * @interface Block
 */
export interface Block {
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    name?: string | null;
    /**
     * 
     * @type {BlockType}
     * @memberof Block
     */
    type?: BlockType;
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    description?: string | null;
    /**
     * 
     * @type {QualityLevel}
     * @memberof Block
     */
    qualityLevel?: QualityLevel;
    /**
     * 
     * @type {Array<GlobalAssetIntent>}
     * @memberof Block
     */
    globalAssetIntents?: Array<GlobalAssetIntent> | null;
    /**
     * 
     * @type {Array<AiVoiceResponse>}
     * @memberof Block
     */
    aiResponses?: Array<AiVoiceResponse> | null;
    /**
     * 
     * @type {Array<IntentPhrase>}
     * @memberof Block
     */
    intentPhrases?: Array<IntentPhrase> | null;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    posX?: number;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    posY?: number;
    /**
     * 
     * @type {Array<MedicalProtocolAction>}
     * @memberof Block
     */
    medicalProtocolActions?: Array<MedicalProtocolAction> | null;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    secondsFromDialogStartEstimate?: number;
    /**
     * 
     * @type {Scene}
     * @memberof Block
     */
    scene?: Scene;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    scores?: number;
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    frontendBlockId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Block
     */
    personaId?: number | null;
    /**
     * 
     * @type {SceneContextPersona}
     * @memberof Block
     */
    persona?: SceneContextPersona;
}
/**
 * 
 * @export
 * @interface BlockDto
 */
export interface BlockDto {
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    name?: string | null;
    /**
     * 
     * @type {BlockType}
     * @memberof BlockDto
     */
    type?: BlockType;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    description?: string | null;
    /**
     * 
     * @type {QualityLevel}
     * @memberof BlockDto
     */
    qualityLevel?: QualityLevel;
    /**
     * 
     * @type {Array<GlobalAssetIntentDto>}
     * @memberof BlockDto
     */
    globalAssetIntents?: Array<GlobalAssetIntentDto> | null;
    /**
     * 
     * @type {Array<AiVoiceResponseDto>}
     * @memberof BlockDto
     */
    aiResponses?: Array<AiVoiceResponseDto> | null;
    /**
     * 
     * @type {Array<IntentPhraseDto>}
     * @memberof BlockDto
     */
    intentPhrases?: Array<IntentPhraseDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    posX?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    posY?: number;
    /**
     * 
     * @type {Array<MedicalProtocolActionDto>}
     * @memberof BlockDto
     */
    medicalProtocolActions?: Array<MedicalProtocolActionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    secondsFromDialogStartEstimate?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    personaId?: number | null;
}
/**
 * 
 * @export
 * @interface BlockLink
 */
export interface BlockLink {
    /**
     * 
     * @type {number}
     * @memberof BlockLink
     */
    id?: number;
    /**
     * 
     * @type {Block}
     * @memberof BlockLink
     */
    origin?: Block;
    /**
     * 
     * @type {number}
     * @memberof BlockLink
     */
    originId?: number;
    /**
     * 
     * @type {Block}
     * @memberof BlockLink
     */
    target?: Block;
    /**
     * 
     * @type {number}
     * @memberof BlockLink
     */
    targetId?: number;
    /**
     * 
     * @type {Scene}
     * @memberof BlockLink
     */
    scene?: Scene;
    /**
     * 
     * @type {number}
     * @memberof BlockLink
     */
    sceneId?: number;
}
/**
 * 
 * @export
 * @interface BlockLinkDto
 */
export interface BlockLinkDto {
    /**
     * 
     * @type {number}
     * @memberof BlockLinkDto
     */
    id?: number;
    /**
     * 
     * @type {BlockDto}
     * @memberof BlockLinkDto
     */
    origin?: BlockDto;
    /**
     * 
     * @type {number}
     * @memberof BlockLinkDto
     */
    originId?: number;
    /**
     * 
     * @type {BlockDto}
     * @memberof BlockLinkDto
     */
    target?: BlockDto;
    /**
     * 
     * @type {number}
     * @memberof BlockLinkDto
     */
    targetId?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockLinkDto
     */
    sceneId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BlockType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MedicalProtocol>}
     * @memberof Company
     */
    medicalProtocols?: Array<MedicalProtocol> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Company
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {Array<CompanyDepartment>}
     * @memberof Company
     */
    departments?: Array<CompanyDepartment> | null;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof Company
     */
    scenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<SceneGroup>}
     * @memberof Company
     */
    sceneGroups?: Array<SceneGroup> | null;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof Company
     */
    sharedScenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<GlobalAssetCategory>}
     * @memberof Company
     */
    sharedGlobalAssetCategories?: Array<GlobalAssetCategory> | null;
    /**
     * 
     * @type {Array<GlobalAssetCategory>}
     * @memberof Company
     */
    globalAssetCategories?: Array<GlobalAssetCategory> | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    externalName?: string | null;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Company
     */
    groups?: Array<Group> | null;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof Company
     */
    tenants?: Array<Tenant> | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    defaultLanguage?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDepartment
 */
export interface CompanyDepartment {
    /**
     * 
     * @type {number}
     * @memberof CompanyDepartment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDepartment
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDepartment
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof CompanyDepartment
     */
    company?: Company;
}
/**
 * 
 * @export
 * @interface CompanyDepartmentDto
 */
export interface CompanyDepartmentDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDepartmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDepartmentDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDepartmentDto
     */
    companyId?: number;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    defaultLanguage?: string | null;
    /**
     * 
     * @type {Array<MedicalProtocolDto>}
     * @memberof CompanyDto
     */
    medicalProtocols?: Array<MedicalProtocolDto> | null;
    /**
     * 
     * @type {Array<SceneGroupDto>}
     * @memberof CompanyDto
     */
    sceneGroups?: Array<SceneGroupDto> | null;
    /**
     * 
     * @type {Array<SceneDto>}
     * @memberof CompanyDto
     */
    scenes?: Array<SceneDto> | null;
}
/**
 * 
 * @export
 * @interface CompanyVariable
 */
export interface CompanyVariable {
    /**
     * 
     * @type {number}
     * @memberof CompanyVariable
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyVariable
     */
    value?: string | null;
    /**
     * 
     * @type {Variable}
     * @memberof CompanyVariable
     */
    variable?: Variable;
    /**
     * 
     * @type {number}
     * @memberof CompanyVariable
     */
    variableId?: number;
    /**
     * 
     * @type {Company}
     * @memberof CompanyVariable
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof CompanyVariable
     */
    companyId?: number;
}
/**
 * 
 * @export
 * @interface CompanyVariableDto
 */
export interface CompanyVariableDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyVariableDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyVariableDto
     */
    value?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyVariableDto
     */
    variableId?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyVariableDto
     */
    companyId?: number;
}
/**
 * 
 * @export
 * @interface ConversationAnalysisDto
 */
export interface ConversationAnalysisDto {
    /**
     * 
     * @type {number}
     * @memberof ConversationAnalysisDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationAnalysisDto
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationAnalysisDto
     */
    score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAnalysisDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationFeedback
 */
export interface ConversationFeedback {
    /**
     * 
     * @type {FeedbackEmoji}
     * @memberof ConversationFeedback
     */
    emoji?: FeedbackEmoji;
    /**
     * 
     * @type {string}
     * @memberof ConversationFeedback
     */
    feedback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationFeedback
     */
    metadata?: string | null;
}
/**
 * 
 * @export
 * @interface ConvertFromSharedScenarioCommand
 */
export interface ConvertFromSharedScenarioCommand {
    /**
     * 
     * @type {number}
     * @memberof ConvertFromSharedScenarioCommand
     */
    targetCompanyId?: number;
}
/**
 * 
 * @export
 * @interface CopyScenarioCommand
 */
export interface CopyScenarioCommand {
    /**
     * 
     * @type {number}
     * @memberof CopyScenarioCommand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CopyScenarioCommand
     */
    newName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CopyScenarioCommand
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CopyScenarioCommand
     */
    targetLanguage?: string | null;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Course
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof Course
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    dueDate?: string;
    /**
     * 
     * @type {Company}
     * @memberof Course
     */
    company?: Company;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof Course
     */
    scenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<ScheduledCourse>}
     * @memberof Course
     */
    scheduledCourses?: Array<ScheduledCourse> | null;
}
/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    scenesCount?: number;
    /**
     * 
     * @type {Array<CourseSceneDto>}
     * @memberof CourseDto
     */
    scenes?: Array<CourseSceneDto> | null;
    /**
     * 
     * @type {Array<ScheduledCourseDto>}
     * @memberof CourseDto
     */
    scheduledCourses?: Array<ScheduledCourseDto> | null;
}
/**
 * 
 * @export
 * @interface CourseSceneDto
 */
export interface CourseSceneDto {
    /**
     * 
     * @type {number}
     * @memberof CourseSceneDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSceneDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseSceneDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface CreateActionCommand
 */
export interface CreateActionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateActionCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface CreateChapterCommand
 */
export interface CreateChapterCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateChapterCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCompanyCommand
 */
export interface CreateCompanyCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyCommand
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCompanyDepartmentCommand
 */
export interface CreateCompanyDepartmentCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDepartmentCommand
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCourseCommand
 */
export interface CreateCourseCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateCourseCommand
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseCommand
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCourseCommand
     */
    duration?: number | null;
}
/**
 * 
 * @export
 * @interface CreateGlobalAssetCategoryCommand
 */
export interface CreateGlobalAssetCategoryCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalAssetCategoryCommand
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalAssetCategoryCommand
     */
    language?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGlobalAssetCategoryCommand
     */
    shared?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateGlobalAssetIntentCommand
 */
export interface CreateGlobalAssetIntentCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalAssetIntentCommand
     */
    title?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGlobalAssetIntentCommand
     */
    samples?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateMedicalProtocolCommand
 */
export interface CreateMedicalProtocolCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateMedicalProtocolCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface CreateOrganizationInvitationRequestCommand
 */
export interface CreateOrganizationInvitationRequestCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateOrganizationInvitationRequestCommand
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInvitationRequestCommand
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInvitationRequestCommand
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInvitationRequestCommand
     */
    tenantDomain?: string | null;
}
/**
 * 
 * @export
 * @interface CreateScheduledCallCommand
 */
export interface CreateScheduledCallCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduledCallCommand
     */
    sceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCallCommand
     */
    scheduledTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCallCommand
     */
    targetName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateScheduledCallCommand
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCallCommand
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateScheduledCallCommand
     */
    companyId?: number;
}
/**
 * 
 * @export
 * @interface CreateScheduledCourseCommand
 */
export interface CreateScheduledCourseCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduledCourseCommand
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCourseCommand
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCourseCommand
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface CreateScheduledCourseNotificationCommand
 */
export interface CreateScheduledCourseNotificationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCourseNotificationCommand
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledCourseNotificationCommand
     */
    customText?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserGroupCommand
 */
export interface CreateUserGroupCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateUserGroupCommand
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUserGroupCommand
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface DeleteCompanyCommand
 */
export interface DeleteCompanyCommand {
    /**
     * 
     * @type {number}
     * @memberof DeleteCompanyCommand
     */
    id?: number;
    /**
     * 
     * @type {OnCompanyDeleteAction}
     * @memberof DeleteCompanyCommand
     */
    onCompanyDeleteAction?: OnCompanyDeleteAction;
    /**
     * 
     * @type {number}
     * @memberof DeleteCompanyCommand
     */
    destinationCompanyId?: number | null;
}
/**
 * 
 * @export
 * @interface DeleteGlobalAssetCategoriesCommand
 */
export interface DeleteGlobalAssetCategoriesCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteGlobalAssetCategoriesCommand
     */
    ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteGlobalAssetIntentCommand
 */
export interface DeleteGlobalAssetIntentCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteGlobalAssetIntentCommand
     */
    ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteScheduledCallsCommand
 */
export interface DeleteScheduledCallsCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteScheduledCallsCommand
     */
    ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteUserGroupCommand
 */
export interface DeleteUserGroupCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUserGroupCommand
     */
    ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteUsersFromGroupCommand
 */
export interface DeleteUsersFromGroupCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUsersFromGroupCommand
     */
    ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DepartmentInvitationDto
 */
export interface DepartmentInvitationDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentInvitationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentInvitationDto
     */
    inviteeEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentInvitationDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentInvitationDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentInvitationDto
     */
    inviterId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentInvitationDto
     */
    createdTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentInvitationDto
     */
    expirationTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentInvitationDto
     */
    acceptedTime?: string | null;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof DepartmentInvitationDto
     */
    invitationStatus?: InvitationStatus;
}
/**
 * 
 * @export
 * @interface DialogFeedbackDto
 */
export interface DialogFeedbackDto {
    /**
     * 
     * @type {number}
     * @memberof DialogFeedbackDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogFeedbackDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DialogFeedbackDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogFeedbackDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogFeedbackDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {FeedbackEmoji}
     * @memberof DialogFeedbackDto
     */
    emoji?: FeedbackEmoji;
    /**
     * 
     * @type {string}
     * @memberof DialogFeedbackDto
     */
    feedback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogFeedbackDto
     */
    metadata?: string | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticDto
 */
export interface DialogStatisticDto {
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    departmentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    sceneVersionId?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    langCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    sceneName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    end?: string;
    /**
     * 
     * @type {DialogStatisticRespondentDto}
     * @memberof DialogStatisticDto
     */
    respondent?: DialogStatisticRespondentDto;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    procedureScores?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    procedureScoresMax?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    transitionScores?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    reachTimeScores?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    reachTimeScoresMax?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    timestamp?: string;
    /**
     * 
     * @type {Array<DialogStatisticIntentDto>}
     * @memberof DialogStatisticDto
     */
    intents?: Array<DialogStatisticIntentDto> | null;
    /**
     * 
     * @type {Array<QualitySettingsDto>}
     * @memberof DialogStatisticDto
     */
    qualityParams?: Array<QualitySettingsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    totalScores?: number;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticDto
     */
    totalScoresMax?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticDto
     */
    callRecordingUrl?: string | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticGlobalIntentPredictionDto
 */
export interface DialogStatisticGlobalIntentPredictionDto {
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticGlobalIntentPredictionDto
     */
    globalIntentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticGlobalIntentPredictionDto
     */
    globalIntentName?: string | null;
    /**
     * 
     * @type {Array<DialogStatisticGlobalIntentPredictionSampleDto>}
     * @memberof DialogStatisticGlobalIntentPredictionDto
     */
    globalIntentSamples?: Array<DialogStatisticGlobalIntentPredictionSampleDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticGlobalIntentPredictionDto
     */
    globalCategoryName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticGlobalIntentPredictionDto
     */
    originalIntentId?: number;
}
/**
 * 
 * @export
 * @interface DialogStatisticGlobalIntentPredictionSampleDto
 */
export interface DialogStatisticGlobalIntentPredictionSampleDto {
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticGlobalIntentPredictionSampleDto
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticIntentDto
 */
export interface DialogStatisticIntentDto {
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticIntentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    voiceId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticIntentDto
     */
    number?: number;
    /**
     * 
     * @type {BlockDto}
     * @memberof DialogStatisticIntentDto
     */
    currentBlock?: BlockDto;
    /**
     * 
     * @type {BlockDto}
     * @memberof DialogStatisticIntentDto
     */
    nextBlock?: BlockDto;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    userSays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    responseText?: string | null;
    /**
     * 
     * @type {Emotion}
     * @memberof DialogStatisticIntentDto
     */
    emotion?: Emotion;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    originalIntentText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticIntentDto
     */
    originalIntentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentDto
     */
    originalIntentName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DialogStatisticIntentDto
     */
    isFallback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DialogStatisticIntentDto
     */
    verifyStatus?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticIntentDto
     */
    scores?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DialogStatisticIntentDto
     */
    corrected?: boolean;
    /**
     * 
     * @type {QualityLevel}
     * @memberof DialogStatisticIntentDto
     */
    color?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof DialogStatisticIntentDto
     */
    previousColor?: QualityLevel;
    /**
     * 
     * @type {Array<MedicalProtocolActionDto>}
     * @memberof DialogStatisticIntentDto
     */
    medicalProtocolActions?: Array<MedicalProtocolActionDto> | null;
    /**
     * 
     * @type {Array<DialogStatisticIntentPredictionDto>}
     * @memberof DialogStatisticIntentDto
     */
    intentsInPrediction?: Array<DialogStatisticIntentPredictionDto> | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticIntentPredictionDto
 */
export interface DialogStatisticIntentPredictionDto {
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticIntentPredictionDto
     */
    originalIntentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentPredictionDto
     */
    originalIntentName?: string | null;
    /**
     * 
     * @type {Array<DialogStatisticIntentPredictionSampleDto>}
     * @memberof DialogStatisticIntentPredictionDto
     */
    samples?: Array<DialogStatisticIntentPredictionSampleDto> | null;
    /**
     * 
     * @type {Array<DialogStatisticGlobalIntentPredictionDto>}
     * @memberof DialogStatisticIntentPredictionDto
     */
    globalIntentsInPrediction?: Array<DialogStatisticGlobalIntentPredictionDto> | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticIntentPredictionSampleDto
 */
export interface DialogStatisticIntentPredictionSampleDto {
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticIntentPredictionSampleDto
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticKpiDetailsDto
 */
export interface DialogStatisticKpiDetailsDto {
    /**
     * 
     * @type {Array<ProcedureScoreDto>}
     * @memberof DialogStatisticKpiDetailsDto
     */
    procedureScores?: Array<ProcedureScoreDto> | null;
    /**
     * 
     * @type {Array<ReachTimeScoreDto>}
     * @memberof DialogStatisticKpiDetailsDto
     */
    reachTimeScores?: Array<ReachTimeScoreDto> | null;
    /**
     * 
     * @type {Array<TransitionScoreDto>}
     * @memberof DialogStatisticKpiDetailsDto
     */
    transitionScores?: Array<TransitionScoreDto> | null;
    /**
     * 
     * @type {Array<ConversationAnalysisDto>}
     * @memberof DialogStatisticKpiDetailsDto
     */
    conversationAnalyses?: Array<ConversationAnalysisDto> | null;
}
/**
 * 
 * @export
 * @interface DialogStatisticRespondentDto
 */
export interface DialogStatisticRespondentDto {
    /**
     * 
     * @type {number}
     * @memberof DialogStatisticRespondentDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticRespondentDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DialogStatisticRespondentDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface EditUserCommand
 */
export interface EditUserCommand {
    /**
     * 
     * @type {number}
     * @memberof EditUserCommand
     */
    id?: number;
    /**
     * 
     * @type {UserRole}
     * @memberof EditUserCommand
     */
    role?: UserRole;
    /**
     * 
     * @type {number}
     * @memberof EditUserCommand
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserCommand
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserCommand
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserCommand
     */
    hideScenarioContent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserCommand
     */
    showCadForm?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Emotion {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface EndCallCommand
 */
export interface EndCallCommand {
    /**
     * 
     * @type {string}
     * @memberof EndCallCommand
     */
    callSid?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EndReason {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FeedbackEmoji {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface GlobalAssetCategory
 */
export interface GlobalAssetCategory {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategory
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategory
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategory
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof GlobalAssetCategory
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof GlobalAssetCategory
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategory
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategory
     */
    modifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategory
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategory
     */
    language?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof GlobalAssetCategory
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategory
     */
    companyId?: number | null;
    /**
     * 
     * @type {Array<GlobalAssetIntent>}
     * @memberof GlobalAssetCategory
     */
    intents?: Array<GlobalAssetIntent> | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof GlobalAssetCategory
     */
    sharedCompanies?: Array<Company> | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetCategoryDto
 */
export interface GlobalAssetCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategoryDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategoryDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategoryDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategoryDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategoryDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetCategoryDto
     */
    language?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetCategoryDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof GlobalAssetCategoryDto
     */
    sharedCompanies?: Array<CompanyDto> | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetConnection
 */
export interface GlobalAssetConnection {
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetConnection
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetConnection
     */
    sceneId?: number;
}
/**
 * 
 * @export
 * @interface GlobalAssetFullDto
 */
export interface GlobalAssetFullDto {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetFullDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetFullDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetFullDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetFullDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetFullDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetFullDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetFullDto
     */
    language?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetFullDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {Array<GlobalAssetIntentDto>}
     * @memberof GlobalAssetFullDto
     */
    intents?: Array<GlobalAssetIntentDto> | null;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof GlobalAssetFullDto
     */
    sharedCompanies?: Array<CompanyDto> | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetIntent
 */
export interface GlobalAssetIntent {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntent
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntent
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntent
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof GlobalAssetIntent
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof GlobalAssetIntent
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntent
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntent
     */
    modifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntent
     */
    title?: string | null;
    /**
     * 
     * @type {GlobalAssetCategory}
     * @memberof GlobalAssetIntent
     */
    category?: GlobalAssetCategory;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntent
     */
    categoryId?: number;
    /**
     * 
     * @type {Array<GlobalAssetSample>}
     * @memberof GlobalAssetIntent
     */
    samples?: Array<GlobalAssetSample> | null;
    /**
     * 
     * @type {Array<Block>}
     * @memberof GlobalAssetIntent
     */
    blocks?: Array<Block> | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetIntentDto
 */
export interface GlobalAssetIntentDto {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntentDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntentDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntentDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntentDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntentDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetIntentDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetIntentDto
     */
    categoryId?: number;
    /**
     * 
     * @type {Array<GlobalAssetSampleDto>}
     * @memberof GlobalAssetIntentDto
     */
    samples?: Array<GlobalAssetSampleDto> | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetSample
 */
export interface GlobalAssetSample {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetSample
     */
    id?: number;
    /**
     * 
     * @type {GlobalAssetIntent}
     * @memberof GlobalAssetSample
     */
    intent?: GlobalAssetIntent;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetSample
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface GlobalAssetSampleDto
 */
export interface GlobalAssetSampleDto {
    /**
     * 
     * @type {number}
     * @memberof GlobalAssetSampleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalAssetSampleDto
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    groupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name?: string | null;
    /**
     * 
     * @type {Tenant}
     * @memberof Group
     */
    tenant?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    tenantId?: number;
    /**
     * 
     * @type {Company}
     * @memberof Group
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    companyId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface IntentPhrase
 */
export interface IntentPhrase {
    /**
     * 
     * @type {number}
     * @memberof IntentPhrase
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IntentPhrase
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface IntentPhraseDto
 */
export interface IntentPhraseDto {
    /**
     * 
     * @type {number}
     * @memberof IntentPhraseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IntentPhraseDto
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IntentProcessingProvider {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LLM {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14
}

/**
 * 
 * @export
 * @interface MedicalProtocol
 */
export interface MedicalProtocol {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocol
     */
    id?: number;
    /**
     * 
     * @type {Array<MedicalProtocolChapter>}
     * @memberof MedicalProtocol
     */
    chapters?: Array<MedicalProtocolChapter> | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocol
     */
    title?: string | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof MedicalProtocol
     */
    companies?: Array<Company> | null;
}
/**
 * 
 * @export
 * @interface MedicalProtocolAction
 */
export interface MedicalProtocolAction {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolAction
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocolAction
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolAction
     */
    priority?: number;
    /**
     * 
     * @type {MedicalProtocolChapter}
     * @memberof MedicalProtocolAction
     */
    chapter?: MedicalProtocolChapter;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolAction
     */
    chapterId?: number;
    /**
     * 
     * @type {Array<Block>}
     * @memberof MedicalProtocolAction
     */
    blocks?: Array<Block> | null;
}
/**
 * 
 * @export
 * @interface MedicalProtocolActionDto
 */
export interface MedicalProtocolActionDto {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolActionDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolActionDto
     */
    chapterId?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocolActionDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolActionDto
     */
    priority?: number;
}
/**
 * 
 * @export
 * @interface MedicalProtocolChapter
 */
export interface MedicalProtocolChapter {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolChapter
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocolChapter
     */
    title?: string | null;
    /**
     * 
     * @type {MedicalProtocol}
     * @memberof MedicalProtocolChapter
     */
    protocol?: MedicalProtocol;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolChapter
     */
    protocolId?: number;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof MedicalProtocolChapter
     */
    scenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<MedicalProtocolAction>}
     * @memberof MedicalProtocolChapter
     */
    actions?: Array<MedicalProtocolAction> | null;
}
/**
 * 
 * @export
 * @interface MedicalProtocolChapterDto
 */
export interface MedicalProtocolChapterDto {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolChapterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocolChapterDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolChapterDto
     */
    protocolId?: number;
    /**
     * 
     * @type {Array<MedicalProtocolActionDto>}
     * @memberof MedicalProtocolChapterDto
     */
    actions?: Array<MedicalProtocolActionDto> | null;
}
/**
 * 
 * @export
 * @interface MedicalProtocolDto
 */
export interface MedicalProtocolDto {
    /**
     * 
     * @type {number}
     * @memberof MedicalProtocolDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProtocolDto
     */
    title?: string | null;
    /**
     * 
     * @type {Array<MedicalProtocolChapterDto>}
     * @memberof MedicalProtocolDto
     */
    chapters?: Array<MedicalProtocolChapterDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OnCompanyDeleteAction {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface PartialListCourseDto
 */
export interface PartialListCourseDto {
    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof PartialListCourseDto
     */
    data?: Array<CourseDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PartialListCourseDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialListCourseDto
     */
    skip?: number;
}
/**
 * 
 * @export
 * @interface PartialListDialogStatisticDto
 */
export interface PartialListDialogStatisticDto {
    /**
     * 
     * @type {Array<DialogStatisticDto>}
     * @memberof PartialListDialogStatisticDto
     */
    data?: Array<DialogStatisticDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PartialListDialogStatisticDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialListDialogStatisticDto
     */
    skip?: number;
}
/**
 * 
 * @export
 * @interface PartialListScheduledCallDto
 */
export interface PartialListScheduledCallDto {
    /**
     * 
     * @type {Array<ScheduledCallDto>}
     * @memberof PartialListScheduledCallDto
     */
    data?: Array<ScheduledCallDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PartialListScheduledCallDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialListScheduledCallDto
     */
    skip?: number;
}
/**
 * 
 * @export
 * @interface PartialListUserGroupDto
 */
export interface PartialListUserGroupDto {
    /**
     * 
     * @type {Array<UserGroupDto>}
     * @memberof PartialListUserGroupDto
     */
    data?: Array<UserGroupDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PartialListUserGroupDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialListUserGroupDto
     */
    skip?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PersonaGender {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PersonaType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface ProcedureScoreDto
 */
export interface ProcedureScoreDto {
    /**
     * 
     * @type {string}
     * @memberof ProcedureScoreDto
     */
    medicalProtocolActionName?: string | null;
    /**
     * 
     * @type {Array<DialogStatisticIntentDto>}
     * @memberof ProcedureScoreDto
     */
    toIntents?: Array<DialogStatisticIntentDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ProcedureScoreDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcedureScoreDto
     */
    sentence?: string | null;
    /**
     * 
     * @type {ProcedureScoreReason}
     * @memberof ProcedureScoreDto
     */
    reason?: ProcedureScoreReason;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProcedureScoreReason {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface ProcessDialogCommand
 */
export interface ProcessDialogCommand {
    /**
     * 
     * @type {string}
     * @memberof ProcessDialogCommand
     */
    sessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessDialogCommand
     */
    sceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDialogCommand
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessDialogCommand
     */
    speech?: string | null;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof ProcessDialogCommand
     */
    intentProcessingProvider?: IntentProcessingProvider;
}
/**
 * 
 * @export
 * @interface ProcessResultDto
 */
export interface ProcessResultDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessResultDto
     */
    sessionId?: string | null;
    /**
     * 
     * @type {BlockDto}
     * @memberof ProcessResultDto
     */
    lastBlock?: BlockDto;
    /**
     * 
     * @type {BlockDto}
     * @memberof ProcessResultDto
     */
    prevBlock?: BlockDto;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    lastBlockId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    prevBlocKId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    lastBlockSceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    prevBlockSceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessResultDto
     */
    text?: string | null;
    /**
     * 
     * @type {Emotion}
     * @memberof ProcessResultDto
     */
    emotion?: Emotion;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    scoresChanges?: number;
    /**
     * 
     * @type {QualityLevel}
     * @memberof ProcessResultDto
     */
    scoresColor?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof ProcessResultDto
     */
    previousColor?: QualityLevel;
    /**
     * 
     * @type {string}
     * @memberof ProcessResultDto
     */
    originalIntentText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProcessResultDto
     */
    originalIntentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessResultDto
     */
    originalIntentName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessResultDto
     */
    isEnd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessResultDto
     */
    isFallback?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessResultDto
     */
    intentHitTime?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum QualityLevel {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface QualitySettingsDto
 */
export interface QualitySettingsDto {
    /**
     * 
     * @type {QualityLevel}
     * @memberof QualitySettingsDto
     */
    origin?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof QualitySettingsDto
     */
    destination?: QualityLevel;
    /**
     * 
     * @type {number}
     * @memberof QualitySettingsDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface ReachTimeScoreDto
 */
export interface ReachTimeScoreDto {
    /**
     * 
     * @type {number}
     * @memberof ReachTimeScoreDto
     */
    intentNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ReachTimeScoreDto
     */
    toIntentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReachTimeScoreDto
     */
    targetTimeSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof ReachTimeScoreDto
     */
    reachTimeSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof ReachTimeScoreDto
     */
    score?: number;
}
/**
 * 
 * @export
 * @interface RestoreScenarioVersionCommand
 */
export interface RestoreScenarioVersionCommand {
    /**
     * 
     * @type {number}
     * @memberof RestoreScenarioVersionCommand
     */
    scenarioId?: number;
    /**
     * 
     * @type {number}
     * @memberof RestoreScenarioVersionCommand
     */
    versionId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ScenarioChangeType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53
}

/**
 * 
 * @export
 * @interface ScenarioUpdateDto
 */
export interface ScenarioUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ScenarioUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioUpdateDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScenarioUpdateDto
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioUpdateDto
     */
    versionId?: number;
    /**
     * 
     * @type {ScenarioChangeType}
     * @memberof ScenarioUpdateDto
     */
    scenarioChangeType?: ScenarioChangeType;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    scenarioChangeTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScenarioUpdateDto
     */
    blockName?: string | null;
}
/**
 * 
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    versionId?: number;
    /**
     * 
     * @type {Company}
     * @memberof Scene
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    description?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof Scene
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof Scene
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof Scene
     */
    sttProvider?: SttProvider;
    /**
     * 
     * @type {Array<MedicalProtocolChapter>}
     * @memberof Scene
     */
    medicalProtocolChapters?: Array<MedicalProtocolChapter> | null;
    /**
     * 
     * @type {Array<Block>}
     * @memberof Scene
     */
    blocks?: Array<Block> | null;
    /**
     * 
     * @type {Array<BlockLink>}
     * @memberof Scene
     */
    blockLinks?: Array<BlockLink> | null;
    /**
     * 
     * @type {Array<TransitionScoresSettings>}
     * @memberof Scene
     */
    transitionScoresSettings?: Array<TransitionScoresSettings> | null;
    /**
     * 
     * @type {Array<Course>}
     * @memberof Scene
     */
    courses?: Array<Course> | null;
    /**
     * 
     * @type {SceneGroup}
     * @memberof Scene
     */
    sceneGroup?: SceneGroup;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    centerX?: number;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    centerY?: number;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    scale?: number;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    scenarioContextIntro?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    playScenarioContextOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    sceneGroupId?: number | null;
    /**
     * 
     * @type {Array<SceneContext>}
     * @memberof Scene
     */
    contexts?: Array<SceneContext> | null;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    defaultPersonaId?: number | null;
    /**
     * 
     * @type {SceneContextPersona}
     * @memberof Scene
     */
    defaultPersona?: SceneContextPersona;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof Scene
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {LLM}
     * @memberof Scene
     */
    llm?: LLM;
    /**
     * 
     * @type {Array<Company>}
     * @memberof Scene
     */
    sharedCompanies?: Array<Company> | null;
    /**
     * 
     * @type {Array<SceneCompany>}
     * @memberof Scene
     */
    sceneCompanies?: Array<SceneCompany> | null;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof Scene
     */
    variables?: Array<Variable> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    useStreaming?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    maxTokens?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    isLlmEnabled?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SceneAccessLevel {
    NUMBER_0 = 0
}

/**
 * 
 * @export
 * @interface SceneCompany
 */
export interface SceneCompany {
    /**
     * 
     * @type {number}
     * @memberof SceneCompany
     */
    sceneId?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneCompany
     */
    companyId?: number;
    /**
     * 
     * @type {SceneAccessLevel}
     * @memberof SceneCompany
     */
    adminEditAccess?: SceneAccessLevel;
    /**
     * 
     * @type {number}
     * @memberof SceneCompany
     */
    sceneGroupId?: number | null;
    /**
     * 
     * @type {SceneGroup}
     * @memberof SceneCompany
     */
    sceneGroup?: SceneGroup;
}
/**
 * 
 * @export
 * @interface SceneContext
 */
export interface SceneContext {
    /**
     * 
     * @type {number}
     * @memberof SceneContext
     */
    id?: number;
    /**
     * 
     * @type {Array<SceneContextLocation>}
     * @memberof SceneContext
     */
    locations?: Array<SceneContextLocation> | null;
    /**
     * 
     * @type {Array<SceneContextFact>}
     * @memberof SceneContext
     */
    facts?: Array<SceneContextFact> | null;
    /**
     * 
     * @type {Array<SceneContextPersona>}
     * @memberof SceneContext
     */
    personas?: Array<SceneContextPersona> | null;
    /**
     * 
     * @type {number}
     * @memberof SceneContext
     */
    sceneId?: number;
    /**
     * 
     * @type {Scene}
     * @memberof SceneContext
     */
    scene?: Scene;
}
/**
 * 
 * @export
 * @interface SceneContextDto
 */
export interface SceneContextDto {
    /**
     * 
     * @type {number}
     * @memberof SceneContextDto
     */
    id?: number;
    /**
     * 
     * @type {Array<SceneContextLocationDto>}
     * @memberof SceneContextDto
     */
    locations?: Array<SceneContextLocationDto> | null;
    /**
     * 
     * @type {Array<SceneContextFactDto>}
     * @memberof SceneContextDto
     */
    facts?: Array<SceneContextFactDto> | null;
    /**
     * 
     * @type {Array<SceneContextPersonaDto>}
     * @memberof SceneContextDto
     */
    personas?: Array<SceneContextPersonaDto> | null;
}
/**
 * 
 * @export
 * @interface SceneContextFact
 */
export interface SceneContextFact {
    /**
     * 
     * @type {number}
     * @memberof SceneContextFact
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextFact
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneContextFact
     */
    sceneContextId?: number;
    /**
     * 
     * @type {SceneContext}
     * @memberof SceneContextFact
     */
    sceneContext?: SceneContext;
}
/**
 * 
 * @export
 * @interface SceneContextFactDto
 */
export interface SceneContextFactDto {
    /**
     * 
     * @type {number}
     * @memberof SceneContextFactDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextFactDto
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface SceneContextLocation
 */
export interface SceneContextLocation {
    /**
     * 
     * @type {number}
     * @memberof SceneContextLocation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextLocation
     */
    location?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneContextLocation
     */
    sceneContextId?: number;
    /**
     * 
     * @type {SceneContext}
     * @memberof SceneContextLocation
     */
    sceneContext?: SceneContext;
}
/**
 * 
 * @export
 * @interface SceneContextLocationDto
 */
export interface SceneContextLocationDto {
    /**
     * 
     * @type {number}
     * @memberof SceneContextLocationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextLocationDto
     */
    location?: string | null;
}
/**
 * 
 * @export
 * @interface SceneContextPersona
 */
export interface SceneContextPersona {
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersona
     */
    id?: number;
    /**
     * 
     * @type {PersonaType}
     * @memberof SceneContextPersona
     */
    type?: PersonaType;
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersona
     */
    sceneContextPersonaRoleId?: number;
    /**
     * 
     * @type {SceneContextPersonaRole}
     * @memberof SceneContextPersona
     */
    role?: SceneContextPersonaRole;
    /**
     * 
     * @type {PersonaGender}
     * @memberof SceneContextPersona
     */
    gender?: PersonaGender;
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersona
     */
    age?: number | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SceneContextPersona
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersona
     */
    voiceName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneContextPersona
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersona
     */
    sceneContextId?: number;
    /**
     * 
     * @type {SceneContext}
     * @memberof SceneContextPersona
     */
    sceneContext?: SceneContext;
}
/**
 * 
 * @export
 * @interface SceneContextPersonaDto
 */
export interface SceneContextPersonaDto {
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersonaDto
     */
    id?: number;
    /**
     * 
     * @type {PersonaType}
     * @memberof SceneContextPersonaDto
     */
    type?: PersonaType;
    /**
     * 
     * @type {PersonaGender}
     * @memberof SceneContextPersonaDto
     */
    gender?: PersonaGender;
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersonaDto
     */
    age?: number | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SceneContextPersonaDto
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaDto
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaDto
     */
    roleName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneContextPersonaDto
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersonaDto
     */
    sceneContextPersonaRoleId?: number;
}
/**
 * 
 * @export
 * @interface SceneContextPersonaRole
 */
export interface SceneContextPersonaRole {
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersonaRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaRole
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaRole
     */
    langCode?: string | null;
}
/**
 * 
 * @export
 * @interface SceneContextPersonaRoleDto
 */
export interface SceneContextPersonaRoleDto {
    /**
     * 
     * @type {number}
     * @memberof SceneContextPersonaRoleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaRoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneContextPersonaRoleDto
     */
    langCode?: string | null;
}
/**
 * 
 * @export
 * @interface SceneDialogStatisticDto
 */
export interface SceneDialogStatisticDto {
    /**
     * 
     * @type {Sex}
     * @memberof SceneDialogStatisticDto
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof SceneDialogStatisticDto
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDialogStatisticDto
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SceneDialogStatisticDto
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof SceneDialogStatisticDto
     */
    sttProvider?: SttProvider;
}
/**
 * 
 * @export
 * @interface SceneDto
 */
export interface SceneDto {
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    versionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    description?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof SceneDto
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SceneDto
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof SceneDto
     */
    sttProvider?: SttProvider;
    /**
     * 
     * @type {Array<MedicalProtocolChapterDto>}
     * @memberof SceneDto
     */
    medicalProtocolChapters?: Array<MedicalProtocolChapterDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SceneDto
     */
    medicalProtocolChapterIds?: Array<number> | null;
    /**
     * 
     * @type {Array<BlockDto>}
     * @memberof SceneDto
     */
    blocks?: Array<BlockDto> | null;
    /**
     * 
     * @type {Array<BlockLinkDto>}
     * @memberof SceneDto
     */
    blockLinks?: Array<BlockLinkDto> | null;
    /**
     * 
     * @type {Array<TransitionScoresSettingsDto>}
     * @memberof SceneDto
     */
    transitionScoresSettings?: Array<TransitionScoresSettingsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    centerX?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    centerY?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    scale?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    scenarioContextIntro?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneDto
     */
    playScenarioContextOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    startBlockId?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    sceneGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {Array<SceneContextDto>}
     * @memberof SceneDto
     */
    contexts?: Array<SceneContextDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    defaultPersonaId?: number | null;
    /**
     * 
     * @type {SceneContextPersonaDto}
     * @memberof SceneDto
     */
    defaultPersona?: SceneContextPersonaDto;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof SceneDto
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {LLM}
     * @memberof SceneDto
     */
    llm?: LLM;
    /**
     * 
     * @type {boolean}
     * @memberof SceneDto
     */
    useStreaming?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    maxTokens?: number;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof SceneDto
     */
    sharedCompanies?: Array<CompanyDto> | null;
    /**
     * 
     * @type {Array<VariableDto>}
     * @memberof SceneDto
     */
    variables?: Array<VariableDto> | null;
}
/**
 * 
 * @export
 * @interface SceneExtendedDto
 */
export interface SceneExtendedDto {
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    versionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    description?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof SceneExtendedDto
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SceneExtendedDto
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof SceneExtendedDto
     */
    sttProvider?: SttProvider;
    /**
     * 
     * @type {Array<MedicalProtocolChapterDto>}
     * @memberof SceneExtendedDto
     */
    medicalProtocolChapters?: Array<MedicalProtocolChapterDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SceneExtendedDto
     */
    medicalProtocolChapterIds?: Array<number> | null;
    /**
     * 
     * @type {Array<BlockDto>}
     * @memberof SceneExtendedDto
     */
    blocks?: Array<BlockDto> | null;
    /**
     * 
     * @type {Array<BlockLinkDto>}
     * @memberof SceneExtendedDto
     */
    blockLinks?: Array<BlockLinkDto> | null;
    /**
     * 
     * @type {Array<TransitionScoresSettingsDto>}
     * @memberof SceneExtendedDto
     */
    transitionScoresSettings?: Array<TransitionScoresSettingsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    centerX?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    centerY?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    scale?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    scenarioContextIntro?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneExtendedDto
     */
    playScenarioContextOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    startBlockId?: number;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    sceneGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneExtendedDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {Array<SceneContextDto>}
     * @memberof SceneExtendedDto
     */
    contexts?: Array<SceneContextDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    defaultPersonaId?: number | null;
    /**
     * 
     * @type {SceneContextPersonaDto}
     * @memberof SceneExtendedDto
     */
    defaultPersona?: SceneContextPersonaDto;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof SceneExtendedDto
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {LLM}
     * @memberof SceneExtendedDto
     */
    llm?: LLM;
    /**
     * 
     * @type {boolean}
     * @memberof SceneExtendedDto
     */
    useStreaming?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneExtendedDto
     */
    maxTokens?: number;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof SceneExtendedDto
     */
    sharedCompanies?: Array<CompanyDto> | null;
    /**
     * 
     * @type {Array<VariableDto>}
     * @memberof SceneExtendedDto
     */
    variables?: Array<VariableDto> | null;
}
/**
 * 
 * @export
 * @interface SceneGroup
 */
export interface SceneGroup {
    /**
     * 
     * @type {number}
     * @memberof SceneGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneGroup
     */
    title?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof SceneGroup
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof SceneGroup
     */
    companyId?: number;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof SceneGroup
     */
    scenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<SceneCompany>}
     * @memberof SceneGroup
     */
    sharedScenes?: Array<SceneCompany> | null;
}
/**
 * 
 * @export
 * @interface SceneGroupDto
 */
export interface SceneGroupDto {
    /**
     * 
     * @type {number}
     * @memberof SceneGroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SceneGroupDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneGroupDto
     */
    companyId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SceneGroupDto
     */
    sceneIds?: Array<number> | null;
    /**
     * 
     * @type {Array<SceneDto>}
     * @memberof SceneGroupDto
     */
    scenes?: Array<SceneDto> | null;
}
/**
 * 
 * @export
 * @interface ScheduledCallDto
 */
export interface ScheduledCallDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCallDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCallDto
     */
    sceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCallDto
     */
    sceneName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCallDto
     */
    scheduledTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCallDto
     */
    targetName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCallDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCallDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCallDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCallDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {ScheduledCallStatus}
     * @memberof ScheduledCallDto
     */
    status?: ScheduledCallStatus;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCallDto
     */
    speechRecognitionTimeout?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ScheduledCallStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9
}

/**
 * 
 * @export
 * @interface ScheduledCourse
 */
export interface ScheduledCourse {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourse
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourse
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourse
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourse
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourse
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourse
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourse
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourse
     */
    departmentId?: number | null;
    /**
     * 
     * @type {Course}
     * @memberof ScheduledCourse
     */
    course?: Course;
    /**
     * 
     * @type {CompanyDepartment}
     * @memberof ScheduledCourse
     */
    department?: CompanyDepartment;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourse
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourse
     */
    endDate?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof ScheduledCourse
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof ScheduledCourse
     */
    userGroups?: Array<UserGroup> | null;
    /**
     * 
     * @type {Array<ScheduledCourseUser>}
     * @memberof ScheduledCourse
     */
    scheduledCourseUsers?: Array<ScheduledCourseUser> | null;
    /**
     * 
     * @type {Array<ScheduledCourseUserGroup>}
     * @memberof ScheduledCourse
     */
    scheduledCourseUserGroups?: Array<ScheduledCourseUserGroup> | null;
    /**
     * 
     * @type {Array<ScheduledCourseNotification>}
     * @memberof ScheduledCourse
     */
    scheduledCourseNotifications?: Array<ScheduledCourseNotification> | null;
}
/**
 * 
 * @export
 * @interface ScheduledCourseAssignmentCommand
 */
export interface ScheduledCourseAssignmentCommand {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseAssignmentCommand
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface ScheduledCourseDto
 */
export interface ScheduledCourseDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    departmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    departmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    courseTitle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    completedScenesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseDto
     */
    scenesCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseDto
     */
    endDate?: string;
    /**
     * 
     * @type {Array<ScheduledCourseUserDto>}
     * @memberof ScheduledCourseDto
     */
    users?: Array<ScheduledCourseUserDto> | null;
    /**
     * 
     * @type {Array<ScheduledCourseUserGroupDto>}
     * @memberof ScheduledCourseDto
     */
    userGroups?: Array<ScheduledCourseUserGroupDto> | null;
}
/**
 * 
 * @export
 * @interface ScheduledCourseNotification
 */
export interface ScheduledCourseNotification {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotification
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotification
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotification
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseNotification
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseNotification
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotification
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotification
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotification
     */
    scheduledCourseId?: number;
    /**
     * 
     * @type {ScheduledCourse}
     * @memberof ScheduledCourseNotification
     */
    scheduledCourse?: ScheduledCourse;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotification
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotification
     */
    customText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotification
     */
    hangfireJobId?: string | null;
}
/**
 * 
 * @export
 * @interface ScheduledCourseNotificationDto
 */
export interface ScheduledCourseNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotificationDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseNotificationDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotificationDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotificationDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotificationDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotificationDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseNotificationDto
     */
    customText?: string | null;
}
/**
 * 
 * @export
 * @interface ScheduledCourseSceneResultDto
 */
export interface ScheduledCourseSceneResultDto {
    /**
     * 
     * @type {SceneDto}
     * @memberof ScheduledCourseSceneResultDto
     */
    scene?: SceneDto;
    /**
     * 
     * @type {DialogStatisticDto}
     * @memberof ScheduledCourseSceneResultDto
     */
    dialogStatistic?: DialogStatisticDto;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseSceneResultDto
     */
    status?: string | null;
}
/**
 * 
 * @export
 * @interface ScheduledCourseUser
 */
export interface ScheduledCourseUser {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUser
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUser
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUser
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseUser
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseUser
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUser
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUser
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUser
     */
    scheduledCourseId?: number;
    /**
     * 
     * @type {ScheduledCourse}
     * @memberof ScheduledCourseUser
     */
    scheduledCourse?: ScheduledCourse;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUser
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseUser
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface ScheduledCourseUserDto
 */
export interface ScheduledCourseUserDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserDto
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface ScheduledCourseUserGroup
 */
export interface ScheduledCourseUserGroup {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroup
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroup
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroup
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseUserGroup
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof ScheduledCourseUserGroup
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroup
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroup
     */
    modifiedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroup
     */
    scheduledCourseId?: number;
    /**
     * 
     * @type {ScheduledCourse}
     * @memberof ScheduledCourseUserGroup
     */
    scheduledCourse?: ScheduledCourse;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroup
     */
    userGroupId?: number;
    /**
     * 
     * @type {UserGroup}
     * @memberof ScheduledCourseUserGroup
     */
    userGroup?: UserGroup;
}
/**
 * 
 * @export
 * @interface ScheduledCourseUserGroupDto
 */
export interface ScheduledCourseUserGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroupDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroupDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroupDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroupDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroupDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCourseUserGroupDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCourseUserGroupDto
     */
    userGroupId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Sex {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface SimpleCompanyDto
 */
export interface SimpleCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleCompanyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleCompanyDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface SimpleScenarioDto
 */
export interface SimpleScenarioDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleScenarioDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleScenarioDto
     */
    versionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleScenarioDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleScenarioDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleScenarioDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleScenarioDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleScenarioDto
     */
    sceneGroupId?: number | null;
    /**
     * 
     * @type {Array<SimpleCompanyDto>}
     * @memberof SimpleScenarioDto
     */
    sharedCompanies?: Array<SimpleCompanyDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface Speaker
 */
export interface Speaker {
    /**
     * 
     * @type {TtsProvider}
     * @memberof Speaker
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    languageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    name?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof Speaker
     */
    sex?: Sex;
}
/**
 * 
 * @export
 * @interface SpeechToSpeechCommand
 */
export interface SpeechToSpeechCommand {
    /**
     * 
     * @type {string}
     * @memberof SpeechToSpeechCommand
     */
    callSid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpeechToSpeechCommand
     */
    audio?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SttProvider {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface SynthesizeSpeechCommand
 */
export interface SynthesizeSpeechCommand {
    /**
     * 
     * @type {number}
     * @memberof SynthesizeSpeechCommand
     */
    intentId?: number | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof SynthesizeSpeechCommand
     */
    service?: TtsProvider;
    /**
     * 
     * @type {Sex}
     * @memberof SynthesizeSpeechCommand
     */
    sex?: Sex;
    /**
     * 
     * @type {Emotion}
     * @memberof SynthesizeSpeechCommand
     */
    emotion?: Emotion;
    /**
     * 
     * @type {string}
     * @memberof SynthesizeSpeechCommand
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SynthesizeSpeechCommand
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SynthesizeSpeechCommand
     */
    voiceName?: string | null;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    tenantId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    externalTenant?: boolean;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Tenant
     */
    groups?: Array<Group> | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof Tenant
     */
    companies?: Array<Company> | null;
}
/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {number}
     * @memberof TenantDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    tenantId?: string | null;
}
/**
 * 
 * @export
 * @interface TransitionScoreDto
 */
export interface TransitionScoreDto {
    /**
     * 
     * @type {number}
     * @memberof TransitionScoreDto
     */
    intentNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionScoreDto
     */
    toIntentName?: string | null;
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoreDto
     */
    fromQualityLevel?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoreDto
     */
    toQualityLevel?: QualityLevel;
    /**
     * 
     * @type {number}
     * @memberof TransitionScoreDto
     */
    score?: number;
}
/**
 * 
 * @export
 * @interface TransitionScoresSettings
 */
export interface TransitionScoresSettings {
    /**
     * 
     * @type {number}
     * @memberof TransitionScoresSettings
     */
    id?: number;
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoresSettings
     */
    origin?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoresSettings
     */
    destination?: QualityLevel;
    /**
     * 
     * @type {number}
     * @memberof TransitionScoresSettings
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface TransitionScoresSettingsDto
 */
export interface TransitionScoresSettingsDto {
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoresSettingsDto
     */
    origin?: QualityLevel;
    /**
     * 
     * @type {QualityLevel}
     * @memberof TransitionScoresSettingsDto
     */
    destination?: QualityLevel;
    /**
     * 
     * @type {number}
     * @memberof TransitionScoresSettingsDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TtsProvider {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface TwilioCallback
 */
export interface TwilioCallback {
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    transcriptionSid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    transcriptionText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    transcriptionStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    transcriptionUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    recordingSid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    recordingUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    callSid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    accountSid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    callStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    apiVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    forwardedFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    speechResult?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    digits?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwilioCallback
     */
    confidence?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateActionCommand
 */
export interface UpdateActionCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateActionCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateChapterCommand
 */
export interface UpdateChapterCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateChapterCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCompanyCommand
 */
export interface UpdateCompanyCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyCommand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MedicalProtocol>}
     * @memberof UpdateCompanyCommand
     */
    medicalProtocols?: Array<MedicalProtocol> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof UpdateCompanyCommand
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {Array<CompanyDepartment>}
     * @memberof UpdateCompanyCommand
     */
    departments?: Array<CompanyDepartment> | null;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof UpdateCompanyCommand
     */
    scenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<SceneGroup>}
     * @memberof UpdateCompanyCommand
     */
    sceneGroups?: Array<SceneGroup> | null;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof UpdateCompanyCommand
     */
    sharedScenes?: Array<Scene> | null;
    /**
     * 
     * @type {Array<GlobalAssetCategory>}
     * @memberof UpdateCompanyCommand
     */
    sharedGlobalAssetCategories?: Array<GlobalAssetCategory> | null;
    /**
     * 
     * @type {Array<GlobalAssetCategory>}
     * @memberof UpdateCompanyCommand
     */
    globalAssetCategories?: Array<GlobalAssetCategory> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    externalName?: string | null;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UpdateCompanyCommand
     */
    groups?: Array<Group> | null;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof UpdateCompanyCommand
     */
    tenants?: Array<Tenant> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    defaultLanguage?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCompanyVariableCommand
 */
export interface UpdateCompanyVariableCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyVariableCommand
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCourseCommand
 */
export interface UpdateCourseCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateCourseCommand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGlobalAssetCategoryCommand
 */
export interface UpdateGlobalAssetCategoryCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalAssetCategoryCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGlobalAssetIntentCommand
 */
export interface UpdateGlobalAssetIntentCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalAssetIntentCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMedicalProtocolCommand
 */
export interface UpdateMedicalProtocolCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateMedicalProtocolCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateScenarioContentCommand
 */
export interface UpdateScenarioContentCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioContentCommand
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioContentCommand
     */
    centerX?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioContentCommand
     */
    centerY?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioContentCommand
     */
    scale?: number;
    /**
     * 
     * @type {Array<AddBlockCommand>}
     * @memberof UpdateScenarioContentCommand
     */
    blocks?: Array<AddBlockCommand> | null;
    /**
     * 
     * @type {Array<AddBlockLinkCommand>}
     * @memberof UpdateScenarioContentCommand
     */
    blockLinks?: Array<AddBlockLinkCommand> | null;
}
/**
 * 
 * @export
 * @interface UpdateScenarioSettingsCommand
 */
export interface UpdateScenarioSettingsCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioSettingsCommand
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioSettingsCommand
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenarioSettingsCommand
     */
    name?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdateScenarioSettingsCommand
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenarioSettingsCommand
     */
    voiceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenarioSettingsCommand
     */
    langCode?: string | null;
    /**
     * 
     * @type {TtsProvider}
     * @memberof UpdateScenarioSettingsCommand
     */
    ttsProvider?: TtsProvider;
    /**
     * 
     * @type {SttProvider}
     * @memberof UpdateScenarioSettingsCommand
     */
    sttProvider?: SttProvider;
    /**
     * 
     * @type {Array<TransitionScoresSettings>}
     * @memberof UpdateScenarioSettingsCommand
     */
    transitionScoresSettings?: Array<TransitionScoresSettings> | null;
    /**
     * 
     * @type {Array<MedicalProtocolChapter>}
     * @memberof UpdateScenarioSettingsCommand
     */
    medicalProtocolChapters?: Array<MedicalProtocolChapter> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenarioSettingsCommand
     */
    scenarioContextIntro?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScenarioSettingsCommand
     */
    playScenarioContextOn?: boolean;
    /**
     * 
     * @type {Array<SceneContext>}
     * @memberof UpdateScenarioSettingsCommand
     */
    contexts?: Array<SceneContext> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioSettingsCommand
     */
    defaultPersonaId?: number | null;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof UpdateScenarioSettingsCommand
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenarioSettingsCommand
     */
    description?: string | null;
    /**
     * 
     * @type {LLM}
     * @memberof UpdateScenarioSettingsCommand
     */
    llm?: LLM;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScenarioSettingsCommand
     */
    useStreaming?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenarioSettingsCommand
     */
    maxTokens?: number;
}
/**
 * 
 * @export
 * @interface UpdateSceneGroupCommand
 */
export interface UpdateSceneGroupCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneGroupCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateScheduledCallCommand
 */
export interface UpdateScheduledCallCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduledCallCommand
     */
    sceneId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCallCommand
     */
    scheduledTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCallCommand
     */
    targetName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduledCallCommand
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCallCommand
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {IntentProcessingProvider}
     * @memberof UpdateScheduledCallCommand
     */
    intentProcessingProvider?: IntentProcessingProvider;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduledCallCommand
     */
    speechRecognitionTimeout?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduledCallCommand
     */
    isDigitsGatherEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateScheduledCourseNotificationCommand
 */
export interface UpdateScheduledCourseNotificationCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduledCourseNotificationCommand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCourseNotificationCommand
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCourseNotificationCommand
     */
    customText?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateScheduledCourseRequestCommand
 */
export interface UpdateScheduledCourseRequestCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduledCourseRequestCommand
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCourseRequestCommand
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduledCourseRequestCommand
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserGroupCommand
 */
export interface UpdateUserGroupCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserGroupCommand
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateVariableCommand
 */
export interface UpdateVariableCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateVariableCommand
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVariableCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVariableCommand
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    departmentId?: number | null;
    /**
     * 
     * @type {CompanyDepartment}
     * @memberof User
     */
    department?: CompanyDepartment;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAggreementAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hideScenarioContent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    showCadForm?: boolean;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof User
     */
    userGroups?: Array<UserGroup> | null;
    /**
     * 
     * @type {Array<ScheduledCourse>}
     * @memberof User
     */
    scheduledCourses?: Array<ScheduledCourse> | null;
    /**
     * 
     * @type {Array<ScheduledCourseUser>}
     * @memberof User
     */
    scheduledCourseUsers?: Array<ScheduledCourseUser> | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    externalUserId?: string | null;
    /**
     * 
     * @type {Tenant}
     * @memberof User
     */
    tenant?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    tenantId?: number | null;
    /**
     * 
     * @type {Group}
     * @memberof User
     */
    group?: Group;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    groupId?: number | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {UserRole}
     * @memberof UserDto
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isAggreementAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    hideScenarioContent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    showCadForm?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    departmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    externalOrganizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    roleName?: string | null;
    /**
     * 
     * @type {TenantDto}
     * @memberof UserDto
     */
    tenant?: TenantDto;
}
/**
 * 
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    modifiedBy?: number | null;
    /**
     * 
     * @type {User}
     * @memberof UserGroup
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof UserGroup
     */
    modifiedByUser?: User;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    modifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    title?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof UserGroup
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    departmentId?: number | null;
    /**
     * 
     * @type {CompanyDepartment}
     * @memberof UserGroup
     */
    department?: CompanyDepartment;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserGroup
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {Array<ScheduledCourse>}
     * @memberof UserGroup
     */
    scheduledCourses?: Array<ScheduledCourse> | null;
    /**
     * 
     * @type {Array<ScheduledCourseUserGroup>}
     * @memberof UserGroup
     */
    scheduledCourseUserGroups?: Array<ScheduledCourseUserGroup> | null;
}
/**
 * 
 * @export
 * @interface UserGroupDto
 */
export interface UserGroupDto {
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    createdBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    createdByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    modifiedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    departmentName?: string | null;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserGroupDto
     */
    users?: Array<UserDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserRole {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    value?: string | null;
    /**
     * 
     * @type {Scene}
     * @memberof Variable
     */
    scene?: Scene;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    sceneId?: number;
    /**
     * 
     * @type {Array<CompanyVariable>}
     * @memberof Variable
     */
    companyVariables?: Array<CompanyVariable> | null;
}
/**
 * 
 * @export
 * @interface VariableDto
 */
export interface VariableDto {
    /**
     * 
     * @type {number}
     * @memberof VariableDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VariableDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyStatisticIntentCommand
 */
export interface VerifyStatisticIntentCommand {
    /**
     * 
     * @type {string}
     * @memberof VerifyStatisticIntentCommand
     */
    actualText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerifyStatisticIntentCommand
     */
    actualIntentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyStatisticIntentCommand
     */
    actualIntentName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyStatisticIntentCommand
     */
    isGarbageData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyStatisticIntentCommand
     */
    addSampleToGlobalIntent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VerifyStatisticIntentCommand
     */
    actualGlobalIntentId?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VoiceResponseUsecase {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAllowRecord: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/AllowRecord/allowRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUserCommand} [editUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEditUser: async (editUserCommand?: EditUserCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/EditUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof editUserCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(editUserCommand !== undefined ? editUserCommand : {})
                : (editUserCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAllowRecord(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountAllowRecord(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {EditUserCommand} [editUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountEditUser(editUserCommand?: EditUserCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountEditUser(editUserCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAllowRecord(options?: any): AxiosPromise<UserDto> {
            return AccountApiFp(configuration).accountAllowRecord(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUserCommand} [editUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEditUser(editUserCommand?: EditUserCommand, options?: any): AxiosPromise<UserDto> {
            return AccountApiFp(configuration).accountEditUser(editUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAllowRecord(options?: any) {
        return AccountApiFp(this.configuration).accountAllowRecord(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUserCommand} [editUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountEditUser(editUserCommand?: EditUserCommand, options?: any) {
        return AccountApiFp(this.configuration).accountEditUser(editUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesAddCompany: async (createCompanyCommand?: CreateCompanyCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/AddCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createCompanyCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createCompanyCommand !== undefined ? createCompanyCommand : {})
                : (createCompanyCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteCompanyCommand} [deleteCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesDeleteCompany: async (deleteCompanyCommand?: DeleteCompanyCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/DeleteCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteCompanyCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteCompanyCommand !== undefined ? deleteCompanyCommand : {})
                : (deleteCompanyCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesEditCompany: async (updateCompanyCommand?: UpdateCompanyCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/EditCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateCompanyCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateCompanyCommand !== undefined ? updateCompanyCommand : {})
                : (updateCompanyCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFilterCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/FilterCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesGetCompany: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling companiesGetCompany.');
            }
            const localVarPath = `/api/Companies/GetCompany/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesAddCompany(createCompanyCommand?: CreateCompanyCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).companiesAddCompany(createCompanyCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteCompanyCommand} [deleteCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesDeleteCompany(deleteCompanyCommand?: DeleteCompanyCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).companiesDeleteCompany(deleteCompanyCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesEditCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).companiesEditCompany(updateCompanyCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesFilterCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDto>>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).companiesFilterCompanies(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesGetCompany(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).companiesGetCompany(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesAddCompany(createCompanyCommand?: CreateCompanyCommand, options?: any): AxiosPromise<CompanyDto> {
            return CompaniesApiFp(configuration).companiesAddCompany(createCompanyCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteCompanyCommand} [deleteCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesDeleteCompany(deleteCompanyCommand?: DeleteCompanyCommand, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).companiesDeleteCompany(deleteCompanyCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesEditCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: any): AxiosPromise<CompanyDto> {
            return CompaniesApiFp(configuration).companiesEditCompany(updateCompanyCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFilterCompanies(options?: any): AxiosPromise<Array<CompanyDto>> {
            return CompaniesApiFp(configuration).companiesFilterCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesGetCompany(id: number, options?: any): AxiosPromise<CompanyDto> {
            return CompaniesApiFp(configuration).companiesGetCompany(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {CreateCompanyCommand} [createCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesAddCompany(createCompanyCommand?: CreateCompanyCommand, options?: any) {
        return CompaniesApiFp(this.configuration).companiesAddCompany(createCompanyCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteCompanyCommand} [deleteCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesDeleteCompany(deleteCompanyCommand?: DeleteCompanyCommand, options?: any) {
        return CompaniesApiFp(this.configuration).companiesDeleteCompany(deleteCompanyCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyCommand} [updateCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesEditCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: any) {
        return CompaniesApiFp(this.configuration).companiesEditCompany(updateCompanyCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesFilterCompanies(options?: any) {
        return CompaniesApiFp(this.configuration).companiesFilterCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesGetCompany(id: number, options?: any) {
        return CompaniesApiFp(this.configuration).companiesGetCompany(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreateDepartment: async (companyId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new RequiredError('companyId','Required parameter companyId was null or undefined when calling companyCreateDepartment.');
            }
            const localVarPath = `/api/Company/CreateDepartment/{companyId}/departments`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createCompanyDepartmentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createCompanyDepartmentCommand !== undefined ? createCompanyDepartmentCommand : {})
                : (createCompanyDepartmentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteDepartment: async (departmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling companyDeleteDepartment.');
            }
            const localVarPath = `/api/Company/DeleteDepartment/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAllDepartments: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetAllDepartments/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetDepartments: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new RequiredError('companyId','Required parameter companyId was null or undefined when calling companyGetDepartments.');
            }
            const localVarPath = `/api/Company/GetDepartments/{companyId}/departments`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetInvitations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetInvitations/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateDepartment: async (departmentId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling companyUpdateDepartment.');
            }
            const localVarPath = `/api/Company/UpdateDepartment/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createCompanyDepartmentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createCompanyDepartmentCommand !== undefined ? createCompanyDepartmentCommand : {})
                : (createCompanyDepartmentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCreateDepartment(companyId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDepartmentDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyCreateDepartment(companyId, createCompanyDepartmentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDeleteDepartment(departmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyDeleteDepartment(departmentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetAllDepartments(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDepartmentDto>>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyGetAllDepartments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetDepartments(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDepartmentDto>>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyGetDepartments(companyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetInvitations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentInvitationDto>>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyGetInvitations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateDepartment(departmentId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDepartmentDto>> {
            const localVarAxiosArgs = await CompanyApiAxiosParamCreator(configuration).companyUpdateDepartment(departmentId, createCompanyDepartmentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreateDepartment(companyId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any): AxiosPromise<CompanyDepartmentDto> {
            return CompanyApiFp(configuration).companyCreateDepartment(companyId, createCompanyDepartmentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteDepartment(departmentId: number, options?: any): AxiosPromise<void> {
            return CompanyApiFp(configuration).companyDeleteDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAllDepartments(options?: any): AxiosPromise<Array<CompanyDepartmentDto>> {
            return CompanyApiFp(configuration).companyGetAllDepartments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetDepartments(companyId: number, options?: any): AxiosPromise<Array<CompanyDepartmentDto>> {
            return CompanyApiFp(configuration).companyGetDepartments(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetInvitations(options?: any): AxiosPromise<Array<DepartmentInvitationDto>> {
            return CompanyApiFp(configuration).companyGetInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateDepartment(departmentId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any): AxiosPromise<CompanyDepartmentDto> {
            return CompanyApiFp(configuration).companyUpdateDepartment(departmentId, createCompanyDepartmentCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyCreateDepartment(companyId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any) {
        return CompanyApiFp(this.configuration).companyCreateDepartment(companyId, createCompanyDepartmentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDeleteDepartment(departmentId: number, options?: any) {
        return CompanyApiFp(this.configuration).companyDeleteDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetAllDepartments(options?: any) {
        return CompanyApiFp(this.configuration).companyGetAllDepartments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetDepartments(companyId: number, options?: any) {
        return CompanyApiFp(this.configuration).companyGetDepartments(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetInvitations(options?: any) {
        return CompanyApiFp(this.configuration).companyGetInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {CreateCompanyDepartmentCommand} [createCompanyDepartmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateDepartment(departmentId: number, createCompanyDepartmentCommand?: CreateCompanyDepartmentCommand, options?: any) {
        return CompanyApiFp(this.configuration).companyUpdateDepartment(departmentId, createCompanyDepartmentCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCourseCommand} [createCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateCourse: async (createCourseCommand?: CreateCourseCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course/CreateCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createCourseCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createCourseCommand !== undefined ? createCourseCommand : {})
                : (createCourseCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateCourseScene: async (courseId: number, sceneId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseCreateCourseScene.');
            }
            // verify required parameter 'sceneId' is not null or undefined
            if (sceneId === null || sceneId === undefined) {
                throw new RequiredError('sceneId','Required parameter sceneId was null or undefined when calling courseCreateCourseScene.');
            }
            const localVarPath = `/api/Course/CreateCourseScene/{courseId}/scene/{sceneId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"sceneId"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateScheduledCourseCommand} [createScheduledCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourse: async (courseId: number, createScheduledCourseCommand?: CreateScheduledCourseCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseCreateScheduledCourse.');
            }
            const localVarPath = `/api/Course/CreateScheduledCourse/schedule/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createScheduledCourseCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createScheduledCourseCommand !== undefined ? createScheduledCourseCommand : {})
                : (createScheduledCourseCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {CreateScheduledCourseNotificationCommand} [createScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseNotification: async (scheduledCourseId: number, createScheduledCourseNotificationCommand?: CreateScheduledCourseNotificationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseCreateScheduledCourseNotification.');
            }
            const localVarPath = `/api/Course/CreateScheduledCourseNotification/schedule/{scheduledCourseId}/notification`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createScheduledCourseNotificationCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createScheduledCourseNotificationCommand !== undefined ? createScheduledCourseNotificationCommand : {})
                : (createScheduledCourseNotificationCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseUser: async (scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseCreateScheduledCourseUser.');
            }
            const localVarPath = `/api/Course/CreateScheduledCourseUser/schedule/{scheduledCourseId}/user`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof scheduledCourseAssignmentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(scheduledCourseAssignmentCommand !== undefined ? scheduledCourseAssignmentCommand : {})
                : (scheduledCourseAssignmentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseUserGroup: async (scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseCreateScheduledCourseUserGroup.');
            }
            const localVarPath = `/api/Course/CreateScheduledCourseUserGroup/schedule/{scheduledCourseId}/user-group`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof scheduledCourseAssignmentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(scheduledCourseAssignmentCommand !== undefined ? scheduledCourseAssignmentCommand : {})
                : (scheduledCourseAssignmentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteCourse: async (courseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseDeleteCourse.');
            }
            const localVarPath = `/api/Course/DeleteCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteCourseScene: async (courseId: number, sceneId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseDeleteCourseScene.');
            }
            // verify required parameter 'sceneId' is not null or undefined
            if (sceneId === null || sceneId === undefined) {
                throw new RequiredError('sceneId','Required parameter sceneId was null or undefined when calling courseDeleteCourseScene.');
            }
            const localVarPath = `/api/Course/DeleteCourseScene/{courseId}/scene/{sceneId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"sceneId"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourse: async (scheduledCourseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseDeleteScheduledCourse.');
            }
            const localVarPath = `/api/Course/DeleteScheduledCourse/schedule/{scheduledCourseId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseNotification: async (scheduledCourseId: number, notificationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseDeleteScheduledCourseNotification.');
            }
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling courseDeleteScheduledCourseNotification.');
            }
            const localVarPath = `/api/Course/DeleteScheduledCourseNotification/schedule/{scheduledCourseId}/notification/{notificationId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)))
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseUser: async (scheduledCourseId: number, userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseDeleteScheduledCourseUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling courseDeleteScheduledCourseUser.');
            }
            const localVarPath = `/api/Course/DeleteScheduledCourseUser/schedule/{scheduledCourseId}/user/{userId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseUserGroup: async (scheduledCourseId: number, userGroupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseDeleteScheduledCourseUserGroup.');
            }
            // verify required parameter 'userGroupId' is not null or undefined
            if (userGroupId === null || userGroupId === undefined) {
                throw new RequiredError('userGroupId','Required parameter userGroupId was null or undefined when calling courseDeleteScheduledCourseUserGroup.');
            }
            const localVarPath = `/api/Course/DeleteScheduledCourseUserGroup/schedule/{scheduledCourseId}/user-group/{userGroupId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)))
                .replace(`{${"userGroupId"}}`, encodeURIComponent(String(userGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGet: async (companyId?: number, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCourse: async (courseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGetCourse.');
            }
            const localVarPath = `/api/Course/GetCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCourseAvailableScenes: async (courseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGetCourseAvailableScenes.');
            }
            const localVarPath = `/api/Course/GetCourseAvailableScenes/{courseId}/available-scenes`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCurrentUserScheduledCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course/GetCurrentUserScheduledCourses/user/current/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAllAssignedUsers: async (scheduledCourseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseGetScheduledCourseAllAssignedUsers.');
            }
            const localVarPath = `/api/Course/GetScheduledCourseAllAssignedUsers/schedule/{scheduledCourseId}/all-assigned-users`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAvailableUserGroups: async (scheduledCourseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseGetScheduledCourseAvailableUserGroups.');
            }
            const localVarPath = `/api/Course/GetScheduledCourseAvailableUserGroups/schedule/{scheduledCourseId}/available-user-groups`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAvailableUsers: async (scheduledCourseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseGetScheduledCourseAvailableUsers.');
            }
            const localVarPath = `/api/Course/GetScheduledCourseAvailableUsers/schedule/{scheduledCourseId}/available-users`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseNotifications: async (scheduledCourseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseGetScheduledCourseNotifications.');
            }
            const localVarPath = `/api/Course/GetScheduledCourseNotifications/schedule/{scheduledCourseId}/notifications`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetUserScheduledCourseResults: async (scheduledCourseId: number, userId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseGetUserScheduledCourseResults.');
            }
            const localVarPath = `/api/Course/GetUserScheduledCourseResults/user/current/schedule/{scheduledCourseId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseCommand} [updateCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdate: async (courseId: number, updateCourseCommand?: UpdateCourseCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseUpdate.');
            }
            const localVarPath = `/api/Course/Update/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateCourseCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateCourseCommand !== undefined ? updateCourseCommand : {})
                : (updateCourseCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {UpdateScheduledCourseRequestCommand} [updateScheduledCourseRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateScheduledCourse: async (scheduledCourseId: number, updateScheduledCourseRequestCommand?: UpdateScheduledCourseRequestCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseUpdateScheduledCourse.');
            }
            const localVarPath = `/api/Course/UpdateScheduledCourse/schedule/{scheduledCourseId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateScheduledCourseRequestCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateScheduledCourseRequestCommand !== undefined ? updateScheduledCourseRequestCommand : {})
                : (updateScheduledCourseRequestCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {UpdateScheduledCourseNotificationCommand} [updateScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateScheduledCourseNotification: async (scheduledCourseId: number, notificationId: number, updateScheduledCourseNotificationCommand?: UpdateScheduledCourseNotificationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCourseId' is not null or undefined
            if (scheduledCourseId === null || scheduledCourseId === undefined) {
                throw new RequiredError('scheduledCourseId','Required parameter scheduledCourseId was null or undefined when calling courseUpdateScheduledCourseNotification.');
            }
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling courseUpdateScheduledCourseNotification.');
            }
            const localVarPath = `/api/Course/UpdateScheduledCourseNotification/schedule/{scheduledCourseId}/notification/{notificationId}`
                .replace(`{${"scheduledCourseId"}}`, encodeURIComponent(String(scheduledCourseId)))
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateScheduledCourseNotificationCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateScheduledCourseNotificationCommand !== undefined ? updateScheduledCourseNotificationCommand : {})
                : (updateScheduledCourseNotificationCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCourseCommand} [createCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateCourse(createCourseCommand?: CreateCourseCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateCourse(createCourseCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateCourseScene(courseId: number, sceneId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateCourseScene(courseId, sceneId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateScheduledCourseCommand} [createScheduledCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateScheduledCourse(courseId: number, createScheduledCourseCommand?: CreateScheduledCourseCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateScheduledCourse(courseId, createScheduledCourseCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {CreateScheduledCourseNotificationCommand} [createScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateScheduledCourseNotification(scheduledCourseId: number, createScheduledCourseNotificationCommand?: CreateScheduledCourseNotificationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateScheduledCourseNotification(scheduledCourseId, createScheduledCourseNotificationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateScheduledCourseUser(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateScheduledCourseUser(scheduledCourseId, scheduledCourseAssignmentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreateScheduledCourseUserGroup(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseCreateScheduledCourseUserGroup(scheduledCourseId, scheduledCourseAssignmentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteCourse(courseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteCourse(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteCourseScene(courseId: number, sceneId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteCourseScene(courseId, sceneId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteScheduledCourse(scheduledCourseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteScheduledCourse(scheduledCourseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteScheduledCourseNotification(scheduledCourseId: number, notificationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteScheduledCourseNotification(scheduledCourseId, notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteScheduledCourseUser(scheduledCourseId: number, userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteScheduledCourseUser(scheduledCourseId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteScheduledCourseUserGroup(scheduledCourseId: number, userGroupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseDeleteScheduledCourseUserGroup(scheduledCourseId, userGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGet(companyId?: number, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialListCourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGet(companyId, skip, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetCourse(courseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetCourse(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetCourseAvailableScenes(courseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SceneDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetCourseAvailableScenes(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetCurrentUserScheduledCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCourseDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetCurrentUserScheduledCourses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetScheduledCourseAllAssignedUsers(scheduledCourseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetScheduledCourseAllAssignedUsers(scheduledCourseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetScheduledCourseAvailableUserGroups(scheduledCourseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGroupDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetScheduledCourseAvailableUserGroups(scheduledCourseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetScheduledCourseAvailableUsers(scheduledCourseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetScheduledCourseAvailableUsers(scheduledCourseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetScheduledCourseNotifications(scheduledCourseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCourseNotificationDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetScheduledCourseNotifications(scheduledCourseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseGetUserScheduledCourseResults(scheduledCourseId: number, userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCourseSceneResultDto>>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseGetUserScheduledCourseResults(scheduledCourseId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseCommand} [updateCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseUpdate(courseId: number, updateCourseCommand?: UpdateCourseCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseUpdate(courseId, updateCourseCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {UpdateScheduledCourseRequestCommand} [updateScheduledCourseRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseUpdateScheduledCourse(scheduledCourseId: number, updateScheduledCourseRequestCommand?: UpdateScheduledCourseRequestCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCourseDto>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseUpdateScheduledCourse(scheduledCourseId, updateScheduledCourseRequestCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {UpdateScheduledCourseNotificationCommand} [updateScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseUpdateScheduledCourseNotification(scheduledCourseId: number, notificationId: number, updateScheduledCourseNotificationCommand?: UpdateScheduledCourseNotificationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CourseApiAxiosParamCreator(configuration).courseUpdateScheduledCourseNotification(scheduledCourseId, notificationId, updateScheduledCourseNotificationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateCourseCommand} [createCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateCourse(createCourseCommand?: CreateCourseCommand, options?: any): AxiosPromise<CourseDto> {
            return CourseApiFp(configuration).courseCreateCourse(createCourseCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateCourseScene(courseId: number, sceneId: number, options?: any): AxiosPromise<CourseDto> {
            return CourseApiFp(configuration).courseCreateCourseScene(courseId, sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateScheduledCourseCommand} [createScheduledCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourse(courseId: number, createScheduledCourseCommand?: CreateScheduledCourseCommand, options?: any): AxiosPromise<ScheduledCourseDto> {
            return CourseApiFp(configuration).courseCreateScheduledCourse(courseId, createScheduledCourseCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {CreateScheduledCourseNotificationCommand} [createScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseNotification(scheduledCourseId: number, createScheduledCourseNotificationCommand?: CreateScheduledCourseNotificationCommand, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseCreateScheduledCourseNotification(scheduledCourseId, createScheduledCourseNotificationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseUser(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any): AxiosPromise<ScheduledCourseDto> {
            return CourseApiFp(configuration).courseCreateScheduledCourseUser(scheduledCourseId, scheduledCourseAssignmentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreateScheduledCourseUserGroup(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any): AxiosPromise<ScheduledCourseDto> {
            return CourseApiFp(configuration).courseCreateScheduledCourseUserGroup(scheduledCourseId, scheduledCourseAssignmentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteCourse(courseId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} sceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteCourseScene(courseId: number, sceneId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteCourseScene(courseId, sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourse(scheduledCourseId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteScheduledCourse(scheduledCourseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseNotification(scheduledCourseId: number, notificationId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteScheduledCourseNotification(scheduledCourseId, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseUser(scheduledCourseId: number, userId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteScheduledCourseUser(scheduledCourseId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteScheduledCourseUserGroup(scheduledCourseId: number, userGroupId: number, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseDeleteScheduledCourseUserGroup(scheduledCourseId, userGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGet(companyId?: number, skip?: number, take?: number, options?: any): AxiosPromise<PartialListCourseDto> {
            return CourseApiFp(configuration).courseGet(companyId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCourse(courseId: number, options?: any): AxiosPromise<CourseDto> {
            return CourseApiFp(configuration).courseGetCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCourseAvailableScenes(courseId: number, options?: any): AxiosPromise<Array<SceneDto>> {
            return CourseApiFp(configuration).courseGetCourseAvailableScenes(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetCurrentUserScheduledCourses(options?: any): AxiosPromise<Array<ScheduledCourseDto>> {
            return CourseApiFp(configuration).courseGetCurrentUserScheduledCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAllAssignedUsers(scheduledCourseId: number, options?: any): AxiosPromise<Array<UserDto>> {
            return CourseApiFp(configuration).courseGetScheduledCourseAllAssignedUsers(scheduledCourseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAvailableUserGroups(scheduledCourseId: number, options?: any): AxiosPromise<Array<UserGroupDto>> {
            return CourseApiFp(configuration).courseGetScheduledCourseAvailableUserGroups(scheduledCourseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseAvailableUsers(scheduledCourseId: number, options?: any): AxiosPromise<Array<UserDto>> {
            return CourseApiFp(configuration).courseGetScheduledCourseAvailableUsers(scheduledCourseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetScheduledCourseNotifications(scheduledCourseId: number, options?: any): AxiosPromise<Array<ScheduledCourseNotificationDto>> {
            return CourseApiFp(configuration).courseGetScheduledCourseNotifications(scheduledCourseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGetUserScheduledCourseResults(scheduledCourseId: number, userId?: number, options?: any): AxiosPromise<Array<ScheduledCourseSceneResultDto>> {
            return CourseApiFp(configuration).courseGetUserScheduledCourseResults(scheduledCourseId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseCommand} [updateCourseCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdate(courseId: number, updateCourseCommand?: UpdateCourseCommand, options?: any): AxiosPromise<CourseDto> {
            return CourseApiFp(configuration).courseUpdate(courseId, updateCourseCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {UpdateScheduledCourseRequestCommand} [updateScheduledCourseRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateScheduledCourse(scheduledCourseId: number, updateScheduledCourseRequestCommand?: UpdateScheduledCourseRequestCommand, options?: any): AxiosPromise<ScheduledCourseDto> {
            return CourseApiFp(configuration).courseUpdateScheduledCourse(scheduledCourseId, updateScheduledCourseRequestCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCourseId 
         * @param {number} notificationId 
         * @param {UpdateScheduledCourseNotificationCommand} [updateScheduledCourseNotificationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateScheduledCourseNotification(scheduledCourseId: number, notificationId: number, updateScheduledCourseNotificationCommand?: UpdateScheduledCourseNotificationCommand, options?: any): AxiosPromise<void> {
            return CourseApiFp(configuration).courseUpdateScheduledCourseNotification(scheduledCourseId, notificationId, updateScheduledCourseNotificationCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @param {CreateCourseCommand} [createCourseCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateCourse(createCourseCommand?: CreateCourseCommand, options?: any) {
        return CourseApiFp(this.configuration).courseCreateCourse(createCourseCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {number} sceneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateCourseScene(courseId: number, sceneId: number, options?: any) {
        return CourseApiFp(this.configuration).courseCreateCourseScene(courseId, sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {CreateScheduledCourseCommand} [createScheduledCourseCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateScheduledCourse(courseId: number, createScheduledCourseCommand?: CreateScheduledCourseCommand, options?: any) {
        return CourseApiFp(this.configuration).courseCreateScheduledCourse(courseId, createScheduledCourseCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {CreateScheduledCourseNotificationCommand} [createScheduledCourseNotificationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateScheduledCourseNotification(scheduledCourseId: number, createScheduledCourseNotificationCommand?: CreateScheduledCourseNotificationCommand, options?: any) {
        return CourseApiFp(this.configuration).courseCreateScheduledCourseNotification(scheduledCourseId, createScheduledCourseNotificationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateScheduledCourseUser(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any) {
        return CourseApiFp(this.configuration).courseCreateScheduledCourseUser(scheduledCourseId, scheduledCourseAssignmentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {ScheduledCourseAssignmentCommand} [scheduledCourseAssignmentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreateScheduledCourseUserGroup(scheduledCourseId: number, scheduledCourseAssignmentCommand?: ScheduledCourseAssignmentCommand, options?: any) {
        return CourseApiFp(this.configuration).courseCreateScheduledCourseUserGroup(scheduledCourseId, scheduledCourseAssignmentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteCourse(courseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {number} sceneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteCourseScene(courseId: number, sceneId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteCourseScene(courseId, sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteScheduledCourse(scheduledCourseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteScheduledCourse(scheduledCourseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteScheduledCourseNotification(scheduledCourseId: number, notificationId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteScheduledCourseNotification(scheduledCourseId, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteScheduledCourseUser(scheduledCourseId: number, userId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteScheduledCourseUser(scheduledCourseId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {number} userGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteScheduledCourseUserGroup(scheduledCourseId: number, userGroupId: number, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteScheduledCourseUserGroup(scheduledCourseId, userGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGet(companyId?: number, skip?: number, take?: number, options?: any) {
        return CourseApiFp(this.configuration).courseGet(companyId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetCourse(courseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetCourseAvailableScenes(courseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetCourseAvailableScenes(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetCurrentUserScheduledCourses(options?: any) {
        return CourseApiFp(this.configuration).courseGetCurrentUserScheduledCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetScheduledCourseAllAssignedUsers(scheduledCourseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetScheduledCourseAllAssignedUsers(scheduledCourseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetScheduledCourseAvailableUserGroups(scheduledCourseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetScheduledCourseAvailableUserGroups(scheduledCourseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetScheduledCourseAvailableUsers(scheduledCourseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetScheduledCourseAvailableUsers(scheduledCourseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetScheduledCourseNotifications(scheduledCourseId: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetScheduledCourseNotifications(scheduledCourseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseGetUserScheduledCourseResults(scheduledCourseId: number, userId?: number, options?: any) {
        return CourseApiFp(this.configuration).courseGetUserScheduledCourseResults(scheduledCourseId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} courseId 
     * @param {UpdateCourseCommand} [updateCourseCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseUpdate(courseId: number, updateCourseCommand?: UpdateCourseCommand, options?: any) {
        return CourseApiFp(this.configuration).courseUpdate(courseId, updateCourseCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {UpdateScheduledCourseRequestCommand} [updateScheduledCourseRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseUpdateScheduledCourse(scheduledCourseId: number, updateScheduledCourseRequestCommand?: UpdateScheduledCourseRequestCommand, options?: any) {
        return CourseApiFp(this.configuration).courseUpdateScheduledCourse(scheduledCourseId, updateScheduledCourseRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCourseId 
     * @param {number} notificationId 
     * @param {UpdateScheduledCourseNotificationCommand} [updateScheduledCourseNotificationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseUpdateScheduledCourseNotification(scheduledCourseId: number, notificationId: number, updateScheduledCourseNotificationCommand?: UpdateScheduledCourseNotificationCommand, options?: any) {
        return CourseApiFp(this.configuration).courseUpdateScheduledCourseNotification(scheduledCourseId, notificationId, updateScheduledCourseNotificationCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DialogSessionApi - axios parameter creator
 * @export
 */
export const DialogSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} [versionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogSessionInitSession: async (scenarioId: number, versionId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            if (scenarioId === null || scenarioId === undefined) {
                throw new RequiredError('scenarioId','Required parameter scenarioId was null or undefined when calling dialogSessionInitSession.');
            }
            const localVarPath = `/api/DialogSession/InitSession/{scenarioId}/start`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (versionId !== undefined) {
                localVarQueryParameter['versionId'] = versionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DialogSessionApi - functional programming interface
 * @export
 */
export const DialogSessionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} [versionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogSessionInitSession(scenarioId: number, versionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DialogSessionApiAxiosParamCreator(configuration).dialogSessionInitSession(scenarioId, versionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DialogSessionApi - factory interface
 * @export
 */
export const DialogSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} [versionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogSessionInitSession(scenarioId: number, versionId?: number, options?: any): AxiosPromise<string> {
            return DialogSessionApiFp(configuration).dialogSessionInitSession(scenarioId, versionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DialogSessionApi - object-oriented interface
 * @export
 * @class DialogSessionApi
 * @extends {BaseAPI}
 */
export class DialogSessionApi extends BaseAPI {
    /**
     * 
     * @param {number} scenarioId 
     * @param {number} [versionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogSessionApi
     */
    public dialogSessionInitSession(scenarioId: number, versionId?: number, options?: any) {
        return DialogSessionApiFp(this.configuration).dialogSessionInitSession(scenarioId, versionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DialogStatisticsApi - axios parameter creator
 * @export
 */
export const DialogStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDialogStatisticCommand} [addDialogStatisticCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsAddDialogStatistic: async (addDialogStatisticCommand?: AddDialogStatisticCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DialogStatistics/AddDialogStatistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addDialogStatisticCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addDialogStatisticCommand !== undefined ? addDialogStatisticCommand : {})
                : (addDialogStatisticCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsDeleteDialogStatistic: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dialogStatisticsDeleteDialogStatistic.');
            }
            const localVarPath = `/api/DialogStatistics/DeleteDialogStatistic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogFeedbacks: async (dialogStatisticsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dialogStatisticsId' is not null or undefined
            if (dialogStatisticsId === null || dialogStatisticsId === undefined) {
                throw new RequiredError('dialogStatisticsId','Required parameter dialogStatisticsId was null or undefined when calling dialogStatisticsGetDialogFeedbacks.');
            }
            const localVarPath = `/api/DialogStatistics/GetDialogFeedbacks/{dialogStatisticsId}/feedback`
                .replace(`{${"dialogStatisticsId"}}`, encodeURIComponent(String(dialogStatisticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogStatistic: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dialogStatisticsGetDialogStatistic.');
            }
            const localVarPath = `/api/DialogStatistics/GetDialogStatistic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogTranscript: async (dialogStatisticsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dialogStatisticsId' is not null or undefined
            if (dialogStatisticsId === null || dialogStatisticsId === undefined) {
                throw new RequiredError('dialogStatisticsId','Required parameter dialogStatisticsId was null or undefined when calling dialogStatisticsGetDialogTranscript.');
            }
            const localVarPath = `/api/DialogStatistics/GetDialogTranscript/{dialogStatisticsId}/transcript`
                .replace(`{${"dialogStatisticsId"}}`, encodeURIComponent(String(dialogStatisticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetKpiDetails: async (dialogStatisticsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dialogStatisticsId' is not null or undefined
            if (dialogStatisticsId === null || dialogStatisticsId === undefined) {
                throw new RequiredError('dialogStatisticsId','Required parameter dialogStatisticsId was null or undefined when calling dialogStatisticsGetKpiDetails.');
            }
            const localVarPath = `/api/DialogStatistics/GetKpiDetails/{dialogStatisticsId}/kpidetails`
                .replace(`{${"dialogStatisticsId"}}`, encodeURIComponent(String(dialogStatisticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {SortType} [sortOrder] 
         * @param {string} [orderBy] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsListDialogStatisticsService: async (skip?: number, take?: number, sortOrder?: SortType, orderBy?: string, from?: string, to?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DialogStatistics/ListDialogStatisticsService`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {ConversationFeedback} [conversationFeedback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsStoreFeedback: async (sessionId: string, conversationFeedback?: ConversationFeedback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling dialogStatisticsStoreFeedback.');
            }
            const localVarPath = `/api/DialogStatistics/StoreFeedback/StoreFeedback/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof conversationFeedback !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(conversationFeedback !== undefined ? conversationFeedback : {})
                : (conversationFeedback || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} statisticId 
         * @param {number} intentId 
         * @param {VerifyStatisticIntentCommand} [verifyStatisticIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsVerifyStatisticIntent: async (statisticId: number, intentId: number, verifyStatisticIntentCommand?: VerifyStatisticIntentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'statisticId' is not null or undefined
            if (statisticId === null || statisticId === undefined) {
                throw new RequiredError('statisticId','Required parameter statisticId was null or undefined when calling dialogStatisticsVerifyStatisticIntent.');
            }
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling dialogStatisticsVerifyStatisticIntent.');
            }
            const localVarPath = `/api/DialogStatistics/VerifyStatisticIntent/{statisticId}/{intentId}/verifyIntent`
                .replace(`{${"statisticId"}}`, encodeURIComponent(String(statisticId)))
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyStatisticIntentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyStatisticIntentCommand !== undefined ? verifyStatisticIntentCommand : {})
                : (verifyStatisticIntentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DialogStatisticsApi - functional programming interface
 * @export
 */
export const DialogStatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDialogStatisticCommand} [addDialogStatisticCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsAddDialogStatistic(addDialogStatisticCommand?: AddDialogStatisticCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DialogStatisticDto>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsAddDialogStatistic(addDialogStatisticCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsDeleteDialogStatistic(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsDeleteDialogStatistic(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsGetDialogFeedbacks(dialogStatisticsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DialogFeedbackDto>>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsGetDialogFeedbacks(dialogStatisticsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsGetDialogStatistic(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DialogStatisticDto>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsGetDialogStatistic(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsGetDialogTranscript(dialogStatisticsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsGetDialogTranscript(dialogStatisticsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsGetKpiDetails(dialogStatisticsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DialogStatisticKpiDetailsDto>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsGetKpiDetails(dialogStatisticsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {SortType} [sortOrder] 
         * @param {string} [orderBy] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsListDialogStatisticsService(skip?: number, take?: number, sortOrder?: SortType, orderBy?: string, from?: string, to?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialListDialogStatisticDto>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsListDialogStatisticsService(skip, take, sortOrder, orderBy, from, to, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {ConversationFeedback} [conversationFeedback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsStoreFeedback(sessionId: string, conversationFeedback?: ConversationFeedback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsStoreFeedback(sessionId, conversationFeedback, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} statisticId 
         * @param {number} intentId 
         * @param {VerifyStatisticIntentCommand} [verifyStatisticIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dialogStatisticsVerifyStatisticIntent(statisticId: number, intentId: number, verifyStatisticIntentCommand?: VerifyStatisticIntentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DialogStatisticsApiAxiosParamCreator(configuration).dialogStatisticsVerifyStatisticIntent(statisticId, intentId, verifyStatisticIntentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DialogStatisticsApi - factory interface
 * @export
 */
export const DialogStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddDialogStatisticCommand} [addDialogStatisticCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsAddDialogStatistic(addDialogStatisticCommand?: AddDialogStatisticCommand, options?: any): AxiosPromise<DialogStatisticDto> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsAddDialogStatistic(addDialogStatisticCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsDeleteDialogStatistic(id: number, options?: any): AxiosPromise<void> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsDeleteDialogStatistic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogFeedbacks(dialogStatisticsId: number, options?: any): AxiosPromise<Array<DialogFeedbackDto>> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsGetDialogFeedbacks(dialogStatisticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogStatistic(id: number, options?: any): AxiosPromise<DialogStatisticDto> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsGetDialogStatistic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetDialogTranscript(dialogStatisticsId: number, options?: any): AxiosPromise<string> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsGetDialogTranscript(dialogStatisticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dialogStatisticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsGetKpiDetails(dialogStatisticsId: number, options?: any): AxiosPromise<DialogStatisticKpiDetailsDto> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsGetKpiDetails(dialogStatisticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {SortType} [sortOrder] 
         * @param {string} [orderBy] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsListDialogStatisticsService(skip?: number, take?: number, sortOrder?: SortType, orderBy?: string, from?: string, to?: string, search?: string, options?: any): AxiosPromise<PartialListDialogStatisticDto> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsListDialogStatisticsService(skip, take, sortOrder, orderBy, from, to, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {ConversationFeedback} [conversationFeedback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsStoreFeedback(sessionId: string, conversationFeedback?: ConversationFeedback, options?: any): AxiosPromise<void> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsStoreFeedback(sessionId, conversationFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} statisticId 
         * @param {number} intentId 
         * @param {VerifyStatisticIntentCommand} [verifyStatisticIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dialogStatisticsVerifyStatisticIntent(statisticId: number, intentId: number, verifyStatisticIntentCommand?: VerifyStatisticIntentCommand, options?: any): AxiosPromise<void> {
            return DialogStatisticsApiFp(configuration).dialogStatisticsVerifyStatisticIntent(statisticId, intentId, verifyStatisticIntentCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DialogStatisticsApi - object-oriented interface
 * @export
 * @class DialogStatisticsApi
 * @extends {BaseAPI}
 */
export class DialogStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {AddDialogStatisticCommand} [addDialogStatisticCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsAddDialogStatistic(addDialogStatisticCommand?: AddDialogStatisticCommand, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsAddDialogStatistic(addDialogStatisticCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsDeleteDialogStatistic(id: number, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsDeleteDialogStatistic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dialogStatisticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsGetDialogFeedbacks(dialogStatisticsId: number, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsGetDialogFeedbacks(dialogStatisticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsGetDialogStatistic(id: number, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsGetDialogStatistic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dialogStatisticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsGetDialogTranscript(dialogStatisticsId: number, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsGetDialogTranscript(dialogStatisticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dialogStatisticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsGetKpiDetails(dialogStatisticsId: number, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsGetKpiDetails(dialogStatisticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {SortType} [sortOrder] 
     * @param {string} [orderBy] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsListDialogStatisticsService(skip?: number, take?: number, sortOrder?: SortType, orderBy?: string, from?: string, to?: string, search?: string, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsListDialogStatisticsService(skip, take, sortOrder, orderBy, from, to, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {ConversationFeedback} [conversationFeedback] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsStoreFeedback(sessionId: string, conversationFeedback?: ConversationFeedback, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsStoreFeedback(sessionId, conversationFeedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} statisticId 
     * @param {number} intentId 
     * @param {VerifyStatisticIntentCommand} [verifyStatisticIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogStatisticsApi
     */
    public dialogStatisticsVerifyStatisticIntent(statisticId: number, intentId: number, verifyStatisticIntentCommand?: VerifyStatisticIntentCommand, options?: any) {
        return DialogStatisticsApiFp(this.configuration).dialogStatisticsVerifyStatisticIntent(statisticId, intentId, verifyStatisticIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalAssetsApi - axios parameter creator
 * @export
 */
export const GlobalAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} intentId 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCopyIntentToCompany: async (intentId: number, companyId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsCopyIntentToCompany.');
            }
            const localVarPath = `/api/GlobalAssets/CopyIntentToCompany/intents/{intentId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateGlobalAssetCategoryCommand} [createGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateCategory: async (createGlobalAssetCategoryCommand?: CreateGlobalAssetCategoryCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/CreateCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createGlobalAssetCategoryCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createGlobalAssetCategoryCommand !== undefined ? createGlobalAssetCategoryCommand : {})
                : (createGlobalAssetCategoryCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {CreateGlobalAssetIntentCommand} [createGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateIntent: async (categoryId: number, createGlobalAssetIntentCommand?: CreateGlobalAssetIntentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling globalAssetsCreateIntent.');
            }
            const localVarPath = `/api/GlobalAssets/CreateIntent/{categoryId}/intent`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createGlobalAssetIntentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createGlobalAssetIntentCommand !== undefined ? createGlobalAssetIntentCommand : {})
                : (createGlobalAssetIntentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateSample: async (intentId: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsCreateSample.');
            }
            const localVarPath = `/api/GlobalAssets/CreateSample/intents/{intentId}/sample`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteGlobalAssetCategoriesCommand} [deleteGlobalAssetCategoriesCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteCategories: async (deleteGlobalAssetCategoriesCommand?: DeleteGlobalAssetCategoriesCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/DeleteCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteGlobalAssetCategoriesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteGlobalAssetCategoriesCommand !== undefined ? deleteGlobalAssetCategoriesCommand : {})
                : (deleteGlobalAssetCategoriesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteGlobalAssetIntentCommand} [deleteGlobalAssetIntentCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteIntents: async (deleteGlobalAssetIntentCommand?: DeleteGlobalAssetIntentCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/DeleteIntents/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteGlobalAssetIntentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteGlobalAssetIntentCommand !== undefined ? deleteGlobalAssetIntentCommand : {})
                : (deleteGlobalAssetIntentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteSample: async (intentId: number, sampleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsDeleteSample.');
            }
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling globalAssetsDeleteSample.');
            }
            const localVarPath = `/api/GlobalAssets/DeleteSample/intents/{intentId}/{sampleId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)))
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} selectedLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsExportData: async (selectedLanguage: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedLanguage' is not null or undefined
            if (selectedLanguage === null || selectedLanguage === undefined) {
                throw new RequiredError('selectedLanguage','Required parameter selectedLanguage was null or undefined when calling globalAssetsExportData.');
            }
            const localVarPath = `/api/GlobalAssets/ExportData/export/{selectedLanguage}`
                .replace(`{${"selectedLanguage"}}`, encodeURIComponent(String(selectedLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/GetCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetFullData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/GetFullData/full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetIntents: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling globalAssetsGetIntents.');
            }
            const localVarPath = `/api/GlobalAssets/GetIntents/{categoryId}/intents`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetTemplatesConnections: async (intentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsGetTemplatesConnections.');
            }
            const localVarPath = `/api/GlobalAssets/GetTemplatesConnections/intents/{intentId}/connections`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsImportData: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GlobalAssets/ImportData/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsMoveAndMergeIntentToCategory: async (intentId: number, body?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsMoveAndMergeIntentToCategory.');
            }
            const localVarPath = `/api/GlobalAssets/MoveAndMergeIntentToCategory/intents/{intentId}/move`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {UpdateGlobalAssetCategoryCommand} [updateGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateCategory: async (categoryId: number, updateGlobalAssetCategoryCommand?: UpdateGlobalAssetCategoryCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling globalAssetsUpdateCategory.');
            }
            const localVarPath = `/api/GlobalAssets/UpdateCategory/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateGlobalAssetCategoryCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateGlobalAssetCategoryCommand !== undefined ? updateGlobalAssetCategoryCommand : {})
                : (updateGlobalAssetCategoryCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {UpdateGlobalAssetIntentCommand} [updateGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateIntent: async (intentId: number, updateGlobalAssetIntentCommand?: UpdateGlobalAssetIntentCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsUpdateIntent.');
            }
            const localVarPath = `/api/GlobalAssets/UpdateIntent/intents/{intentId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateGlobalAssetIntentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateGlobalAssetIntentCommand !== undefined ? updateGlobalAssetIntentCommand : {})
                : (updateGlobalAssetIntentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateSample: async (intentId: number, sampleId: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling globalAssetsUpdateSample.');
            }
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling globalAssetsUpdateSample.');
            }
            const localVarPath = `/api/GlobalAssets/UpdateSample/intents/{intentId}/{sampleId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)))
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalAssetsApi - functional programming interface
 * @export
 */
export const GlobalAssetsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} intentId 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsCopyIntentToCompany(intentId: number, companyId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsCopyIntentToCompany(intentId, companyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateGlobalAssetCategoryCommand} [createGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsCreateCategory(createGlobalAssetCategoryCommand?: CreateGlobalAssetCategoryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetFullDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsCreateCategory(createGlobalAssetCategoryCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {CreateGlobalAssetIntentCommand} [createGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsCreateIntent(categoryId: number, createGlobalAssetIntentCommand?: CreateGlobalAssetIntentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetIntentDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsCreateIntent(categoryId, createGlobalAssetIntentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsCreateSample(intentId: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetIntentDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsCreateSample(intentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteGlobalAssetCategoriesCommand} [deleteGlobalAssetCategoriesCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand?: DeleteGlobalAssetCategoriesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteGlobalAssetIntentCommand} [deleteGlobalAssetIntentCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand?: DeleteGlobalAssetIntentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsDeleteSample(intentId: number, sampleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetIntentDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsDeleteSample(intentId, sampleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} selectedLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsExportData(selectedLanguage: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsExportData(selectedLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsGetCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAssetCategoryDto>>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsGetCategories(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsGetFullData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAssetFullDto>>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsGetFullData(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsGetIntents(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAssetIntentDto>>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsGetIntents(categoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsGetTemplatesConnections(intentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAssetConnection>>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsGetTemplatesConnections(intentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsImportData(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsImportData(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsMoveAndMergeIntentToCategory(intentId: number, body?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsMoveAndMergeIntentToCategory(intentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {UpdateGlobalAssetCategoryCommand} [updateGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsUpdateCategory(categoryId: number, updateGlobalAssetCategoryCommand?: UpdateGlobalAssetCategoryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetFullDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsUpdateCategory(categoryId, updateGlobalAssetCategoryCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {UpdateGlobalAssetIntentCommand} [updateGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsUpdateIntent(intentId: number, updateGlobalAssetIntentCommand?: UpdateGlobalAssetIntentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetIntentDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsUpdateIntent(intentId, updateGlobalAssetIntentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAssetsUpdateSample(intentId: number, sampleId: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAssetIntentDto>> {
            const localVarAxiosArgs = await GlobalAssetsApiAxiosParamCreator(configuration).globalAssetsUpdateSample(intentId, sampleId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GlobalAssetsApi - factory interface
 * @export
 */
export const GlobalAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} intentId 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCopyIntentToCompany(intentId: number, companyId?: number, options?: any): AxiosPromise<void> {
            return GlobalAssetsApiFp(configuration).globalAssetsCopyIntentToCompany(intentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateGlobalAssetCategoryCommand} [createGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateCategory(createGlobalAssetCategoryCommand?: CreateGlobalAssetCategoryCommand, options?: any): AxiosPromise<GlobalAssetFullDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsCreateCategory(createGlobalAssetCategoryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {CreateGlobalAssetIntentCommand} [createGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateIntent(categoryId: number, createGlobalAssetIntentCommand?: CreateGlobalAssetIntentCommand, options?: any): AxiosPromise<GlobalAssetIntentDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsCreateIntent(categoryId, createGlobalAssetIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsCreateSample(intentId: number, body?: string, options?: any): AxiosPromise<GlobalAssetIntentDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsCreateSample(intentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteGlobalAssetCategoriesCommand} [deleteGlobalAssetCategoriesCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand?: DeleteGlobalAssetCategoriesCommand, options?: any): AxiosPromise<void> {
            return GlobalAssetsApiFp(configuration).globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteGlobalAssetIntentCommand} [deleteGlobalAssetIntentCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand?: DeleteGlobalAssetIntentCommand, options?: any): AxiosPromise<void> {
            return GlobalAssetsApiFp(configuration).globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsDeleteSample(intentId: number, sampleId: number, options?: any): AxiosPromise<GlobalAssetIntentDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsDeleteSample(intentId, sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} selectedLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsExportData(selectedLanguage: string, options?: any): AxiosPromise<any> {
            return GlobalAssetsApiFp(configuration).globalAssetsExportData(selectedLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetCategories(options?: any): AxiosPromise<Array<GlobalAssetCategoryDto>> {
            return GlobalAssetsApiFp(configuration).globalAssetsGetCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetFullData(options?: any): AxiosPromise<Array<GlobalAssetFullDto>> {
            return GlobalAssetsApiFp(configuration).globalAssetsGetFullData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetIntents(categoryId: number, options?: any): AxiosPromise<Array<GlobalAssetIntentDto>> {
            return GlobalAssetsApiFp(configuration).globalAssetsGetIntents(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsGetTemplatesConnections(intentId: number, options?: any): AxiosPromise<Array<GlobalAssetConnection>> {
            return GlobalAssetsApiFp(configuration).globalAssetsGetTemplatesConnections(intentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsImportData(file?: any, options?: any): AxiosPromise<void> {
            return GlobalAssetsApiFp(configuration).globalAssetsImportData(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsMoveAndMergeIntentToCategory(intentId: number, body?: number, options?: any): AxiosPromise<void> {
            return GlobalAssetsApiFp(configuration).globalAssetsMoveAndMergeIntentToCategory(intentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {UpdateGlobalAssetCategoryCommand} [updateGlobalAssetCategoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateCategory(categoryId: number, updateGlobalAssetCategoryCommand?: UpdateGlobalAssetCategoryCommand, options?: any): AxiosPromise<GlobalAssetFullDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsUpdateCategory(categoryId, updateGlobalAssetCategoryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {UpdateGlobalAssetIntentCommand} [updateGlobalAssetIntentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateIntent(intentId: number, updateGlobalAssetIntentCommand?: UpdateGlobalAssetIntentCommand, options?: any): AxiosPromise<GlobalAssetIntentDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsUpdateIntent(intentId, updateGlobalAssetIntentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} intentId 
         * @param {number} sampleId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAssetsUpdateSample(intentId: number, sampleId: number, body?: string, options?: any): AxiosPromise<GlobalAssetIntentDto> {
            return GlobalAssetsApiFp(configuration).globalAssetsUpdateSample(intentId, sampleId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalAssetsApi - object-oriented interface
 * @export
 * @class GlobalAssetsApi
 * @extends {BaseAPI}
 */
export class GlobalAssetsApi extends BaseAPI {
    /**
     * 
     * @param {number} intentId 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsCopyIntentToCompany(intentId: number, companyId?: number, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsCopyIntentToCompany(intentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateGlobalAssetCategoryCommand} [createGlobalAssetCategoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsCreateCategory(createGlobalAssetCategoryCommand?: CreateGlobalAssetCategoryCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsCreateCategory(createGlobalAssetCategoryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {CreateGlobalAssetIntentCommand} [createGlobalAssetIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsCreateIntent(categoryId: number, createGlobalAssetIntentCommand?: CreateGlobalAssetIntentCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsCreateIntent(categoryId, createGlobalAssetIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsCreateSample(intentId: number, body?: string, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsCreateSample(intentId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteGlobalAssetCategoriesCommand} [deleteGlobalAssetCategoriesCommand] A list of documents IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand?: DeleteGlobalAssetCategoriesCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsDeleteCategories(deleteGlobalAssetCategoriesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteGlobalAssetIntentCommand} [deleteGlobalAssetIntentCommand] A list of documents IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand?: DeleteGlobalAssetIntentCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsDeleteIntents(deleteGlobalAssetIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {number} sampleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsDeleteSample(intentId: number, sampleId: number, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsDeleteSample(intentId, sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} selectedLanguage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsExportData(selectedLanguage: string, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsExportData(selectedLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsGetCategories(options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsGetCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsGetFullData(options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsGetFullData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsGetIntents(categoryId: number, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsGetIntents(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsGetTemplatesConnections(intentId: number, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsGetTemplatesConnections(intentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsImportData(file?: any, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsImportData(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsMoveAndMergeIntentToCategory(intentId: number, body?: number, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsMoveAndMergeIntentToCategory(intentId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {UpdateGlobalAssetCategoryCommand} [updateGlobalAssetCategoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsUpdateCategory(categoryId: number, updateGlobalAssetCategoryCommand?: UpdateGlobalAssetCategoryCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsUpdateCategory(categoryId, updateGlobalAssetCategoryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {UpdateGlobalAssetIntentCommand} [updateGlobalAssetIntentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsUpdateIntent(intentId: number, updateGlobalAssetIntentCommand?: UpdateGlobalAssetIntentCommand, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsUpdateIntent(intentId, updateGlobalAssetIntentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} intentId 
     * @param {number} sampleId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAssetsApi
     */
    public globalAssetsUpdateSample(intentId: number, sampleId: number, body?: string, options?: any) {
        return GlobalAssetsApiFp(this.configuration).globalAssetsUpdateSample(intentId, sampleId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalIndexApi - axios parameter creator
 * @export
 */
export const MedicalIndexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMedicalProtocolCommand} [createMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexAddMedicalIndex: async (createMedicalProtocolCommand?: CreateMedicalProtocolCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalIndex/AddMedicalIndex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createMedicalProtocolCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createMedicalProtocolCommand !== undefined ? createMedicalProtocolCommand : {})
                : (createMedicalProtocolCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {CreateActionCommand} [createActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateAction: async (medIndexId: number, chapterId: number, createActionCommand?: CreateActionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexCreateAction.');
            }
            // verify required parameter 'chapterId' is not null or undefined
            if (chapterId === null || chapterId === undefined) {
                throw new RequiredError('chapterId','Required parameter chapterId was null or undefined when calling medicalIndexCreateAction.');
            }
            const localVarPath = `/api/MedicalIndex/CreateAction/{medIndexId}/{chapterId}/action`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createActionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createActionCommand !== undefined ? createActionCommand : {})
                : (createActionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {CreateChapterCommand} [createChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateChapter: async (medIndexId: number, createChapterCommand?: CreateChapterCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexCreateChapter.');
            }
            const localVarPath = `/api/MedicalIndex/CreateChapter/{medIndexId}/chapter`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createChapterCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createChapterCommand !== undefined ? createChapterCommand : {})
                : (createChapterCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateMedIndexAssignment: async (medIndexId: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexCreateMedIndexAssignment.');
            }
            const localVarPath = `/api/MedicalIndex/CreateMedIndexAssignment/{medIndexId}/assignment`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteAction: async (medIndexId: number, chapterId: number, actionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexDeleteAction.');
            }
            // verify required parameter 'chapterId' is not null or undefined
            if (chapterId === null || chapterId === undefined) {
                throw new RequiredError('chapterId','Required parameter chapterId was null or undefined when calling medicalIndexDeleteAction.');
            }
            // verify required parameter 'actionId' is not null or undefined
            if (actionId === null || actionId === undefined) {
                throw new RequiredError('actionId','Required parameter actionId was null or undefined when calling medicalIndexDeleteAction.');
            }
            const localVarPath = `/api/MedicalIndex/DeleteAction/{medIndexId}/{chapterId}/action/{actionId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)))
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteChapter: async (medIndexId: number, chapterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexDeleteChapter.');
            }
            // verify required parameter 'chapterId' is not null or undefined
            if (chapterId === null || chapterId === undefined) {
                throw new RequiredError('chapterId','Required parameter chapterId was null or undefined when calling medicalIndexDeleteChapter.');
            }
            const localVarPath = `/api/MedicalIndex/DeleteChapter/{medIndexId}/chapter/{chapterId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteMedIndexAssignment: async (medIndexId: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexDeleteMedIndexAssignment.');
            }
            const localVarPath = `/api/MedicalIndex/DeleteMedIndexAssignment/{medIndexId}/assignment`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteMedicalIndex: async (medIndexId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexDeleteMedicalIndex.');
            }
            const localVarPath = `/api/MedicalIndex/DeleteMedicalIndex/{medIndexId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexGetMedicalIndex: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling medicalIndexGetMedicalIndex.');
            }
            const localVarPath = `/api/MedicalIndex/GetMedicalIndex/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexGetMedicalIndexList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalIndex/GetMedicalIndexList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {UpdateActionCommand} [updateActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateAction: async (medIndexId: number, chapterId: number, actionId: number, updateActionCommand?: UpdateActionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexUpdateAction.');
            }
            // verify required parameter 'chapterId' is not null or undefined
            if (chapterId === null || chapterId === undefined) {
                throw new RequiredError('chapterId','Required parameter chapterId was null or undefined when calling medicalIndexUpdateAction.');
            }
            // verify required parameter 'actionId' is not null or undefined
            if (actionId === null || actionId === undefined) {
                throw new RequiredError('actionId','Required parameter actionId was null or undefined when calling medicalIndexUpdateAction.');
            }
            const localVarPath = `/api/MedicalIndex/UpdateAction/{medIndexId}/{chapterId}/action/{actionId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)))
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateActionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateActionCommand !== undefined ? updateActionCommand : {})
                : (updateActionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {UpdateChapterCommand} [updateChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateChapter: async (medIndexId: number, chapterId: number, updateChapterCommand?: UpdateChapterCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexUpdateChapter.');
            }
            // verify required parameter 'chapterId' is not null or undefined
            if (chapterId === null || chapterId === undefined) {
                throw new RequiredError('chapterId','Required parameter chapterId was null or undefined when calling medicalIndexUpdateChapter.');
            }
            const localVarPath = `/api/MedicalIndex/UpdateChapter/{medIndexId}/chapter/{chapterId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)))
                .replace(`{${"chapterId"}}`, encodeURIComponent(String(chapterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateChapterCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateChapterCommand !== undefined ? updateChapterCommand : {})
                : (updateChapterCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {UpdateMedicalProtocolCommand} [updateMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateMedicalIndex: async (medIndexId: number, updateMedicalProtocolCommand?: UpdateMedicalProtocolCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medIndexId' is not null or undefined
            if (medIndexId === null || medIndexId === undefined) {
                throw new RequiredError('medIndexId','Required parameter medIndexId was null or undefined when calling medicalIndexUpdateMedicalIndex.');
            }
            const localVarPath = `/api/MedicalIndex/UpdateMedicalIndex/{medIndexId}`
                .replace(`{${"medIndexId"}}`, encodeURIComponent(String(medIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateMedicalProtocolCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateMedicalProtocolCommand !== undefined ? updateMedicalProtocolCommand : {})
                : (updateMedicalProtocolCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalIndexApi - functional programming interface
 * @export
 */
export const MedicalIndexApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMedicalProtocolCommand} [createMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexAddMedicalIndex(createMedicalProtocolCommand?: CreateMedicalProtocolCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexAddMedicalIndex(createMedicalProtocolCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {CreateActionCommand} [createActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexCreateAction(medIndexId: number, chapterId: number, createActionCommand?: CreateActionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexCreateAction(medIndexId, chapterId, createActionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {CreateChapterCommand} [createChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexCreateChapter(medIndexId: number, createChapterCommand?: CreateChapterCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexCreateChapter(medIndexId, createChapterCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexCreateMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexCreateMedIndexAssignment(medIndexId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexDeleteAction(medIndexId: number, chapterId: number, actionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexDeleteAction(medIndexId, chapterId, actionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexDeleteChapter(medIndexId: number, chapterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexDeleteChapter(medIndexId, chapterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexDeleteMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexDeleteMedIndexAssignment(medIndexId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexDeleteMedicalIndex(medIndexId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexDeleteMedicalIndex(medIndexId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexGetMedicalIndex(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexGetMedicalIndex(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexGetMedicalIndexList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicalProtocolDto>>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexGetMedicalIndexList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {UpdateActionCommand} [updateActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexUpdateAction(medIndexId: number, chapterId: number, actionId: number, updateActionCommand?: UpdateActionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexUpdateAction(medIndexId, chapterId, actionId, updateActionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {UpdateChapterCommand} [updateChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexUpdateChapter(medIndexId: number, chapterId: number, updateChapterCommand?: UpdateChapterCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexUpdateChapter(medIndexId, chapterId, updateChapterCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {UpdateMedicalProtocolCommand} [updateMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalIndexUpdateMedicalIndex(medIndexId: number, updateMedicalProtocolCommand?: UpdateMedicalProtocolCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalProtocolDto>> {
            const localVarAxiosArgs = await MedicalIndexApiAxiosParamCreator(configuration).medicalIndexUpdateMedicalIndex(medIndexId, updateMedicalProtocolCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MedicalIndexApi - factory interface
 * @export
 */
export const MedicalIndexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateMedicalProtocolCommand} [createMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexAddMedicalIndex(createMedicalProtocolCommand?: CreateMedicalProtocolCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexAddMedicalIndex(createMedicalProtocolCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {CreateActionCommand} [createActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateAction(medIndexId: number, chapterId: number, createActionCommand?: CreateActionCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexCreateAction(medIndexId, chapterId, createActionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {CreateChapterCommand} [createChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateChapter(medIndexId: number, createChapterCommand?: CreateChapterCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexCreateChapter(medIndexId, createChapterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexCreateMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexCreateMedIndexAssignment(medIndexId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteAction(medIndexId: number, chapterId: number, actionId: number, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexDeleteAction(medIndexId, chapterId, actionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteChapter(medIndexId: number, chapterId: number, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexDeleteChapter(medIndexId, chapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexDeleteMedIndexAssignment(medIndexId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexDeleteMedicalIndex(medIndexId: number, options?: any): AxiosPromise<void> {
            return MedicalIndexApiFp(configuration).medicalIndexDeleteMedicalIndex(medIndexId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexGetMedicalIndex(id: number, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexGetMedicalIndex(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexGetMedicalIndexList(options?: any): AxiosPromise<Array<MedicalProtocolDto>> {
            return MedicalIndexApiFp(configuration).medicalIndexGetMedicalIndexList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {number} actionId 
         * @param {UpdateActionCommand} [updateActionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateAction(medIndexId: number, chapterId: number, actionId: number, updateActionCommand?: UpdateActionCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexUpdateAction(medIndexId, chapterId, actionId, updateActionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {number} chapterId 
         * @param {UpdateChapterCommand} [updateChapterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateChapter(medIndexId: number, chapterId: number, updateChapterCommand?: UpdateChapterCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexUpdateChapter(medIndexId, chapterId, updateChapterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medIndexId 
         * @param {UpdateMedicalProtocolCommand} [updateMedicalProtocolCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalIndexUpdateMedicalIndex(medIndexId: number, updateMedicalProtocolCommand?: UpdateMedicalProtocolCommand, options?: any): AxiosPromise<MedicalProtocolDto> {
            return MedicalIndexApiFp(configuration).medicalIndexUpdateMedicalIndex(medIndexId, updateMedicalProtocolCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalIndexApi - object-oriented interface
 * @export
 * @class MedicalIndexApi
 * @extends {BaseAPI}
 */
export class MedicalIndexApi extends BaseAPI {
    /**
     * 
     * @param {CreateMedicalProtocolCommand} [createMedicalProtocolCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexAddMedicalIndex(createMedicalProtocolCommand?: CreateMedicalProtocolCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexAddMedicalIndex(createMedicalProtocolCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {number} chapterId 
     * @param {CreateActionCommand} [createActionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexCreateAction(medIndexId: number, chapterId: number, createActionCommand?: CreateActionCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexCreateAction(medIndexId, chapterId, createActionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {CreateChapterCommand} [createChapterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexCreateChapter(medIndexId: number, createChapterCommand?: CreateChapterCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexCreateChapter(medIndexId, createChapterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexCreateMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexCreateMedIndexAssignment(medIndexId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {number} chapterId 
     * @param {number} actionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexDeleteAction(medIndexId: number, chapterId: number, actionId: number, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexDeleteAction(medIndexId, chapterId, actionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {number} chapterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexDeleteChapter(medIndexId: number, chapterId: number, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexDeleteChapter(medIndexId, chapterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexDeleteMedIndexAssignment(medIndexId: number, requestBody?: Array<number>, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexDeleteMedIndexAssignment(medIndexId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexDeleteMedicalIndex(medIndexId: number, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexDeleteMedicalIndex(medIndexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexGetMedicalIndex(id: number, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexGetMedicalIndex(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexGetMedicalIndexList(options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexGetMedicalIndexList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {number} chapterId 
     * @param {number} actionId 
     * @param {UpdateActionCommand} [updateActionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexUpdateAction(medIndexId: number, chapterId: number, actionId: number, updateActionCommand?: UpdateActionCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexUpdateAction(medIndexId, chapterId, actionId, updateActionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {number} chapterId 
     * @param {UpdateChapterCommand} [updateChapterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexUpdateChapter(medIndexId: number, chapterId: number, updateChapterCommand?: UpdateChapterCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexUpdateChapter(medIndexId, chapterId, updateChapterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medIndexId 
     * @param {UpdateMedicalProtocolCommand} [updateMedicalProtocolCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalIndexApi
     */
    public medicalIndexUpdateMedicalIndex(medIndexId: number, updateMedicalProtocolCommand?: UpdateMedicalProtocolCommand, options?: any) {
        return MedicalIndexApiFp(this.configuration).medicalIndexUpdateMedicalIndex(medIndexId, updateMedicalProtocolCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessApi - axios parameter creator
 * @export
 */
export const ProcessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EndCallCommand} [endCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEndCall: async (endCallCommand?: EndCallCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Process/EndCall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof endCallCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(endCallCommand !== undefined ? endCallCommand : {})
                : (endCallCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phoneNum] 
         * @param {number} [sceneId] 
         * @param {number} [companyId] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {IntentProcessingProvider} [intentProcessingProvider] 
         * @param {boolean} [playGreeting] 
         * @param {boolean} [isInhouseStt] 
         * @param {boolean} [playBeep] 
         * @param {number} [speechTimeout] 
         * @param {boolean} [isDigitsGatherEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMakeCall: async (phoneNum?: string, sceneId?: number, companyId?: number, userId?: number, userName?: string, intentProcessingProvider?: IntentProcessingProvider, playGreeting?: boolean, isInhouseStt?: boolean, playBeep?: boolean, speechTimeout?: number, isDigitsGatherEnabled?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Process/MakeCall/call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phoneNum !== undefined) {
                localVarQueryParameter['phoneNum'] = phoneNum;
            }

            if (sceneId !== undefined) {
                localVarQueryParameter['sceneId'] = sceneId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (intentProcessingProvider !== undefined) {
                localVarQueryParameter['intentProcessingProvider'] = intentProcessingProvider;
            }

            if (playGreeting !== undefined) {
                localVarQueryParameter['playGreeting'] = playGreeting;
            }

            if (isInhouseStt !== undefined) {
                localVarQueryParameter['isInhouseStt'] = isInhouseStt;
            }

            if (playBeep !== undefined) {
                localVarQueryParameter['playBeep'] = playBeep;
            }

            if (speechTimeout !== undefined) {
                localVarQueryParameter['speechTimeout'] = speechTimeout;
            }

            if (isDigitsGatherEnabled !== undefined) {
                localVarQueryParameter['isDigitsGatherEnabled'] = isDigitsGatherEnabled;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessDialogCommand} [processDialogCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcessDialog: async (processDialogCommand?: ProcessDialogCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Process/ProcessDialog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processDialogCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processDialogCommand !== undefined ? processDialogCommand : {})
                : (processDialogCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [transcriptionSid] 
         * @param {string} [transcriptionText] 
         * @param {string} [transcriptionStatus] 
         * @param {string} [transcriptionUrl] 
         * @param {string} [recordingSid] 
         * @param {string} [recordingUrl] 
         * @param {string} [callSid] 
         * @param {string} [accountSid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [callStatus] 
         * @param {string} [apiVersion] 
         * @param {string} [direction] 
         * @param {string} [forwardedFrom] 
         * @param {string} [speechResult] 
         * @param {string} [digits] 
         * @param {string} [confidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReceiveCall: async (transcriptionSid?: string, transcriptionText?: string, transcriptionStatus?: string, transcriptionUrl?: string, recordingSid?: string, recordingUrl?: string, callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, speechResult?: string, digits?: string, confidence?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Process/ReceiveCall/receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (transcriptionSid !== undefined) { 
                localVarFormParams.set('transcriptionSid', transcriptionSid as any);
            }
    
            if (transcriptionText !== undefined) { 
                localVarFormParams.set('transcriptionText', transcriptionText as any);
            }
    
            if (transcriptionStatus !== undefined) { 
                localVarFormParams.set('transcriptionStatus', transcriptionStatus as any);
            }
    
            if (transcriptionUrl !== undefined) { 
                localVarFormParams.set('transcriptionUrl', transcriptionUrl as any);
            }
    
            if (recordingSid !== undefined) { 
                localVarFormParams.set('recordingSid', recordingSid as any);
            }
    
            if (recordingUrl !== undefined) { 
                localVarFormParams.set('recordingUrl', recordingUrl as any);
            }
    
            if (callSid !== undefined) { 
                localVarFormParams.set('callSid', callSid as any);
            }
    
            if (accountSid !== undefined) { 
                localVarFormParams.set('accountSid', accountSid as any);
            }
    
            if (from !== undefined) { 
                localVarFormParams.set('from', from as any);
            }
    
            if (to !== undefined) { 
                localVarFormParams.set('to', to as any);
            }
    
            if (callStatus !== undefined) { 
                localVarFormParams.set('callStatus', callStatus as any);
            }
    
            if (apiVersion !== undefined) { 
                localVarFormParams.set('apiVersion', apiVersion as any);
            }
    
            if (direction !== undefined) { 
                localVarFormParams.set('direction', direction as any);
            }
    
            if (forwardedFrom !== undefined) { 
                localVarFormParams.set('forwardedFrom', forwardedFrom as any);
            }
    
            if (speechResult !== undefined) { 
                localVarFormParams.set('speechResult', speechResult as any);
            }
    
            if (digits !== undefined) { 
                localVarFormParams.set('digits', digits as any);
            }
    
            if (confidence !== undefined) { 
                localVarFormParams.set('confidence', confidence as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpeechToSpeechCommand} [speechToSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSpeechToSpeech: async (speechToSpeechCommand?: SpeechToSpeechCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Process/SpeechToSpeech`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof speechToSpeechCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(speechToSpeechCommand !== undefined ? speechToSpeechCommand : {})
                : (speechToSpeechCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessApi - functional programming interface
 * @export
 */
export const ProcessApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EndCallCommand} [endCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processEndCall(endCallCommand?: EndCallCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProcessApiAxiosParamCreator(configuration).processEndCall(endCallCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [phoneNum] 
         * @param {number} [sceneId] 
         * @param {number} [companyId] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {IntentProcessingProvider} [intentProcessingProvider] 
         * @param {boolean} [playGreeting] 
         * @param {boolean} [isInhouseStt] 
         * @param {boolean} [playBeep] 
         * @param {number} [speechTimeout] 
         * @param {boolean} [isDigitsGatherEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMakeCall(phoneNum?: string, sceneId?: number, companyId?: number, userId?: number, userName?: string, intentProcessingProvider?: IntentProcessingProvider, playGreeting?: boolean, isInhouseStt?: boolean, playBeep?: boolean, speechTimeout?: number, isDigitsGatherEnabled?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProcessApiAxiosParamCreator(configuration).processMakeCall(phoneNum, sceneId, companyId, userId, userName, intentProcessingProvider, playGreeting, isInhouseStt, playBeep, speechTimeout, isDigitsGatherEnabled, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ProcessDialogCommand} [processDialogCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProcessDialog(processDialogCommand?: ProcessDialogCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultDto>> {
            const localVarAxiosArgs = await ProcessApiAxiosParamCreator(configuration).processProcessDialog(processDialogCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [transcriptionSid] 
         * @param {string} [transcriptionText] 
         * @param {string} [transcriptionStatus] 
         * @param {string} [transcriptionUrl] 
         * @param {string} [recordingSid] 
         * @param {string} [recordingUrl] 
         * @param {string} [callSid] 
         * @param {string} [accountSid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [callStatus] 
         * @param {string} [apiVersion] 
         * @param {string} [direction] 
         * @param {string} [forwardedFrom] 
         * @param {string} [speechResult] 
         * @param {string} [digits] 
         * @param {string} [confidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processReceiveCall(transcriptionSid?: string, transcriptionText?: string, transcriptionStatus?: string, transcriptionUrl?: string, recordingSid?: string, recordingUrl?: string, callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, speechResult?: string, digits?: string, confidence?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProcessApiAxiosParamCreator(configuration).processReceiveCall(transcriptionSid, transcriptionText, transcriptionStatus, transcriptionUrl, recordingSid, recordingUrl, callSid, accountSid, from, to, callStatus, apiVersion, direction, forwardedFrom, speechResult, digits, confidence, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SpeechToSpeechCommand} [speechToSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processSpeechToSpeech(speechToSpeechCommand?: SpeechToSpeechCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProcessApiAxiosParamCreator(configuration).processSpeechToSpeech(speechToSpeechCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessApi - factory interface
 * @export
 */
export const ProcessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {EndCallCommand} [endCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEndCall(endCallCommand?: EndCallCommand, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).processEndCall(endCallCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phoneNum] 
         * @param {number} [sceneId] 
         * @param {number} [companyId] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {IntentProcessingProvider} [intentProcessingProvider] 
         * @param {boolean} [playGreeting] 
         * @param {boolean} [isInhouseStt] 
         * @param {boolean} [playBeep] 
         * @param {number} [speechTimeout] 
         * @param {boolean} [isDigitsGatherEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMakeCall(phoneNum?: string, sceneId?: number, companyId?: number, userId?: number, userName?: string, intentProcessingProvider?: IntentProcessingProvider, playGreeting?: boolean, isInhouseStt?: boolean, playBeep?: boolean, speechTimeout?: number, isDigitsGatherEnabled?: boolean, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).processMakeCall(phoneNum, sceneId, companyId, userId, userName, intentProcessingProvider, playGreeting, isInhouseStt, playBeep, speechTimeout, isDigitsGatherEnabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessDialogCommand} [processDialogCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcessDialog(processDialogCommand?: ProcessDialogCommand, options?: any): AxiosPromise<ProcessResultDto> {
            return ProcessApiFp(configuration).processProcessDialog(processDialogCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [transcriptionSid] 
         * @param {string} [transcriptionText] 
         * @param {string} [transcriptionStatus] 
         * @param {string} [transcriptionUrl] 
         * @param {string} [recordingSid] 
         * @param {string} [recordingUrl] 
         * @param {string} [callSid] 
         * @param {string} [accountSid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [callStatus] 
         * @param {string} [apiVersion] 
         * @param {string} [direction] 
         * @param {string} [forwardedFrom] 
         * @param {string} [speechResult] 
         * @param {string} [digits] 
         * @param {string} [confidence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReceiveCall(transcriptionSid?: string, transcriptionText?: string, transcriptionStatus?: string, transcriptionUrl?: string, recordingSid?: string, recordingUrl?: string, callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, speechResult?: string, digits?: string, confidence?: string, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).processReceiveCall(transcriptionSid, transcriptionText, transcriptionStatus, transcriptionUrl, recordingSid, recordingUrl, callSid, accountSid, from, to, callStatus, apiVersion, direction, forwardedFrom, speechResult, digits, confidence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeechToSpeechCommand} [speechToSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSpeechToSpeech(speechToSpeechCommand?: SpeechToSpeechCommand, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).processSpeechToSpeech(speechToSpeechCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessApi - object-oriented interface
 * @export
 * @class ProcessApi
 * @extends {BaseAPI}
 */
export class ProcessApi extends BaseAPI {
    /**
     * 
     * @param {EndCallCommand} [endCallCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public processEndCall(endCallCommand?: EndCallCommand, options?: any) {
        return ProcessApiFp(this.configuration).processEndCall(endCallCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phoneNum] 
     * @param {number} [sceneId] 
     * @param {number} [companyId] 
     * @param {number} [userId] 
     * @param {string} [userName] 
     * @param {IntentProcessingProvider} [intentProcessingProvider] 
     * @param {boolean} [playGreeting] 
     * @param {boolean} [isInhouseStt] 
     * @param {boolean} [playBeep] 
     * @param {number} [speechTimeout] 
     * @param {boolean} [isDigitsGatherEnabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public processMakeCall(phoneNum?: string, sceneId?: number, companyId?: number, userId?: number, userName?: string, intentProcessingProvider?: IntentProcessingProvider, playGreeting?: boolean, isInhouseStt?: boolean, playBeep?: boolean, speechTimeout?: number, isDigitsGatherEnabled?: boolean, options?: any) {
        return ProcessApiFp(this.configuration).processMakeCall(phoneNum, sceneId, companyId, userId, userName, intentProcessingProvider, playGreeting, isInhouseStt, playBeep, speechTimeout, isDigitsGatherEnabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessDialogCommand} [processDialogCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public processProcessDialog(processDialogCommand?: ProcessDialogCommand, options?: any) {
        return ProcessApiFp(this.configuration).processProcessDialog(processDialogCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [transcriptionSid] 
     * @param {string} [transcriptionText] 
     * @param {string} [transcriptionStatus] 
     * @param {string} [transcriptionUrl] 
     * @param {string} [recordingSid] 
     * @param {string} [recordingUrl] 
     * @param {string} [callSid] 
     * @param {string} [accountSid] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [callStatus] 
     * @param {string} [apiVersion] 
     * @param {string} [direction] 
     * @param {string} [forwardedFrom] 
     * @param {string} [speechResult] 
     * @param {string} [digits] 
     * @param {string} [confidence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public processReceiveCall(transcriptionSid?: string, transcriptionText?: string, transcriptionStatus?: string, transcriptionUrl?: string, recordingSid?: string, recordingUrl?: string, callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, speechResult?: string, digits?: string, confidence?: string, options?: any) {
        return ProcessApiFp(this.configuration).processReceiveCall(transcriptionSid, transcriptionText, transcriptionStatus, transcriptionUrl, recordingSid, recordingUrl, callSid, accountSid, from, to, callStatus, apiVersion, direction, forwardedFrom, speechResult, digits, confidence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeechToSpeechCommand} [speechToSpeechCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public processSpeechToSpeech(speechToSpeechCommand?: SpeechToSpeechCommand, options?: any) {
        return ProcessApiFp(this.configuration).processSpeechToSpeech(speechToSpeechCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScenariosApi - axios parameter creator
 * @export
 */
export const ScenariosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddCompaniesToSharedScene: async (id: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosAddCompaniesToSharedScene.');
            }
            const localVarPath = `/api/Scenarios/AddCompaniesToSharedScene/{id}/sharedCompany`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {AddCompanyVariableCommand} [addCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddCompanyVariable: async (id: number, companyId?: number, addCompanyVariableCommand?: AddCompanyVariableCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosAddCompanyVariable.');
            }
            const localVarPath = `/api/Scenarios/AddCompanyVariable/{id}/variables/company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addCompanyVariableCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addCompanyVariableCommand !== undefined ? addCompanyVariableCommand : {})
                : (addCompanyVariableCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<AddVariableCommand>} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddMultipleVariables: async (id: number, addVariableCommand?: Array<AddVariableCommand>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosAddMultipleVariables.');
            }
            const localVarPath = `/api/Scenarios/AddMultipleVariables/{id}/variables/multiple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addVariableCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addVariableCommand !== undefined ? addVariableCommand : {})
                : (addVariableCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddScenarioCommand} [addScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddScene: async (addScenarioCommand?: AddScenarioCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/AddScene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addScenarioCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addScenarioCommand !== undefined ? addScenarioCommand : {})
                : (addScenarioCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddVariableCommand} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddVariable: async (id: number, addVariableCommand?: AddVariableCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosAddVariable.');
            }
            const localVarPath = `/api/Scenarios/AddVariable/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addVariableCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addVariableCommand !== undefined ? addVariableCommand : {})
                : (addVariableCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosConvertSceneToSharedScene: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosConvertSceneToSharedScene.');
            }
            const localVarPath = `/api/Scenarios/ConvertSceneToSharedScene/{id}/convertToShared`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ConvertFromSharedScenarioCommand} [convertFromSharedScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosConvertSharedSceneToScene: async (id: number, convertFromSharedScenarioCommand?: ConvertFromSharedScenarioCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosConvertSharedSceneToScene.');
            }
            const localVarPath = `/api/Scenarios/ConvertSharedSceneToScene/{id}/convertFromShared`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof convertFromSharedScenarioCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(convertFromSharedScenarioCommand !== undefined ? convertFromSharedScenarioCommand : {})
                : (convertFromSharedScenarioCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CopyScenarioCommand} [copyScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosCopyScene: async (copyScenarioCommand?: CopyScenarioCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/CopyScene/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof copyScenarioCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(copyScenarioCommand !== undefined ? copyScenarioCommand : {})
                : (copyScenarioCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateScenarioContentCommand} [updateScenarioContentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosEditSceneContent: async (updateScenarioContentCommand?: UpdateScenarioContentCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/EditSceneContent/editContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateScenarioContentCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateScenarioContentCommand !== undefined ? updateScenarioContentCommand : {})
                : (updateScenarioContentCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateScenarioSettingsCommand} [updateScenarioSettingsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosEditSceneInfo: async (updateScenarioSettingsCommand?: UpdateScenarioSettingsCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/EditSceneInfo/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateScenarioSettingsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateScenarioSettingsCommand !== undefined ? updateScenarioSettingsCommand : {})
                : (updateScenarioSettingsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosFilterScenes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/FilterScenes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetCompanyVariables: async (id: number, companyId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosGetCompanyVariables.');
            }
            const localVarPath = `/api/Scenarios/GetCompanyVariables/{id}/variables/company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetDialogStatisticScenarioVersion: async (scenarioId: number, versionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            if (scenarioId === null || scenarioId === undefined) {
                throw new RequiredError('scenarioId','Required parameter scenarioId was null or undefined when calling scenariosGetDialogStatisticScenarioVersion.');
            }
            // verify required parameter 'versionId' is not null or undefined
            if (versionId === null || versionId === undefined) {
                throw new RequiredError('versionId','Required parameter versionId was null or undefined when calling scenariosGetDialogStatisticScenarioVersion.');
            }
            const localVarPath = `/api/Scenarios/GetDialogStatisticScenarioVersion/{scenarioId}/{versionId}/dialogStatistic`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} langCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetPersonaRoles: async (langCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            if (langCode === null || langCode === undefined) {
                throw new RequiredError('langCode','Required parameter langCode was null or undefined when calling scenariosGetPersonaRoles.');
            }
            const localVarPath = `/api/Scenarios/GetPersonaRoles/personaroles/{langCode}`
                .replace(`{${"langCode"}}`, encodeURIComponent(String(langCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScenarioUpdates: async (scenarioId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            if (scenarioId === null || scenarioId === undefined) {
                throw new RequiredError('scenarioId','Required parameter scenarioId was null or undefined when calling scenariosGetScenarioUpdates.');
            }
            const localVarPath = `/api/Scenarios/GetScenarioUpdates/updates/{scenarioId}`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScenarioVersion: async (scenarioId: number, versionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            if (scenarioId === null || scenarioId === undefined) {
                throw new RequiredError('scenarioId','Required parameter scenarioId was null or undefined when calling scenariosGetScenarioVersion.');
            }
            // verify required parameter 'versionId' is not null or undefined
            if (versionId === null || versionId === undefined) {
                throw new RequiredError('versionId','Required parameter versionId was null or undefined when calling scenariosGetScenarioVersion.');
            }
            const localVarPath = `/api/Scenarios/GetScenarioVersion/{scenarioId}/{versionId}`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScene: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosGetScene.');
            }
            const localVarPath = `/api/Scenarios/GetScene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetVariables: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosGetVariables.');
            }
            const localVarPath = `/api/Scenarios/GetVariables/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveCompaniesFromSharedScene: async (id: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosRemoveCompaniesFromSharedScene.');
            }
            const localVarPath = `/api/Scenarios/RemoveCompaniesFromSharedScene/{id}/sharedCompany`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveCompanyVariable: async (id: number, companyVariableId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosRemoveCompanyVariable.');
            }
            // verify required parameter 'companyVariableId' is not null or undefined
            if (companyVariableId === null || companyVariableId === undefined) {
                throw new RequiredError('companyVariableId','Required parameter companyVariableId was null or undefined when calling scenariosRemoveCompanyVariable.');
            }
            const localVarPath = `/api/Scenarios/RemoveCompanyVariable/{id}/variables/company/{companyVariableId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"companyVariableId"}}`, encodeURIComponent(String(companyVariableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveScene: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosRemoveScene.');
            }
            const localVarPath = `/api/Scenarios/RemoveScene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [variableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveVariable: async (id: number, variableName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosRemoveVariable.');
            }
            const localVarPath = `/api/Scenarios/RemoveVariable/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variableName !== undefined) {
                localVarQueryParameter['variableName'] = variableName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestoreScenarioVersionCommand} [restoreScenarioVersionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRestoreScenarioVersion: async (restoreScenarioVersionCommand?: RestoreScenarioVersionCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scenarios/RestoreScenarioVersion/restoreScenario`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof restoreScenarioVersionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(restoreScenarioVersionCommand !== undefined ? restoreScenarioVersionCommand : {})
                : (restoreScenarioVersionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {UpdateCompanyVariableCommand} [updateCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosUpdateCompanyVariable: async (id: number, companyVariableId: number, updateCompanyVariableCommand?: UpdateCompanyVariableCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosUpdateCompanyVariable.');
            }
            // verify required parameter 'companyVariableId' is not null or undefined
            if (companyVariableId === null || companyVariableId === undefined) {
                throw new RequiredError('companyVariableId','Required parameter companyVariableId was null or undefined when calling scenariosUpdateCompanyVariable.');
            }
            const localVarPath = `/api/Scenarios/UpdateCompanyVariable/{id}/variables/company/{companyVariableId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"companyVariableId"}}`, encodeURIComponent(String(companyVariableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateCompanyVariableCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateCompanyVariableCommand !== undefined ? updateCompanyVariableCommand : {})
                : (updateCompanyVariableCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVariableCommand} [updateVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosUpdateVariable: async (id: number, updateVariableCommand?: UpdateVariableCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling scenariosUpdateVariable.');
            }
            const localVarPath = `/api/Scenarios/UpdateVariable/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateVariableCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateVariableCommand !== undefined ? updateVariableCommand : {})
                : (updateVariableCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenariosApi - functional programming interface
 * @export
 */
export const ScenariosApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosAddCompaniesToSharedScene(id: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosAddCompaniesToSharedScene(id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {AddCompanyVariableCommand} [addCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosAddCompanyVariable(id: number, companyId?: number, addCompanyVariableCommand?: AddCompanyVariableCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosAddCompanyVariable(id, companyId, addCompanyVariableCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<AddVariableCommand>} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosAddMultipleVariables(id: number, addVariableCommand?: Array<AddVariableCommand>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosAddMultipleVariables(id, addVariableCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AddScenarioCommand} [addScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosAddScene(addScenarioCommand?: AddScenarioCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosAddScene(addScenarioCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddVariableCommand} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosAddVariable(id: number, addVariableCommand?: AddVariableCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosAddVariable(id, addVariableCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosConvertSceneToSharedScene(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosConvertSceneToSharedScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ConvertFromSharedScenarioCommand} [convertFromSharedScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosConvertSharedSceneToScene(id: number, convertFromSharedScenarioCommand?: ConvertFromSharedScenarioCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosConvertSharedSceneToScene(id, convertFromSharedScenarioCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CopyScenarioCommand} [copyScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosCopyScene(copyScenarioCommand?: CopyScenarioCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneExtendedDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosCopyScene(copyScenarioCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateScenarioContentCommand} [updateScenarioContentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosEditSceneContent(updateScenarioContentCommand?: UpdateScenarioContentCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosEditSceneContent(updateScenarioContentCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateScenarioSettingsCommand} [updateScenarioSettingsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosEditSceneInfo(updateScenarioSettingsCommand?: UpdateScenarioSettingsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosEditSceneInfo(updateScenarioSettingsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosFilterScenes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleScenarioDto>>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosFilterScenes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetCompanyVariables(id: number, companyId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyVariableDto>>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetCompanyVariables(id, companyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetDialogStatisticScenarioVersion(scenarioId: number, versionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDialogStatisticDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetDialogStatisticScenarioVersion(scenarioId, versionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} langCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetPersonaRoles(langCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SceneContextPersonaRoleDto>>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetPersonaRoles(langCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetScenarioUpdates(scenarioId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScenarioUpdateDto>>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetScenarioUpdates(scenarioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetScenarioVersion(scenarioId: number, versionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetScenarioVersion(scenarioId, versionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetScene(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosGetVariables(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VariableDto>>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosGetVariables(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosRemoveCompaniesFromSharedScene(id: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosRemoveCompaniesFromSharedScene(id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosRemoveCompanyVariable(id: number, companyVariableId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosRemoveCompanyVariable(id, companyVariableId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosRemoveScene(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosRemoveScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [variableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosRemoveVariable(id: number, variableName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosRemoveVariable(id, variableName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RestoreScenarioVersionCommand} [restoreScenarioVersionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosRestoreScenarioVersion(restoreScenarioVersionCommand?: RestoreScenarioVersionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosRestoreScenarioVersion(restoreScenarioVersionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {UpdateCompanyVariableCommand} [updateCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosUpdateCompanyVariable(id: number, companyVariableId: number, updateCompanyVariableCommand?: UpdateCompanyVariableCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosUpdateCompanyVariable(id, companyVariableId, updateCompanyVariableCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVariableCommand} [updateVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenariosUpdateVariable(id: number, updateVariableCommand?: UpdateVariableCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScenariosApiAxiosParamCreator(configuration).scenariosUpdateVariable(id, updateVariableCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ScenariosApi - factory interface
 * @export
 */
export const ScenariosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddCompaniesToSharedScene(id: number, requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosAddCompaniesToSharedScene(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {AddCompanyVariableCommand} [addCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddCompanyVariable(id: number, companyId?: number, addCompanyVariableCommand?: AddCompanyVariableCommand, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosAddCompanyVariable(id, companyId, addCompanyVariableCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<AddVariableCommand>} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddMultipleVariables(id: number, addVariableCommand?: Array<AddVariableCommand>, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosAddMultipleVariables(id, addVariableCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddScenarioCommand} [addScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddScene(addScenarioCommand?: AddScenarioCommand, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosAddScene(addScenarioCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AddVariableCommand} [addVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosAddVariable(id: number, addVariableCommand?: AddVariableCommand, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosAddVariable(id, addVariableCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosConvertSceneToSharedScene(id: number, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosConvertSceneToSharedScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ConvertFromSharedScenarioCommand} [convertFromSharedScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosConvertSharedSceneToScene(id: number, convertFromSharedScenarioCommand?: ConvertFromSharedScenarioCommand, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosConvertSharedSceneToScene(id, convertFromSharedScenarioCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CopyScenarioCommand} [copyScenarioCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosCopyScene(copyScenarioCommand?: CopyScenarioCommand, options?: any): AxiosPromise<SceneExtendedDto> {
            return ScenariosApiFp(configuration).scenariosCopyScene(copyScenarioCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateScenarioContentCommand} [updateScenarioContentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosEditSceneContent(updateScenarioContentCommand?: UpdateScenarioContentCommand, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosEditSceneContent(updateScenarioContentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateScenarioSettingsCommand} [updateScenarioSettingsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosEditSceneInfo(updateScenarioSettingsCommand?: UpdateScenarioSettingsCommand, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosEditSceneInfo(updateScenarioSettingsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosFilterScenes(options?: any): AxiosPromise<Array<SimpleScenarioDto>> {
            return ScenariosApiFp(configuration).scenariosFilterScenes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetCompanyVariables(id: number, companyId?: number, options?: any): AxiosPromise<Array<CompanyVariableDto>> {
            return ScenariosApiFp(configuration).scenariosGetCompanyVariables(id, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetDialogStatisticScenarioVersion(scenarioId: number, versionId: number, options?: any): AxiosPromise<SceneDialogStatisticDto> {
            return ScenariosApiFp(configuration).scenariosGetDialogStatisticScenarioVersion(scenarioId, versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} langCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetPersonaRoles(langCode: string, options?: any): AxiosPromise<Array<SceneContextPersonaRoleDto>> {
            return ScenariosApiFp(configuration).scenariosGetPersonaRoles(langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScenarioUpdates(scenarioId: number, options?: any): AxiosPromise<Array<ScenarioUpdateDto>> {
            return ScenariosApiFp(configuration).scenariosGetScenarioUpdates(scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scenarioId 
         * @param {number} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScenarioVersion(scenarioId: number, versionId: number, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosGetScenarioVersion(scenarioId, versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetScene(id: number, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosGetScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosGetVariables(id: number, options?: any): AxiosPromise<Array<VariableDto>> {
            return ScenariosApiFp(configuration).scenariosGetVariables(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveCompaniesFromSharedScene(id: number, requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosRemoveCompaniesFromSharedScene(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveCompanyVariable(id: number, companyVariableId: number, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosRemoveCompanyVariable(id, companyVariableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveScene(id: number, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosRemoveScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [variableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRemoveVariable(id: number, variableName?: string, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosRemoveVariable(id, variableName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestoreScenarioVersionCommand} [restoreScenarioVersionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosRestoreScenarioVersion(restoreScenarioVersionCommand?: RestoreScenarioVersionCommand, options?: any): AxiosPromise<SceneDto> {
            return ScenariosApiFp(configuration).scenariosRestoreScenarioVersion(restoreScenarioVersionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} companyVariableId 
         * @param {UpdateCompanyVariableCommand} [updateCompanyVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosUpdateCompanyVariable(id: number, companyVariableId: number, updateCompanyVariableCommand?: UpdateCompanyVariableCommand, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosUpdateCompanyVariable(id, companyVariableId, updateCompanyVariableCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVariableCommand} [updateVariableCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenariosUpdateVariable(id: number, updateVariableCommand?: UpdateVariableCommand, options?: any): AxiosPromise<void> {
            return ScenariosApiFp(configuration).scenariosUpdateVariable(id, updateVariableCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenariosApi - object-oriented interface
 * @export
 * @class ScenariosApi
 * @extends {BaseAPI}
 */
export class ScenariosApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosAddCompaniesToSharedScene(id: number, requestBody?: Array<number>, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosAddCompaniesToSharedScene(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [companyId] 
     * @param {AddCompanyVariableCommand} [addCompanyVariableCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosAddCompanyVariable(id: number, companyId?: number, addCompanyVariableCommand?: AddCompanyVariableCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosAddCompanyVariable(id, companyId, addCompanyVariableCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<AddVariableCommand>} [addVariableCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosAddMultipleVariables(id: number, addVariableCommand?: Array<AddVariableCommand>, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosAddMultipleVariables(id, addVariableCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddScenarioCommand} [addScenarioCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosAddScene(addScenarioCommand?: AddScenarioCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosAddScene(addScenarioCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AddVariableCommand} [addVariableCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosAddVariable(id: number, addVariableCommand?: AddVariableCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosAddVariable(id, addVariableCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosConvertSceneToSharedScene(id: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosConvertSceneToSharedScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ConvertFromSharedScenarioCommand} [convertFromSharedScenarioCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosConvertSharedSceneToScene(id: number, convertFromSharedScenarioCommand?: ConvertFromSharedScenarioCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosConvertSharedSceneToScene(id, convertFromSharedScenarioCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CopyScenarioCommand} [copyScenarioCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosCopyScene(copyScenarioCommand?: CopyScenarioCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosCopyScene(copyScenarioCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateScenarioContentCommand} [updateScenarioContentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosEditSceneContent(updateScenarioContentCommand?: UpdateScenarioContentCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosEditSceneContent(updateScenarioContentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateScenarioSettingsCommand} [updateScenarioSettingsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosEditSceneInfo(updateScenarioSettingsCommand?: UpdateScenarioSettingsCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosEditSceneInfo(updateScenarioSettingsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosFilterScenes(options?: any) {
        return ScenariosApiFp(this.configuration).scenariosFilterScenes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetCompanyVariables(id: number, companyId?: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetCompanyVariables(id, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scenarioId 
     * @param {number} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetDialogStatisticScenarioVersion(scenarioId: number, versionId: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetDialogStatisticScenarioVersion(scenarioId, versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} langCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetPersonaRoles(langCode: string, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetPersonaRoles(langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetScenarioUpdates(scenarioId: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetScenarioUpdates(scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scenarioId 
     * @param {number} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetScenarioVersion(scenarioId: number, versionId: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetScenarioVersion(scenarioId, versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetScene(id: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosGetVariables(id: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosGetVariables(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosRemoveCompaniesFromSharedScene(id: number, requestBody?: Array<number>, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosRemoveCompaniesFromSharedScene(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} companyVariableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosRemoveCompanyVariable(id: number, companyVariableId: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosRemoveCompanyVariable(id, companyVariableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosRemoveScene(id: number, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosRemoveScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [variableName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosRemoveVariable(id: number, variableName?: string, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosRemoveVariable(id, variableName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestoreScenarioVersionCommand} [restoreScenarioVersionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosRestoreScenarioVersion(restoreScenarioVersionCommand?: RestoreScenarioVersionCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosRestoreScenarioVersion(restoreScenarioVersionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} companyVariableId 
     * @param {UpdateCompanyVariableCommand} [updateCompanyVariableCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosUpdateCompanyVariable(id: number, companyVariableId: number, updateCompanyVariableCommand?: UpdateCompanyVariableCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosUpdateCompanyVariable(id, companyVariableId, updateCompanyVariableCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateVariableCommand} [updateVariableCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public scenariosUpdateVariable(id: number, updateVariableCommand?: UpdateVariableCommand, options?: any) {
        return ScenariosApiFp(this.configuration).scenariosUpdateVariable(id, updateVariableCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneAddSceneToGroup: async (groupId: number, sceneIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling sceneAddSceneToGroup.');
            }
            const localVarPath = `/api/Scene/AddSceneToGroup/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sceneIds) {
                localVarQueryParameter['sceneIds'] = sceneIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneCreateGroup: async (companyId?: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scene/CreateGroup/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneDeleteGroup: async (groupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling sceneDeleteGroup.');
            }
            const localVarPath = `/api/Scene/DeleteGroup/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneGetSceneGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Scene/GetSceneGroups/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneRemoveScenesFromGroup: async (groupId: number, sceneIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling sceneRemoveScenesFromGroup.');
            }
            const localVarPath = `/api/Scene/RemoveScenesFromGroup/groups/{groupId}/scenes`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sceneIds) {
                localVarQueryParameter['sceneIds'] = sceneIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateSceneGroupCommand} [updateSceneGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneUpdateGroup: async (groupId: number, updateSceneGroupCommand?: UpdateSceneGroupCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling sceneUpdateGroup.');
            }
            const localVarPath = `/api/Scene/UpdateGroup/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateSceneGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateSceneGroupCommand !== undefined ? updateSceneGroupCommand : {})
                : (updateSceneGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneAddSceneToGroup(groupId: number, sceneIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneGroupDto>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneAddSceneToGroup(groupId, sceneIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneCreateGroup(companyId?: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneGroupDto>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneCreateGroup(companyId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneDeleteGroup(groupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneDeleteGroup(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneGetSceneGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SceneGroupDto>>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneGetSceneGroups(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneRemoveScenesFromGroup(groupId: number, sceneIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneGroupDto>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneRemoveScenesFromGroup(groupId, sceneIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateSceneGroupCommand} [updateSceneGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sceneUpdateGroup(groupId: number, updateSceneGroupCommand?: UpdateSceneGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneGroupDto>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).sceneUpdateGroup(groupId, updateSceneGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneAddSceneToGroup(groupId: number, sceneIds?: Array<number>, options?: any): AxiosPromise<SceneGroupDto> {
            return SceneApiFp(configuration).sceneAddSceneToGroup(groupId, sceneIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneCreateGroup(companyId?: number, body?: string, options?: any): AxiosPromise<SceneGroupDto> {
            return SceneApiFp(configuration).sceneCreateGroup(companyId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneDeleteGroup(groupId: number, options?: any): AxiosPromise<void> {
            return SceneApiFp(configuration).sceneDeleteGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneGetSceneGroups(options?: any): AxiosPromise<Array<SceneGroupDto>> {
            return SceneApiFp(configuration).sceneGetSceneGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {Array<number>} [sceneIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneRemoveScenesFromGroup(groupId: number, sceneIds?: Array<number>, options?: any): AxiosPromise<SceneGroupDto> {
            return SceneApiFp(configuration).sceneRemoveScenesFromGroup(groupId, sceneIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateSceneGroupCommand} [updateSceneGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sceneUpdateGroup(groupId: number, updateSceneGroupCommand?: UpdateSceneGroupCommand, options?: any): AxiosPromise<SceneGroupDto> {
            return SceneApiFp(configuration).sceneUpdateGroup(groupId, updateSceneGroupCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @param {number} groupId 
     * @param {Array<number>} [sceneIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneAddSceneToGroup(groupId: number, sceneIds?: Array<number>, options?: any) {
        return SceneApiFp(this.configuration).sceneAddSceneToGroup(groupId, sceneIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneCreateGroup(companyId?: number, body?: string, options?: any) {
        return SceneApiFp(this.configuration).sceneCreateGroup(companyId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneDeleteGroup(groupId: number, options?: any) {
        return SceneApiFp(this.configuration).sceneDeleteGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneGetSceneGroups(options?: any) {
        return SceneApiFp(this.configuration).sceneGetSceneGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {Array<number>} [sceneIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneRemoveScenesFromGroup(groupId: number, sceneIds?: Array<number>, options?: any) {
        return SceneApiFp(this.configuration).sceneRemoveScenesFromGroup(groupId, sceneIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {UpdateSceneGroupCommand} [updateSceneGroupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public sceneUpdateGroup(groupId: number, updateSceneGroupCommand?: UpdateSceneGroupCommand, options?: any) {
        return SceneApiFp(this.configuration).sceneUpdateGroup(groupId, updateSceneGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScheduledCallsApi - axios parameter creator
 * @export
 */
export const ScheduledCallsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateScheduledCallCommand} [createScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsCreate: async (createScheduledCallCommand?: CreateScheduledCallCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ScheduledCalls/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createScheduledCallCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createScheduledCallCommand !== undefined ? createScheduledCallCommand : {})
                : (createScheduledCallCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteScheduledCallsCommand} [deleteScheduledCallsCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsDelete: async (deleteScheduledCallsCommand?: DeleteScheduledCallsCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ScheduledCalls/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteScheduledCallsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteScheduledCallsCommand !== undefined ? deleteScheduledCallsCommand : {})
                : (deleteScheduledCallsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsGet: async (skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ScheduledCalls/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {UpdateScheduledCallCommand} [updateScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsUpdate: async (scheduledCallId: number, updateScheduledCallCommand?: UpdateScheduledCallCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCallId' is not null or undefined
            if (scheduledCallId === null || scheduledCallId === undefined) {
                throw new RequiredError('scheduledCallId','Required parameter scheduledCallId was null or undefined when calling scheduledCallsUpdate.');
            }
            const localVarPath = `/api/ScheduledCalls/Update/{scheduledCallId}`
                .replace(`{${"scheduledCallId"}}`, encodeURIComponent(String(scheduledCallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateScheduledCallCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateScheduledCallCommand !== undefined ? updateScheduledCallCommand : {})
                : (updateScheduledCallCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {ScheduledCallStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsUpdateStatus: async (scheduledCallId: number, status?: ScheduledCallStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledCallId' is not null or undefined
            if (scheduledCallId === null || scheduledCallId === undefined) {
                throw new RequiredError('scheduledCallId','Required parameter scheduledCallId was null or undefined when calling scheduledCallsUpdateStatus.');
            }
            const localVarPath = `/api/ScheduledCalls/UpdateStatus/{scheduledCallId}/status`
                .replace(`{${"scheduledCallId"}}`, encodeURIComponent(String(scheduledCallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduledCallsApi - functional programming interface
 * @export
 */
export const ScheduledCallsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateScheduledCallCommand} [createScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledCallsCreate(createScheduledCallCommand?: CreateScheduledCallCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCallDto>> {
            const localVarAxiosArgs = await ScheduledCallsApiAxiosParamCreator(configuration).scheduledCallsCreate(createScheduledCallCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteScheduledCallsCommand} [deleteScheduledCallsCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledCallsDelete(deleteScheduledCallsCommand?: DeleteScheduledCallsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ScheduledCallsApiAxiosParamCreator(configuration).scheduledCallsDelete(deleteScheduledCallsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledCallsGet(skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialListScheduledCallDto>> {
            const localVarAxiosArgs = await ScheduledCallsApiAxiosParamCreator(configuration).scheduledCallsGet(skip, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {UpdateScheduledCallCommand} [updateScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledCallsUpdate(scheduledCallId: number, updateScheduledCallCommand?: UpdateScheduledCallCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCallDto>> {
            const localVarAxiosArgs = await ScheduledCallsApiAxiosParamCreator(configuration).scheduledCallsUpdate(scheduledCallId, updateScheduledCallCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {ScheduledCallStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledCallsUpdateStatus(scheduledCallId: number, status?: ScheduledCallStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledCallDto>> {
            const localVarAxiosArgs = await ScheduledCallsApiAxiosParamCreator(configuration).scheduledCallsUpdateStatus(scheduledCallId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ScheduledCallsApi - factory interface
 * @export
 */
export const ScheduledCallsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateScheduledCallCommand} [createScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsCreate(createScheduledCallCommand?: CreateScheduledCallCommand, options?: any): AxiosPromise<ScheduledCallDto> {
            return ScheduledCallsApiFp(configuration).scheduledCallsCreate(createScheduledCallCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteScheduledCallsCommand} [deleteScheduledCallsCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsDelete(deleteScheduledCallsCommand?: DeleteScheduledCallsCommand, options?: any): AxiosPromise<void> {
            return ScheduledCallsApiFp(configuration).scheduledCallsDelete(deleteScheduledCallsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsGet(skip?: number, take?: number, options?: any): AxiosPromise<PartialListScheduledCallDto> {
            return ScheduledCallsApiFp(configuration).scheduledCallsGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {UpdateScheduledCallCommand} [updateScheduledCallCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsUpdate(scheduledCallId: number, updateScheduledCallCommand?: UpdateScheduledCallCommand, options?: any): AxiosPromise<ScheduledCallDto> {
            return ScheduledCallsApiFp(configuration).scheduledCallsUpdate(scheduledCallId, updateScheduledCallCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} scheduledCallId 
         * @param {ScheduledCallStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledCallsUpdateStatus(scheduledCallId: number, status?: ScheduledCallStatus, options?: any): AxiosPromise<ScheduledCallDto> {
            return ScheduledCallsApiFp(configuration).scheduledCallsUpdateStatus(scheduledCallId, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScheduledCallsApi - object-oriented interface
 * @export
 * @class ScheduledCallsApi
 * @extends {BaseAPI}
 */
export class ScheduledCallsApi extends BaseAPI {
    /**
     * 
     * @param {CreateScheduledCallCommand} [createScheduledCallCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledCallsApi
     */
    public scheduledCallsCreate(createScheduledCallCommand?: CreateScheduledCallCommand, options?: any) {
        return ScheduledCallsApiFp(this.configuration).scheduledCallsCreate(createScheduledCallCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteScheduledCallsCommand} [deleteScheduledCallsCommand] A list of documents IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledCallsApi
     */
    public scheduledCallsDelete(deleteScheduledCallsCommand?: DeleteScheduledCallsCommand, options?: any) {
        return ScheduledCallsApiFp(this.configuration).scheduledCallsDelete(deleteScheduledCallsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledCallsApi
     */
    public scheduledCallsGet(skip?: number, take?: number, options?: any) {
        return ScheduledCallsApiFp(this.configuration).scheduledCallsGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCallId 
     * @param {UpdateScheduledCallCommand} [updateScheduledCallCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledCallsApi
     */
    public scheduledCallsUpdate(scheduledCallId: number, updateScheduledCallCommand?: UpdateScheduledCallCommand, options?: any) {
        return ScheduledCallsApiFp(this.configuration).scheduledCallsUpdate(scheduledCallId, updateScheduledCallCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} scheduledCallId 
     * @param {ScheduledCallStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledCallsApi
     */
    public scheduledCallsUpdateStatus(scheduledCallId: number, status?: ScheduledCallStatus, options?: any) {
        return ScheduledCallsApiFp(this.configuration).scheduledCallsUpdateStatus(scheduledCallId, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpeechApi - axios parameter creator
 * @export
 */
export const SpeechApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechGetVoiceList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Speech/GetVoiceList/voices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lng] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechRecognizeSpeech: async (lng?: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Speech/RecognizeSpeech/stt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SynthesizeSpeechCommand} [synthesizeSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechSynthesizeSpeech: async (synthesizeSpeechCommand?: SynthesizeSpeechCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Speech/SynthesizeSpeech/synthesizeSpeech`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof synthesizeSpeechCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(synthesizeSpeechCommand !== undefined ? synthesizeSpeechCommand : {})
                : (synthesizeSpeechCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeechApi - functional programming interface
 * @export
 */
export const SpeechApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speechGetVoiceList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Speaker>>> {
            const localVarAxiosArgs = await SpeechApiAxiosParamCreator(configuration).speechGetVoiceList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [lng] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speechRecognizeSpeech(lng?: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SpeechApiAxiosParamCreator(configuration).speechRecognizeSpeech(lng, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SynthesizeSpeechCommand} [synthesizeSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speechSynthesizeSpeech(synthesizeSpeechCommand?: SynthesizeSpeechCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SpeechApiAxiosParamCreator(configuration).speechSynthesizeSpeech(synthesizeSpeechCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpeechApi - factory interface
 * @export
 */
export const SpeechApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechGetVoiceList(options?: any): AxiosPromise<Array<Speaker>> {
            return SpeechApiFp(configuration).speechGetVoiceList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lng] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechRecognizeSpeech(lng?: string, file?: any, options?: any): AxiosPromise<string> {
            return SpeechApiFp(configuration).speechRecognizeSpeech(lng, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SynthesizeSpeechCommand} [synthesizeSpeechCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechSynthesizeSpeech(synthesizeSpeechCommand?: SynthesizeSpeechCommand, options?: any): AxiosPromise<string> {
            return SpeechApiFp(configuration).speechSynthesizeSpeech(synthesizeSpeechCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpeechApi - object-oriented interface
 * @export
 * @class SpeechApi
 * @extends {BaseAPI}
 */
export class SpeechApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechApi
     */
    public speechGetVoiceList(options?: any) {
        return SpeechApiFp(this.configuration).speechGetVoiceList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lng] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechApi
     */
    public speechRecognizeSpeech(lng?: string, file?: any, options?: any) {
        return SpeechApiFp(this.configuration).speechRecognizeSpeech(lng, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SynthesizeSpeechCommand} [synthesizeSpeechCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechApi
     */
    public speechSynthesizeSpeech(synthesizeSpeechCommand?: SynthesizeSpeechCommand, options?: any) {
        return SpeechApiFp(this.configuration).speechSynthesizeSpeech(synthesizeSpeechCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrganizationInvitationRequestCommand} [createOrganizationInvitationRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateOrganizationInvitation: async (createOrganizationInvitationRequestCommand?: CreateOrganizationInvitationRequestCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateOrganizationInvitation/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createOrganizationInvitationRequestCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createOrganizationInvitationRequestCommand !== undefined ? createOrganizationInvitationRequestCommand : {})
                : (createOrganizationInvitationRequestCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDeleteUser.');
            }
            const localVarPath = `/api/User/DeleteUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetCurrentUser/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTenants: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetTenants/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUsers/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrganizationInvitationRequestCommand} [createOrganizationInvitationRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand?: CreateOrganizationInvitationRequestCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userDeleteUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGetCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetTenants(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantDto>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGetTenants(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGetUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateOrganizationInvitationRequestCommand} [createOrganizationInvitationRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand?: CreateOrganizationInvitationRequestCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUser(id: number, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetCurrentUser(options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userGetCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTenants(options?: any): AxiosPromise<Array<TenantDto>> {
            return UserApiFp(configuration).userGetTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return UserApiFp(configuration).userGetUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrganizationInvitationRequestCommand} [createOrganizationInvitationRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand?: CreateOrganizationInvitationRequestCommand, options?: any) {
        return UserApiFp(this.configuration).userCreateOrganizationInvitation(createOrganizationInvitationRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteUser(id: number, options?: any) {
        return UserApiFp(this.configuration).userDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetCurrentUser(options?: any) {
        return UserApiFp(this.configuration).userGetCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetTenants(options?: any) {
        return UserApiFp(this.configuration).userGetTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUsers(options?: any) {
        return UserApiFp(this.configuration).userGetUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserGroupApi - axios parameter creator
 * @export
 */
export const UserGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {AddUsersToGroupCommand} [addUsersToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupAddUsers: async (groupId: number, addUsersToGroupCommand?: AddUsersToGroupCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling userGroupAddUsers.');
            }
            const localVarPath = `/api/UserGroup/AddUsers/{groupId}/users`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addUsersToGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addUsersToGroupCommand !== undefined ? addUsersToGroupCommand : {})
                : (addUsersToGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserGroupCommand} [createUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupCreate: async (createUserGroupCommand?: CreateUserGroupCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserGroup/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createUserGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createUserGroupCommand !== undefined ? createUserGroupCommand : {})
                : (createUserGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserGroupCommand} [deleteUserGroupCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDeleteGroups: async (deleteUserGroupCommand?: DeleteUserGroupCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserGroup/DeleteGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteUserGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteUserGroupCommand !== undefined ? deleteUserGroupCommand : {})
                : (deleteUserGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {DeleteUsersFromGroupCommand} [deleteUsersFromGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDeleteUsers: async (groupId: number, deleteUsersFromGroupCommand?: DeleteUsersFromGroupCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling userGroupDeleteUsers.');
            }
            const localVarPath = `/api/UserGroup/DeleteUsers/{groupId}/users`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteUsersFromGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteUsersFromGroupCommand !== undefined ? deleteUsersFromGroupCommand : {})
                : (deleteUsersFromGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupGet: async (skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserGroup/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateUserGroupCommand} [updateUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUpdate: async (groupId: number, updateUserGroupCommand?: UpdateUserGroupCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling userGroupUpdate.');
            }
            const localVarPath = `/api/UserGroup/Update/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateUserGroupCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateUserGroupCommand !== undefined ? updateUserGroupCommand : {})
                : (updateUserGroupCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {AddUsersToGroupCommand} [addUsersToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupAddUsers(groupId: number, addUsersToGroupCommand?: AddUsersToGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupAddUsers(groupId, addUsersToGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateUserGroupCommand} [createUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupCreate(createUserGroupCommand?: CreateUserGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupCreate(createUserGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteUserGroupCommand} [deleteUserGroupCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupDeleteGroups(deleteUserGroupCommand?: DeleteUserGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupDeleteGroups(deleteUserGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {DeleteUsersFromGroupCommand} [deleteUsersFromGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupDeleteUsers(groupId: number, deleteUsersFromGroupCommand?: DeleteUsersFromGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupDeleteUsers(groupId, deleteUsersFromGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupGet(skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialListUserGroupDto>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupGet(skip, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateUserGroupCommand} [updateUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupUpdate(groupId: number, updateUserGroupCommand?: UpdateUserGroupCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDto>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).userGroupUpdate(groupId, updateUserGroupCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} groupId 
         * @param {AddUsersToGroupCommand} [addUsersToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupAddUsers(groupId: number, addUsersToGroupCommand?: AddUsersToGroupCommand, options?: any): AxiosPromise<UserGroupDto> {
            return UserGroupApiFp(configuration).userGroupAddUsers(groupId, addUsersToGroupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserGroupCommand} [createUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupCreate(createUserGroupCommand?: CreateUserGroupCommand, options?: any): AxiosPromise<UserGroupDto> {
            return UserGroupApiFp(configuration).userGroupCreate(createUserGroupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserGroupCommand} [deleteUserGroupCommand] A list of documents IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDeleteGroups(deleteUserGroupCommand?: DeleteUserGroupCommand, options?: any): AxiosPromise<void> {
            return UserGroupApiFp(configuration).userGroupDeleteGroups(deleteUserGroupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {DeleteUsersFromGroupCommand} [deleteUsersFromGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDeleteUsers(groupId: number, deleteUsersFromGroupCommand?: DeleteUsersFromGroupCommand, options?: any): AxiosPromise<void> {
            return UserGroupApiFp(configuration).userGroupDeleteUsers(groupId, deleteUsersFromGroupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupGet(skip?: number, take?: number, options?: any): AxiosPromise<PartialListUserGroupDto> {
            return UserGroupApiFp(configuration).userGroupGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {UpdateUserGroupCommand} [updateUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUpdate(groupId: number, updateUserGroupCommand?: UpdateUserGroupCommand, options?: any): AxiosPromise<UserGroupDto> {
            return UserGroupApiFp(configuration).userGroupUpdate(groupId, updateUserGroupCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * 
     * @param {number} groupId 
     * @param {AddUsersToGroupCommand} [addUsersToGroupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupAddUsers(groupId: number, addUsersToGroupCommand?: AddUsersToGroupCommand, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupAddUsers(groupId, addUsersToGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserGroupCommand} [createUserGroupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupCreate(createUserGroupCommand?: CreateUserGroupCommand, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupCreate(createUserGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUserGroupCommand} [deleteUserGroupCommand] A list of documents IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupDeleteGroups(deleteUserGroupCommand?: DeleteUserGroupCommand, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupDeleteGroups(deleteUserGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {DeleteUsersFromGroupCommand} [deleteUsersFromGroupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupDeleteUsers(groupId: number, deleteUsersFromGroupCommand?: DeleteUsersFromGroupCommand, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupDeleteUsers(groupId, deleteUsersFromGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupGet(skip?: number, take?: number, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {UpdateUserGroupCommand} [updateUserGroupCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupUpdate(groupId: number, updateUserGroupCommand?: UpdateUserGroupCommand, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupUpdate(groupId, updateUserGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoicesApi - axios parameter creator
 * @export
 */
export const VoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesDeleteVoice: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling voicesDeleteVoice.');
            }
            const localVarPath = `/api/Voices/DeleteVoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesDownloadVoice: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling voicesDownloadVoice.');
            }
            const localVarPath = `/api/Voices/DownloadVoice/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesGetVoice: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling voicesGetVoice.');
            }
            const localVarPath = `/api/Voices/GetVoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoicesApi - functional programming interface
 * @export
 */
export const VoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voicesDeleteVoice(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VoicesApiAxiosParamCreator(configuration).voicesDeleteVoice(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voicesDownloadVoice(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VoicesApiAxiosParamCreator(configuration).voicesDownloadVoice(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voicesGetVoice(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await VoicesApiAxiosParamCreator(configuration).voicesGetVoice(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VoicesApi - factory interface
 * @export
 */
export const VoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesDeleteVoice(id: string, options?: any): AxiosPromise<void> {
            return VoicesApiFp(configuration).voicesDeleteVoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesDownloadVoice(id: string, options?: any): AxiosPromise<void> {
            return VoicesApiFp(configuration).voicesDownloadVoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voicesGetVoice(id: string, options?: any): AxiosPromise<string> {
            return VoicesApiFp(configuration).voicesGetVoice(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoicesApi - object-oriented interface
 * @export
 * @class VoicesApi
 * @extends {BaseAPI}
 */
export class VoicesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoicesApi
     */
    public voicesDeleteVoice(id: string, options?: any) {
        return VoicesApiFp(this.configuration).voicesDeleteVoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoicesApi
     */
    public voicesDownloadVoice(id: string, options?: any) {
        return VoicesApiFp(this.configuration).voicesDownloadVoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoicesApi
     */
    public voicesGetVoice(id: string, options?: any) {
        return VoicesApiFp(this.configuration).voicesGetVoice(id, options).then((request) => request(this.axios, this.basePath));
    }
}


