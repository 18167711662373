import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { LinkedScenario } from 'app/models/intents/intentNodeProperties.js';
import type { UserSaysPhrases } from 'app/models/scenarios/scenario.js';

import { ScenarioSelectors } from 'app/selectors/index.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';

import ChipWithTooltipComponent from './components/chipWithTooltipComponent.js';
import type { SceneExtendedDto } from '@/generated-api/index.js';
import { SceneDto } from '@/generated-api/index.js';

export interface Props {
  linkedScenarios: LinkedScenario[];
  containerDOMWidth: number;
}

const ScenarioLinkComponent: React.FC<Props> = ({ linkedScenarios, containerDOMWidth }) => {
  const dispatch = useDispatch();
  const links = useSelector(ScenarioSelectors.linkedScenarios);
  const scenarios = useSelector(ScenarioSelectors.scenariosSelector);
  const userSaysPhrasesByScenarioId = useSelector(ScenarioSelectors.userSaysPhrasesByScenarioId);

  const [linkedScenarioUserSaysPhrases, setLinkedScenarioUserSaysPhrases] = useState<UserSaysPhrases[]>();

  useEffect(() => {
    linkedScenarios.forEach((scenario: LinkedScenario) => {
      const scenarioData: SceneExtendedDto | undefined = scenarios.find(
        (scenarioElem) => scenarioElem.id == scenario.sceneId
      );
      if (scenarioData) {
        dispatch(
          ScenarioActions.getDetailedScenarioDataByScenarioId({
            sceneId: scenario.sceneId,
            versionId: 1, // This is wrong, but linked scenarios are deprecated
            startblockId: scenarioData?.startBlockId
          })
        );
      }
    });
  }, [linkedScenarios, scenarios, dispatch]);

  useEffect(() => {
    if (userSaysPhrasesByScenarioId)
      !linkedScenarioUserSaysPhrases
        ? setLinkedScenarioUserSaysPhrases([userSaysPhrasesByScenarioId])
        : !linkedScenarioUserSaysPhrases.some((elem) => elem.scenarioId === userSaysPhrasesByScenarioId?.scenarioId) &&
          setLinkedScenarioUserSaysPhrases([...linkedScenarioUserSaysPhrases, userSaysPhrasesByScenarioId]);
  }, [linkedScenarioUserSaysPhrases, userSaysPhrasesByScenarioId]);

  const getLinkedSceneUserPhrases = useCallback(
    (scenarioId: number) =>
      linkedScenarioUserSaysPhrases?.find((elem) => elem.scenarioId == scenarioId)?.userSaysPhrases,
    [linkedScenarioUserSaysPhrases]
  );

  const handleOnClick = (scenarioId: number) => {
    dispatch(ScenarioActions.switchCurrentScenarioTo(links.findIndex((elem) => elem.sceneId == scenarioId)));
  };

  return (
    <>
      {linkedScenarios.map(({ id, sceneName, sceneId }, index) => {
        return (
          <ChipWithTooltipComponent
            key={id + index}
            userPhrases={getLinkedSceneUserPhrases(sceneId)}
            index={index}
            scenarioId={sceneId}
            scenarioName={sceneName}
            id={id}
            containerDOMWidth={containerDOMWidth}
            onClick={handleOnClick}
          />
        );
      })}
    </>
  );
};

export default ScenarioLinkComponent;
