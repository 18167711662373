import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AccordionDetails, Box, List, ListItem, MenuItem, TextField } from '@material-ui/core';

import type { SceneDto } from '@/generated-api/index.js';
import { PersonaType } from '@/generated-api/index.js';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import type { IntentEditingFormData } from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/types.js';
import CustomAccordion from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/CustomAccordion.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type DefaultPersonaSettingsProps = {
  scenario: SceneDto;
  title: React.ReactNode;
};

const DefaultPersonaSettings: React.FunctionComponent<DefaultPersonaSettingsProps> = ({ title, scenario }) => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);

  const { control } = useFormContext<IntentEditingFormData>();

  const personas =
    scenario.contexts
      ?.flatMap((context) => context.personas.flatMap((persona) => persona))
      .filter((persona) => persona.type === PersonaType.NUMBER_0) ?? [];

  const selectedPersonaId = useWatch({
    control,
    name: nameof.full<IntentEditingFormData>((u) => u.personaId)
  });

  if (!personas.length) return null;

  const defaultPersona = scenario.defaultPersonaId
    ? personas.find((persona) => persona.id === scenario.defaultPersonaId)
    : personas.some((persona) => persona.isDefault)
    ? personas.find((persona) => persona.isDefault)
    : null;

  // make sure the selected persona is available
  const selectedPersona = personas.find((persona) => persona.id === selectedPersonaId);

  const value = selectedPersona ? selectedPersonaId : null;

  return (
    <CustomAccordion title={title} badgeNumber={null}>
      <AccordionDetails>
        <List className="full-width" dense>
          <ListItem disableGutters dense>
            <Box px={1} flexGrow={1}>
              <Controller
                control={control}
                defaultValue={null}
                name={nameof.full<IntentEditingFormData>((u) => u.personaId)}
                render={(field) => {
                  return (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      label={translate(
                        nameof.full<I18nScenarioEditorNs>((n) => n.defaultPersonaSettings.selectPersonaForThisIntent)
                      )}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (currentValue) => {
                          if (!currentValue) {
                            if (!defaultPersona) {
                              return `${personas[0].roleName} (default)`;
                            }
                            return `${defaultPersona?.roleName} (default)`;
                          }
                          if (defaultPersona && selectedPersona.id === defaultPersona.id) {
                            return `${selectedPersona?.roleName} (default)`;
                          }
                          return selectedPersona?.roleName;
                        }
                      }}
                      value={value ?? null}
                      onChange={(event) => {
                        dispatch(IntentEditingActions.updatePersonaId((event.target.value as unknown) as number));
                        field.onChange(event);
                      }}
                    >
                      {personas.map((persona) => (
                        <MenuItem key={persona.id} value={persona.id}>
                          {defaultPersona?.id === persona.id ? `${persona.roleName} (default)` : persona.roleName}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }}
              />
            </Box>
          </ListItem>
        </List>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default DefaultPersonaSettings;
