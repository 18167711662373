import { call, put, takeLatest } from 'redux-saga/effects';
import { CompanyClient } from 'app/apis/api.js';
import { 
  getAllDepartments,
  getAllDepartmentsSuccess,
  getAllDepartmentsFailure
} from 'app/actions/companyManagment/departmentActions.js';

function* getAllDepartmentsSaga() {
  try {
    const response = yield call([CompanyClient, CompanyClient.companyGetAllDepartments]);
    yield put(getAllDepartmentsSuccess(response.data));
  } catch (error) {
    yield put(getAllDepartmentsFailure(error instanceof Error ? error.message : 'Failed to fetch all departments'));
  }
}

export function* departmentSaga() {
  yield takeLatest(getAllDepartments.type, getAllDepartmentsSaga);
}
