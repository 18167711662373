import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Input, MenuItem, TextField, Switch } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Add, Publish, GetApp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  categoriesSelector,
  isGlobalAssetsLibraryStatusLoadingSelector,
  isGlobalAssetsLibraryStatusSucceedSelector
} from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import CategoryList from 'app/components/GlobalAssetsLibrary/CategoryList.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import { LoadMode } from 'app/store/types.js';
import CategoryCard from 'app/components/GlobalAssetsLibrary/CategoryCard/CategoryCard.js';
import { getUsers } from 'app/actions/userManagement/userManagementActions.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import CreateCategoryDialog from 'app/components/GlobalAssetsLibrary/CreateCategoryDialog/CreateCategoryDialog.js';
import { GlobalAssetsLibraryContext } from 'app/components/GlobalAssetsLibrary/Context/GlobalAssetsLibraryContext.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useParams } from 'react-router';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nCoreNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';
import { CurrentUserSelector } from 'app/selectors/userSelectors.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';

const actions = globalAssetsLibrarySlice.actions;

const useStyles = makeStyles((theme) => ({
  section: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Languages = {
  Danish: 'da-DK',
  'English (UK)': 'en-GB',
  'English (US)': 'en-US',
  'English (Australia)': 'en-AU',
  German: 'de-DE',
  Icelandic: 'is-IS',
  Norwegian: 'nb-NO',
  'Spanish (Mexico)': 'es-MX',
  'Spanish (Spain)': 'es-ES',
  'Spanish (US)': 'es-US',
  Swedish: 'sv-SE',
  'Arabic (UAE)': 'ar-AE',
  Hindi: 'hi-IN'
};

const GlobalAssetsLibraryContent = () => {
  const dispatch = useDispatch();
  const { setSearchText } = useContext(GlobalAssetsLibraryContext);

  useEffect(() => {
    dispatch(actions.load(LoadMode.Hard));
    dispatch(getUsers());
  }, [dispatch]);

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCore] = useTranslation([I18nNamespace.Core]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const categories = useSelector(categoriesSelector);
  const isAssetsLoading = useSelector(isGlobalAssetsLibraryStatusLoadingSelector);
  const isAssetsInitialized = useSelector(isGlobalAssetsLibraryStatusSucceedSelector) || true;
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const currentUser = useSelector(CurrentUserSelector);
  const companies = useSelector(companiesSelector);
  const isSkeletonDisplayed = isAssetsLoading || !isAssetsInitialized;

  const classes = useStyles();

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  const getDefaultLanguage = useCallback(() => {
    const userCompany = companies.find((company) => company.id === currentUser.companyId);
    return userCompany?.defaultLanguage || Languages['Norwegian'];
  }, [companies, currentUser]);

  const [isShowingAll, setIsShowingAll] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => getDefaultLanguage());
  const fileInput = useRef(null);
  const { id: categoryId } = useParams<{ id: string }>();

  useEffect(() => {
    if (!categoryId || !categories.length) return;
    const currentCategory = categories.find((x) => `${x.id}` === categoryId);
    if (!currentCategory) return;
    setSelectedLanguage(currentCategory.language);
  }, [categoryId, categories]);

  const onSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  const handleFileUpload = async (event) => {
    if (!event.target.files.length) return;
    await GlobalAssetsClient.globalAssetsImportData(event.target.files[0]);
    await dispatch(actions.load(LoadMode.Hard));
  };

  const exportGalData = async () => {
    const response = await GlobalAssetsClient.globalAssetsExportData(selectedLanguage);
    const url = URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'global_intents.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleShowAllClick = () => {
    setIsShowingAll(!isShowingAll);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box overflow="hidden" height="100%" display="flex">
        <Grid container>
          <Grid xs={5} md={4} lg={3} item classes={{ root: classes.section }}>
            <Box p={4} overflow="auto">
              <Box mb={2}>
                {isSkeletonDisplayed ? (
                  <Skeleton width={100} height={36} />
                ) : (
                  <>
                    <Button startIcon={<GetApp />} onClick={exportGalData}>
                      {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.exportData))}
                    </Button>

                    <Input
                      style={{ display: 'none' }}
                      inputRef={fileInput}
                      type="file"
                      name="file"
                      onChange={handleFileUpload}
                    />
                    <Button startIcon={<Publish />} onClick={() => fileInput.current?.click()}>
                      {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.importData))}
                    </Button>
                  </>
                )}
              </Box>

              <Box mb={2}>
                <TextField
                  select
                  fullWidth
                  placeholder={translate(
                    nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.selectLanguage)
                  )}
                  label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.language))}
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  <MenuItem key={Languages['Norwegian']} data-cy="Norwegian" value={Languages['Norwegian']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.norwegian))}
                  </MenuItem>
                  <MenuItem key={Languages['English (US)']} data-cy="English (US)" value={Languages['English (US)']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishus))}
                  </MenuItem>
                  <MenuItem key={Languages['English (UK)']} data-cy="English (UK)" value={Languages['English (UK)']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishuk))}
                  </MenuItem>
                  <MenuItem
                    key={Languages['English (Australia)']}
                    data-cy="English (Australia)"
                    value={Languages['English (Australia)']}
                  >
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishAustralia))}
                  </MenuItem>
                  <MenuItem key={Languages['German']} data-cy="German" value={Languages['German']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.german))}
                  </MenuItem>
                  <MenuItem key={Languages['Danish']} data-cy="Danish" value={Languages['Danish']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.danish))}
                  </MenuItem>
                  <MenuItem key={Languages['Swedish']} data-cy="Swedish" value={Languages['Swedish']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.swedish))}
                  </MenuItem>
                  <MenuItem key={Languages['Icelandic']} data-cy="Icelandic" value={Languages['Icelandic']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.icelandic))}
                  </MenuItem>
                  <MenuItem
                    key={Languages['Spanish (Spain)']}
                    data-cy="Spanish (Spain)"
                    value={Languages['Spanish (Spain)']}
                  >
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishSpain))}
                  </MenuItem>
                  <MenuItem key={Languages['Spanish (US)']} data-cy="Spanish (US)" value={Languages['Spanish (US)']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishUs))}
                  </MenuItem>
                  <MenuItem
                    key={Languages['Spanish (Mexico)']}
                    data-cy="Spanish (Mexico)"
                    value={Languages['Spanish (Mexico)']}
                  >
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishMexico))}
                  </MenuItem>
                  <MenuItem key={Languages['Arabic (UAE)']} data-cy="Arabic (UAE)" value={Languages['Arabic (UAE)']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.arabicUae))}
                  </MenuItem>
                  <MenuItem key={Languages['Hindi']} data-cy="Hindi" value={Languages['Hindi']}>
                    {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.hindi))}
                  </MenuItem>
                </TextField>
              </Box>

              <Box mb={1}>
                <TextField
                  onChange={onSearchChange}
                  fullWidth
                  placeholder={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.freeTextSearch))}
                  label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.search))}
                />
              </Box>

              <Box display="flex" justifyContent="space-between">
                {isSkeletonDisplayed ? (
                  <Skeleton width={100} height={36} />
                ) : (
                  <>
                    <Button startIcon={<Add />} onClick={openCreateDialog}>
                      {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.create))}
                    </Button>
                    {isSuperAdmin && (
                      <Button onClick={handleShowAllClick}>
                        <Switch checked={isShowingAll} color="primary" />
                        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.showAll))}
                      </Button>
                    )}
                  </>
                )}
              </Box>

              {isSkeletonDisplayed ? (
                <Box>
                  <Skeleton height={72} />
                  <Skeleton height={72} />
                  <Skeleton height={72} />
                </Box>
              ) : (
                <CategoryList isShowingAll={isShowingAll} language={selectedLanguage} />
              )}
            </Box>
          </Grid>

          <Grid xs={7} md={8} lg={9} item container classes={{ root: classes.section }}>
            <CategoryCard />
          </Grid>
        </Grid>
        <CreateCategoryDialog isOpen={isCreateDialogOpen} onClose={closeCreateDialog} language={selectedLanguage} />
      </Box>
    </DndProvider>
  );
};

export default GlobalAssetsLibraryContent;
