import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Box, InputLabel, MenuItem, Select, TextField, Typography, ListItemIcon, Paper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { IntentFormSelectors, RecognitionSelector, ScenarioSelectors } from 'app/selectors/index.js';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import { getValidationSchema } from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/validationSchema.js';
import type { IntentEditingFormData } from './types.js';
import { yupResolver } from '@hookform/resolvers/yup';
import ResponseSettings from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/ResponseSettings/ResponseSettings.js';
import IntentReachTime from './IntentReachTime.js';
import type { MedicalProtocolActionDto, SceneDto } from '@/generated-api/index.js';
import InlineColorPicker from 'app/components/utils/InlineColorPicker/index.js';
import ProtocolReferenceSettings from './ProtocolReferenceSettings.js';
import UserSaysSettings from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/UserSaysSettings.js';
import IntentNameSettings from './IntentNameSettings.js';
import updateAIResponses = IntentEditingActions.updateAIResponses;
import updateFallbackResponses = IntentEditingActions.updateFallbackResponses;
import GlobalAssets from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/GlobalAssets.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';
import DefaultPersonaSettings from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/DefaultPersonaSettings.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline.js';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff.js';
import FlightLandIcon from '@material-ui/icons/FlightLand.js';
import SettingsIcon from '@material-ui/icons/Settings.js';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}));

const FormElement = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(0),
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
}));

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const blockTypeIcons: Record<CustomNodeTypeEnum, React.ComponentType> = {
  [CustomNodeTypeEnum.Normal]: ChatBubbleOutlineIcon,
  [CustomNodeTypeEnum.Start]: FlightTakeoffIcon,
  [CustomNodeTypeEnum.End]: FlightLandIcon,
  [CustomNodeTypeEnum.Service]: SettingsIcon,
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const IntentEditingForm: FC = () => {
  const intent = useSelector(IntentFormSelectors.intentInEditing);
  const intentProps = useSelector(IntentFormSelectors.intentInEditing)?.properties;

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);

  const isDialogActive = useSelector(RecognitionSelector.isDialogActive);
  const dispatch = useDispatch();

  const scenarios = useSelector(ScenarioSelectors.scenariosSelector);
  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);

  const methods = useForm<IntentEditingFormData>({
    resolver: yupResolver(getValidationSchema()),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      intentPhrases: [],
      aiResponses: [],
      fallbackResponses: [],
      globalAssetIntents: [],
      medicalProtocolActions: [],
    }
  });

  const { reset, control, register, errors, setValue } = methods;

  useEffect(() => {
    if (intent?.id == null) {
      return;
    }

    if (intentProps) {
      reset({
        ...intentProps,
        name: intentProps.name || '',
        intentPhrases: intentProps.intentPhrases || [],
        aiResponses: intentProps.aiResponses || [],
        fallbackResponses: intentProps.fallbackResponses || [],
        globalAssetIntents: intentProps.globalAssetIntents || [],
        medicalProtocolActions: intentProps.medicalProtocolActions || [],
      });
      register(nameof.full<IntentEditingFormData>((v) => v.aiResponses));
      register(nameof.full<IntentEditingFormData>((v) => v.fallbackResponses));
      register(nameof.full<IntentEditingFormData>((v) => v.globalAssetIntents));
    }
  }, [intent?.id, intent?.properties]);

  const onInitializeMedicalProtocolActions = (medicalProtocolActions: MedicalProtocolActionDto[]) => {
    setValue('medicalProtocolActions', medicalProtocolActions);
  };

  const parentScenario = useSelector(ScenarioSelectors.parentScenario);

  const scenarioForSettings: SceneDto | undefined = useMemo(() => {
    return parentScenario ? scenarios[0] : scenarios.find((s) => s.id === currentChartScenario?.id);
  }, [scenarios, currentChartScenario, parentScenario]);

  const canSelectAction = useMemo(() => !!scenarioForSettings?.medicalProtocolChapters?.length, [scenarioForSettings]);

  const selectableBlockTypes = useMemo(() => 
    Object.values(CustomNodeTypeEnum).filter(type => type !== CustomNodeTypeEnum.Service),
    []
  );

  if (intent) {
    return (
      <Box>
        <FormProvider {...methods}>          
            <FormSection>
              <IntentNameSettings
                disabled={isDialogActive}
                intent={intent}
                name={nameof.full<IntentEditingFormData>((f) => f.name)}
                error={errors.name?.message}
                control={control}
              />
            </FormSection>
            <FormSection>
              <InputLabel shrink id="block-type-label">
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentEditingForm.blockType))}
              </InputLabel>
              <StyledSelect
                labelId="block-type-label"
                displayEmpty
                fullWidth
                value={intent.type || CustomNodeTypeEnum.Normal}
                onChange={(e) => dispatch(IntentEditingActions.updateBlockType(e.target.value as CustomNodeTypeEnum))}
                renderValue={(selected) => {
                  const type = (selected as string).toLowerCase();
                  const displayType = type === CustomNodeTypeEnum.Service ? CustomNodeTypeEnum.Normal : type;
                  const Icon = blockTypeIcons[displayType as CustomNodeTypeEnum] || ChatBubbleOutlineIcon;
                  return (
                    <Box display="flex" alignItems="center">
                      <Icon fontSize="small" style={{ marginRight: 8 }} />
                      {capitalizeFirstLetter(displayType)}
                    </Box>
                  );
                }}
              >
                {selectableBlockTypes.map((type) => {
                  const Icon = blockTypeIcons[type] || ChatBubbleOutlineIcon;
                  return (
                    <StyledMenuItem
                      key={intent.id + type}
                      value={type}
                    >
                      <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
                        <Icon fontSize="small" />
                      </ListItemIcon>
                      {capitalizeFirstLetter(type)}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormSection>
            <FormElement>
              <UserSaysSettings
                control={control}
                intent={intent}
                errors={errors.intentPhrases?.map((p) => p?.text) ?? []}
                disabled={isDialogActive}
              />
            </FormElement>
            <FormElement>
              <GlobalAssets disabled={isDialogActive} />
            </FormElement>
            <FormElement>
              <ResponseSettings
                disabled={isDialogActive}
                title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentEditingForm.aiResponse))}
                actionCreator={updateAIResponses}
                fieldName="aiResponses"
              />
            </FormElement>
            <FormElement>
              <ResponseSettings
                disabled={isDialogActive}
                title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentEditingForm.fallbackResponse))}
                actionCreator={updateFallbackResponses}
                fieldName="fallbackResponses"
              />
            </FormElement>
            <FormElement>
              <DefaultPersonaSettings
                title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentEditingForm.persona))}
                scenario={scenarioForSettings}
              />
            </FormElement>
            <FormElement>
              <IntentReachTime intent={intent} disabled={isDialogActive} />
            </FormElement>
            <FormElement>
              {canSelectAction && (
                <Typography>
                  {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentEditingForm.protocolReference))}
                </Typography>
              )}

              <ProtocolReferenceSettings
                scenario={scenarioForSettings}
                intent={intent}
                disabled={isDialogActive}
                canSelectAction={canSelectAction}
                onInitializeMedicalProtocolActions={onInitializeMedicalProtocolActions}
              />
            </FormElement>
            {intent?.type !== CustomNodeTypeEnum.Start && (
              <FormElement>
                <InlineColorPicker
                  colors={['#4CAF50', '#FFEB3B', '#F44336']}
                  currentColor={intent?.properties?.labelColor}
                  disabled={isDialogActive}
                  onSelect={(x: string) => dispatch(IntentEditingActions.changeLabelColor(x))}
                />
              </FormElement>
            )}          
        </FormProvider>
      </Box>
        
    );
  }
  return <div />;
};

export default IntentEditingForm;
