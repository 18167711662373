import { call, debounce, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import { GuiStateActions } from 'app/actions/guiState/guiStateAction.js';
import type { Action, ActionMeta } from 'redux-actions';
import type {
  AddScenarioCommand,
  BlockDto,
  CopyScenarioCommand,
  UpdateScenarioSettingsCommand,
  SceneDto,
  SceneExtendedDto,
  RestoreScenarioVersionCommand,
  SimpleScenarioDto
} from '@/generated-api/index.js';
import { BlockType, Emotion, VoiceResponseUsecase, QualityLevel } from '@/generated-api/index.js';
import type { AxiosResponse } from 'axios';
import { ScenarioClient } from 'app/apis/api.js';
import type { CustomChart } from 'app/models/scenarios/customChart.js';
import { getCenterCoordinates, getNodeDecartPosition } from 'app/utils/flowChart/positionHelpers.js';
import { ScenarioChartStoreActions } from 'app/actions/scenarios/scenarioChartStoreAction.js';
import {
  GuiStateSelectors,
  IntentClipboardSelectors,
  ScenarioChartSelectors,
  ScenarioSelectors
} from '../../../selectors/index.js';
import type ScenarioDetails from '../../../models/scenarios/scenarioDetails.js';
import type ChartDOMPosition from '../../../models/guiState/chartDomPosition.js';
import customHistory from '../../../../core/customHistory.js';
import AppRoutes from '../../../utils/routes.js';
import { ChartMapper, SceneMapper } from '../../../utils/flowChart/mapping/index.js';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import { ScenarioChartEditingActions } from 'app/actions/scenarios/scenarioChartEditingActions.js';
import { IntentActions } from 'app/actions/scenarios/intentAction.js';
import type { IIntentClipboardState } from 'app/reducers/scenarios/intentClipboard/index.js';

import type { LinkedScenario } from 'app/models/intents/intentNodeProperties.js';
import type { UserSaysPhrases, UserSaysPhrasesRequest } from 'app/models/scenarios/scenario.js';

import type { Unwrap } from 'app/types/UtilityType.js';
import logger from 'app/utils/logger.js';

function* getScenarios() {
  yield put(ScenarioActions.setScenariosLoading(true));
  try {
    const response: AxiosResponse<Array<SimpleScenarioDto>> = yield call([
      ScenarioClient,
      ScenarioClient.scenariosFilterScenes as any
    ]);

    yield put(ScenarioActions.setScenarios(response.data));
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(ScenarioActions.setScenariosLoading(false));
  }
}

function* getDetailedScenario(params: Action<ScenarioActions.GetDetailedScenarioPayload>) {
  yield put(ScenarioActions.setIsScenarioOpening(true));
  yield put(ScenarioActions.setDetailedScenarioLoading(true));
  const scenarios: SceneDto[] = yield select(ScenarioSelectors.scenariosSelector);
  try {
    const response: AxiosResponse<SceneDto> = yield call(
      [ScenarioClient, ScenarioClient.scenariosGetScenarioVersion],
      params.payload.sceneId,
      params.payload.versionId
    );

    yield put(ScenarioActions.setIsStreamingEnabledForScenario(response.data.useStreaming));

    const chartDOMInfo: ChartDOMPosition = {
      chartDOMHeight: params.payload.chartDOMHeight,
      chartDOMWidth: params.payload.chartDOMWidth
    };

    yield put(GuiStateActions.setChartParams(chartDOMInfo));

    const chart = ChartMapper.chartFromSceneFull(response.data, chartDOMInfo, scenarios);

    yield put(ScenarioChartStoreActions.initChart(chart));

    // check if there's a clipboard to insert
    const clipboard: IIntentClipboardState = yield select(IntentClipboardSelectors.intentClipboard);
    if (!!clipboard.intentClipboard && clipboard.clipboardTargetId === params.payload.sceneId) {
      yield put(
        IntentActions.setIntentClipboard({
          clipboardTargetId: undefined,
          intentClipboard: undefined
        })
      );

      yield put(IntentActions.addIntent(clipboard.intentClipboard));
    }
    const updatedScenarios = scenarios.map((s) => {
      if (s.id === response.data.id) {
        return {
          ...s,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider as number,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          medicalProtocolChapterIds: response.data.medicalProtocolChapters.map((chapter) => chapter.id),
          transitionScoresSettings: response.data.transitionScoresSettings,
          scenarioContextIntro: response.data.scenarioContextIntro,
          playScenarioContextOn: response.data.playScenarioContextOn,
          sttProvider: response.data.sttProvider,
          contexts: response.data.contexts,
          defaultPersonaId: response.data.defaultPersonaId,
          intentProcessingProvider: response.data.intentProcessingProvider,
          versionId: response.data.versionId,
          llm: response.data.llm,
          useStreaming: response.data.useStreaming,
          maxTokens: response.data.maxTokens
        };
      }
      return s;
    });
    yield put(ScenarioActions.setScenarios(updatedScenarios));
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
    yield put(ScenarioActions.setIsScenarioOpening(false));
  }
}

function* getDetailedScenarioDataByScenarioId(action: Action<UserSaysPhrasesRequest>) {
  try {
    if (action.payload) {
      const { sceneId, versionId, startblockId } = action.payload;

      const response: Unwrap<typeof ScenarioClient.scenariosGetScene> = yield call(
        [ScenarioClient, ScenarioClient.scenariosGetScene],
        sceneId,
        versionId
      );

      if (response.data) {
        
        const startBlock = response.data.blocks?.find((block) => block.id == startblockId);

        if (startBlock) {
          let userSaysPhrases: UserSaysPhrases | null = null;

          userSaysPhrases = {
            scenarioId: sceneId,
            userSaysPhrases: startBlock.intentPhrases?.map((p) => p.text) ?? []
          };
         
          yield put(ScenarioActions.setDetailedScenarioDataByScenarioId(userSaysPhrases));
        }
      }
    }
  } catch (error) {
    logger.log(error);
  }
}

function* saveDetailedScenario() {
  yield put(ScenarioActions.setDetailedScenarioLoading(true));
  try {
    const currentChart: CustomChart = yield select(ScenarioChartSelectors.scenarioChart);
    const chartDOMInfo: ChartDOMPosition = yield select(GuiStateSelectors.chartPositionSelector);
    const scenarioDetails: SceneDto = yield select(ScenarioSelectors.getScenarioById(currentChart.properties.id));
    const scenarios: Array<SceneDto> = yield select(ScenarioSelectors.scenariosSelector);
    const scene = SceneMapper.sceneFromChartAndScenario(currentChart, chartDOMInfo, scenarioDetails, scenarios);
    // scene.companyId = scenarioDetails.companyId;

    // TODO: put saved scenario to the store?
    const response: AxiosResponse<SceneDto> = yield call(
      [ScenarioClient, ScenarioClient.scenariosEditSceneContent],
      scene
    );
    yield put(ScenarioActions.setIsStreamingEnabledForScenario(response.data.useStreaming));
    yield put(ScenarioActions.setScenarioHasNotBeenModified(true));
    yield put(ScenarioActions.setIsCurrentScenarioUpdate(true));

    const chart = ChartMapper.chartFromSceneFull(response.data, chartDOMInfo, scenarios);

    const updatedScenarios = scenarios.map((s) => {
      if (s.id === response.data.id) {
        return {
          ...s,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider as number,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          medicalProtocolChapterIds: response.data.medicalProtocolChapters.map((chapter) => chapter.id),
          transitionScoresSettings: response.data.transitionScoresSettings,
          scenarioContextIntro: response.data.scenarioContextIntro,
          playScenarioContextOn: response.data.playScenarioContextOn,
          sttProvider: response.data.sttProvider,
          contexts: response.data.contexts,
          defaultPersonaId: response.data.defaultPersonaId,
          intentProcessingProvider: response.data.intentProcessingProvider,
          versionId: response.data.versionId,
          llm: response.data.llm,
          useStreaming: response.data.useStreaming,
          maxTokens: response.data.maxTokens
        };
      }
      return s;
    });
    yield put(ScenarioActions.setScenarios(updatedScenarios));

    yield put(ScenarioChartStoreActions.initChart(chart));

    yield put(ScenarioActions.setCurrentScenarioSaveStatus(true));
  } catch (error) {
    logger.error(error);
  } finally {
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
  }
}

function* restoreScenario(params: Action<{ scenarioId: number; versionId: number }>) {
  yield put(ScenarioActions.setDetailedScenarioLoading(true));
  try {
    const chartDOMInfo: ChartDOMPosition = yield select(GuiStateSelectors.chartPositionSelector);
    const scenarios: Array<SceneDto> = yield select(ScenarioSelectors.scenariosSelector);

    const restoreScenarioCommand: RestoreScenarioVersionCommand = {
      scenarioId: params.payload.scenarioId,
      versionId: params.payload.versionId
    };

    // TODO: put saved scenario to the store?
    const response: AxiosResponse<SceneDto> = yield call(
      [ScenarioClient, ScenarioClient.scenariosRestoreScenarioVersion],
      restoreScenarioCommand
    );
    yield put(ScenarioActions.setScenarioHasNotBeenModified(true));
    yield put(ScenarioActions.setIsCurrentScenarioUpdate(true));

    const chart = ChartMapper.chartFromSceneFull(response.data, chartDOMInfo, scenarios);

    const updatedScenarios = scenarios.map((s) => {
      if (s.id === response.data.id) {
        return {
          ...s,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider as number,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          medicalProtocolChapterIds: response.data.medicalProtocolChapters.map((chapter) => chapter.id),
          transitionScoresSettings: response.data.transitionScoresSettings,
          scenarioContextIntro: response.data.scenarioContextIntro,
          playScenarioContextOn: response.data.playScenarioContextOn,
          sttProvider: response.data.sttProvider,
          contexts: response.data.contexts,
          defaultPersonaId: response.data.defaultPersonaId,
          intentProcessingProvider: response.data.intentProcessingProvider,
          versionId: response.data.versionId,
          llm: response.data.llm,
          useStreaming: response.data.useStreaming,
          maxTokens: response.data.maxTokens
        };
      }
      return s;
    });
    yield put(ScenarioActions.setScenarios(updatedScenarios));

    yield put(ScenarioChartStoreActions.initChart(chart));

    yield put(ScenarioActions.setCurrentScenarioSaveStatus(true));
  } catch (error) {
    logger.error(error);
  } finally {
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
  }
}

function* addScenario(params: Action<ScenarioDetails>) {
  logger.log(params.payload);
  // TODO: move this to a unified source of "new intents"
  const startBlockOffset = getNodeDecartPosition({ x: 0, y: 0 });
  const endBlockOffset = getNodeDecartPosition({ x: 500, y: 0 });
  const intentText = '';

  const startBlock: BlockDto = {
    id: 0,
    name: 'Start intent',
    intentPhrases: [{ id: 0, text: intentText }],
    aiResponses: [
      { text: intentText, emotion: Emotion.NUMBER_0, voiceResponseUsecase: VoiceResponseUsecase.NUMBER_0 },
      { text: intentText, emotion: Emotion.NUMBER_0, voiceResponseUsecase: VoiceResponseUsecase.NUMBER_1 }
    ],
    qualityLevel: QualityLevel.NUMBER_3,
    type: BlockType.NUMBER_1, // this is Start type
    posX: startBlockOffset.x,
    posY: startBlockOffset.y,
    medicalProtocolActions: []
  };

  const endBlock: BlockDto = {
    id: 0,
    name: 'End intent',
    intentPhrases: [{ id: 0, text: intentText }],
    aiResponses: [
      { text: intentText, emotion: Emotion.NUMBER_0, voiceResponseUsecase: VoiceResponseUsecase.NUMBER_0 },
      { text: intentText, emotion: Emotion.NUMBER_0, voiceResponseUsecase: VoiceResponseUsecase.NUMBER_1 }
    ],
    qualityLevel: QualityLevel.NUMBER_3,
    type: BlockType.NUMBER_2, // this is End type
    posX: endBlockOffset.x,
    posY: endBlockOffset.y,
    medicalProtocolActions: []
  };

  const chartDomWidthAndHeight: ChartDOMPosition = yield select(GuiStateSelectors.chartPositionSelector);
  const defaultCenterOffset = getCenterCoordinates(
    { x: 0, y: 0 },
    { x: chartDomWidthAndHeight.chartDOMWidth ?? 0, y: chartDomWidthAndHeight.chartDOMHeight ?? 0 },
    1
  );

  const scenarioId = 0;
  const addScene: AddScenarioCommand = {
    id: scenarioId,
    companyId: params.payload.companyId,

    name: params.payload.name,
    langCode: params.payload.langCode,
    sex: params.payload.sex as number,
    voiceName: params.payload.voiceName,
    ttsProvider: params.payload.ttsProvider as number,
    blocks: [startBlock, endBlock],
    blockLinks: [],
    centerX: defaultCenterOffset.x,
    centerY: defaultCenterOffset.y,
    scale: 1,
    medicalProtocolChapters: params.payload.medicalProtocolChapters,
    transitionScoresSettings: params.payload.transitionScoresSettings,
    scenarioContextIntro: params.payload.scenarioContextIntro,
    playScenarioContextOn: params.payload.playScenarioContextOn,
    sttProvider: params.payload.sttProvider,
    llm: params.payload.llm,
    useStreaming: params.payload.useStreaming,
    maxTokens: params.payload.maxTokens
  };

  try {
    const response: AxiosResponse<SceneDto> = yield call([ScenarioClient, ScenarioClient.scenariosAddScene], addScene);

    const scenarios: SceneDto[] = yield select(ScenarioSelectors.scenariosSelector);
    yield put(
      ScenarioActions.setScenarios([
        ...scenarios,
        {
          id: response.data.id,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider,
          startBlockId: startBlock.id,
          companyId: response.data.companyId,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          transitionScoresSettings: response.data.transitionScoresSettings,
          scenarioContextIntro: params.payload.scenarioContextIntro,
          playScenarioContextOn: params.payload.playScenarioContextOn,
          sttProvider: params.payload.sttProvider,
          llm: params.payload.llm,
          useStreaming: params.payload.useStreaming,
          maxTokens: params.payload.maxTokens
        }
      ])
    );

    // redirect to the new scenario
    customHistory.push(`${AppRoutes.Scene}/${response.data.id}`);
  } catch (error) {}
}

function* updateScenarioDetails(params: Action<ScenarioDetails>) {

  yield put(ScenarioActions.setIsStreamingEnabledForScenario(params.payload.useStreaming));
  const scenarioDetails: SceneDto = yield select(ScenarioSelectors.getScenarioById(params.payload.id));
  const editSceneInfo: UpdateScenarioSettingsCommand = {
    ...scenarioDetails,
    name: params.payload.name,
    langCode: params.payload.langCode,
    sex: params.payload.sex as number,
    voiceName: params.payload.voiceName,
    ttsProvider: params.payload.ttsProvider as number,
    medicalProtocolChapters: params.payload.medicalProtocolChapterIds
      ? params.payload.medicalProtocolChapterIds.map((chapter) => {
          const id = chapter as number; // chapter here is really only a number (select box value)
          return { id };
        })
      : [],
    transitionScoresSettings: params.payload.transitionScoresSettings,
    scenarioContextIntro: params.payload.scenarioContextIntro,
    playScenarioContextOn: params.payload.playScenarioContextOn,
    sttProvider: params.payload.sttProvider,
    contexts: params.payload.contexts,
    defaultPersonaId: params.payload.defaultPersonaId,
    intentProcessingProvider: params.payload.intentProcessingProvider,
    description: params.payload.description,
    llm: params.payload.llm,
    useStreaming: params.payload.useStreaming,
    maxTokens: params.payload.maxTokens
  };

  try {
    const response: AxiosResponse<SceneDto> = yield call(
      [ScenarioClient, ScenarioClient.scenariosEditSceneInfo],
      editSceneInfo
    );

    const scenarios: SceneDto[] = yield select(ScenarioSelectors.scenariosSelector);

    const updatedScenarios = scenarios.map((s) => {
      if (s.id === response.data.id) {
        return {
          ...s,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider as number,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          medicalProtocolChapterIds: response.data.medicalProtocolChapters.map((chapter) => chapter.id),
          transitionScoresSettings: params.payload.transitionScoresSettings,
          scenarioContextIntro: params.payload.scenarioContextIntro,
          playScenarioContextOn: params.payload.playScenarioContextOn,
          sttProvider: params.payload.sttProvider,
          contexts: response.data.contexts,
          defaultPersonaId: response.data.defaultPersonaId,
          intentProcessingProvider: response.data.intentProcessingProvider,
          description: response.data.description,
          versionId: response.data.versionId,
          llm: response.data.llm,
          useStreaming: response.data.useStreaming,
          maxTokens: response.data.maxTokens
        };
      }
      return s;
    });

    yield put(ScenarioActions.setScenarios(updatedScenarios));
  } catch (error) {}
}

function* copyScenario(params: Action<CopyScenarioCommand>) {
  const copyScene: CopyScenarioCommand = params.payload;

  try {
    const response: AxiosResponse<SceneExtendedDto> = yield call(
      [ScenarioClient, ScenarioClient.scenariosCopyScene],
      copyScene
    );

    const scenarios: SceneDto[] = yield select(ScenarioSelectors.scenariosSelector);

    yield put(
      ScenarioActions.setScenarios([
        ...scenarios,
        {
          id: response.data.id,
          name: response.data.name,
          sex: response.data.sex as number,
          voiceName: response.data.voiceName,
          langCode: response.data.langCode,
          ttsProvider: response.data.ttsProvider as number,
          medicalProtocolChapters: response.data.medicalProtocolChapters,
          transitionScoresSettings: response.data.transitionScoresSettings,
          startBlockId: response.data.startBlockId,
          companyId: response.data.companyId,
          scenarioContextIntro: response.data.scenarioContextIntro,
          playScenarioContextOn: response.data.playScenarioContextOn,
          sttProvider: response.data.sttProvider,
          contexts: response.data.contexts,
          defaultPersonaId: response.data.defaultPersonaId,
          intentProcessingProvider: response.data.intentProcessingProvider,
          description: response.data.description,
          llm: response.data.llm,
          useStreaming: response.data.useStreaming,
          maxTokens: response.data.maxTokens
        }
      ])
    );

    customHistory.push(`${AppRoutes.Scene}/${response.data.id}`);
  } catch (error) {}
}
function* updateScenarioChart() {
  yield put(ScenarioActions.setScenarioHasNotBeenModified(false));
  yield put(ScenarioActions.setIsCurrentScenarioUpdate(false));
  yield put(ScenarioActions.setCurrentScenarioSaveStatus(false));
}

function* setLinks(params: Action<number>) {
  const nodes = yield select(ScenarioChartSelectors.chartNodes);
  const currentScenario: LinkedScenario = yield select(ScenarioSelectors.currentScenario);
  const selectedScenario = yield select(ScenarioSelectors.getScenarioById(params.payload));

  let temp: LinkedScenario[] = [];

  if (nodes) {
    const allLinks = Object.values(nodes)
      .filter((x: any) => x.properties.linkedScenarios != undefined)
      .map((x: any) => x.properties.linkedScenarios);
    temp = allLinks.reduce((accumulator, currentValue) => [...accumulator, ...currentValue], []);
  }

  if (currentScenario)
    temp.unshift({
      id: 0,
      sceneId: currentScenario?.sceneId ?? 0,
      sceneName: currentScenario?.sceneName ?? '',
      startBlockId: currentScenario?.startBlockId ?? 0
    });
  else {
    temp.unshift({
      id: 0,
      sceneId: selectedScenario?.id ?? 0,
      sceneName: selectedScenario?.name ?? '',
      startBlockId: currentScenario?.startBlockId ?? 0
    });
  }

  yield put(ScenarioActions.setLinkedtScenarios(temp));
  yield put(ScenarioActions.setCurrentTabSelected(0));
}

function* goBackToParentScenario() {
  const parentScenario = yield select(ScenarioSelectors.parentScenario);

  if (parentScenario) {
    const temp = parentScenario;

    const lastElem = temp.pop();

    yield put(ScenarioActions.setParentScenario(temp.length == 0 ? undefined : temp));
    if (lastElem) {
      yield put(ScenarioActions.setCurrentScenario(lastElem));
    }
  }
}

function* initToolbar(params: Action<number>) {
  const selectedScenario: SceneDto = yield select(ScenarioSelectors.getScenarioById(params.payload));
  yield put(ScenarioActions.setParentScenario(undefined));
  yield put(ScenarioActions.setRootScenario(selectedScenario));

  // TODO: current scenario is not being reset of route change
  yield put(
    ScenarioActions.setCurrentScenario({
      id: 0,
      sceneId: selectedScenario?.id ?? 0,
      sceneName: selectedScenario?.name ?? '',
      isScenarioSaved: true,
      startBlockId: selectedScenario?.startBlockId ?? 0,
      companyId: selectedScenario?.companyId,
      versionId: selectedScenario.versionId
    })
  );
}

function* switchCurrentScenarioTo(params: Action<number>) {
  yield put(ScenarioChartStoreActions.onShowIntentEdit(false));
  const links = yield select(ScenarioSelectors.linkedScenarios);
  const parentScenario = yield select(ScenarioSelectors.parentScenario);

  let temp = parentScenario;
  if (!Array.isArray(temp)) temp = [];

  temp.push(links[0]);

  yield put(ScenarioActions.setParentScenario(temp));
  yield put(ScenarioActions.setCurrentScenario(links[params.payload]));
}

function* deleteScenario(params: ActionMeta<{ id: number }, { navigationAction: () => void }>) {
  yield put(ScenarioActions.setDetailedScenarioLoading(true));
  const scenarios: SceneDto[] = yield select(ScenarioSelectors.scenariosSelector);

  try {
    yield call([ScenarioClient, ScenarioClient.scenariosRemoveScene], params.payload.id);
    params.meta.navigationAction();
    yield put(ScenarioActions.setCurrentScenario(undefined));
    yield put(ScenarioActions.setScenarios(scenarios.filter((x) => x.id != params.payload.id)));
    yield getScenarios();
  } catch (error) {
    logger.log('deleteScenario error:' + error);
  } finally {
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
  }
}

function* watchSaveDetailedScenario() {
  yield takeLatest(ScenarioActions.Type.SAVE_DETAILED_SCENARIO, saveDetailedScenario);
}

function* watchGetDetailedScenario() {
  yield takeLatest(ScenarioActions.Type.GET_DETAILED_SCENARIO, getDetailedScenario);
}

function* watchGeDetailedScenariosDataByScenarioId() {
  yield takeEvery(ScenarioActions.Type.GET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID, getDetailedScenarioDataByScenarioId);
}

function* watchGetScenarios() {
  yield takeLatest(ScenarioActions.Type.GET_SCENARIOS, getScenarios);
}

function* watchAddScenario() {
  yield takeLatest(ScenarioActions.Type.ADD_SCENARIO, addScenario);
}

function* watchUpdateScenarioDetails() {
  yield takeLatest(ScenarioActions.Type.UPDATE_SCENARIO_DETAILS, updateScenarioDetails);
}

function* watchRestoreScenario() {
  yield takeLatest(ScenarioActions.Type.RESTORE_SCENARIO, restoreScenario);
}

function* watchCopyScenario() {
  yield takeLatest(ScenarioActions.Type.COPY_SCENARIO, copyScenario);
}

function* watchUpdateScenarioChartElements() {
  yield debounce(
    300,
    [
      ...Object.entries(IntentEditingActions.Type).map((pair) => Object.values(pair)[1]),
      ScenarioChartEditingActions.Type.ON_LINK_COMPLETE,
      ScenarioChartEditingActions.Type.ON_DELETE_SELECTED,
      IntentActions.Type.ADD_INTENT,
      IntentActions.Type.REMOVE_INTENT
    ],
    updateScenarioChart
  );
}

function* watchLinks() {
  yield takeLatest(ScenarioActions.Type.SET_LINKS, setLinks);
}

function* watchGoBack() {
  yield takeLatest(ScenarioActions.Type.GO_BACK_TO_PARENT_SCENARIO, goBackToParentScenario);
}

function* watchInitToolBar() {
  yield takeLatest(ScenarioActions.Type.INIT_TOOLBAR, initToolbar);
}

function* watchOnTabClick() {
  yield takeLatest(ScenarioActions.Type.SWITCH_CURRENT_SCENARIO_TO, switchCurrentScenarioTo);
}

function* watchDeleteScenario() {
  yield takeLatest(ScenarioActions.Type.DELETE_SCENARIO, deleteScenario);
}

export default function* watchAllScenarioSagas() {
  yield fork(watchSaveDetailedScenario);
  yield fork(watchRestoreScenario);
  yield fork(watchGetDetailedScenario);
  yield fork(watchGeDetailedScenariosDataByScenarioId);
  yield fork(watchGetScenarios);
  yield fork(watchUpdateScenarioChartElements);
  yield fork(watchAddScenario);
  yield fork(watchUpdateScenarioDetails);
  yield fork(watchCopyScenario);
  yield fork(watchLinks);
  yield fork(watchDeleteScenario);
  yield fork(watchGoBack);
  yield fork(watchInitToolBar);
  yield fork(watchOnTabClick);
}
