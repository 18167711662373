import type { RoleId } from '../users/role.js';

export default interface UserManaged {
  // TODO Use some Dto model instead??!?
  id: number;
  name: string;
  phone?: string | null;
  role: RoleId;
  email: string;
  companyId: number;
  companyName: string;
  deleted: boolean;
  departmentId?: number | null;
  hideScenarioContent: boolean;
  showCadForm: boolean;
}

export enum InvitationStatus {
  Invited = 0,
  Expired = 1,
  Accepted = 2,
  Revoked = 3,
}

export interface Invitation {
  id: number;
  inviteeEmail: string;
  companyId: number | null;
  departmentId: number | null;
  inviterId: number;
  createdTime: string | null;
  expirationTime: string | null;
  acceptedTime: string | null;
  invitationStatus: InvitationStatus;
}
