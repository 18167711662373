import UserManaged from '../../models/userManagement/userManaged.js';
import type { Invitation } from '../../models/userManagement/userManaged.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import type { Action } from 'app/types/ReduxHelperTypes.js';
import { produce } from 'immer';

export interface IUserManagementState {
  users: UserManaged[];
  usersLoaded: boolean;
  invitations: Invitation[];
}

const initialState: IUserManagementState = {
  users: [],
  usersLoaded: false,
  invitations: []
};

export const userManagementReducer = (
  state: IUserManagementState = initialState,
  action: Action<UserManagementActions.Type, UserManagementActions.PayloadType>
): IUserManagementState => {
  switch (action.type) {
    case UserManagementActions.Type.SET_USERS: {
      return { ...state, users: action.payload };
    }

    case UserManagementActions.Type.SET_USERS_LOADED: {
      return {
        ...state,
        usersLoaded: action.payload
      };
    }

    case UserManagementActions.Type.USER_UPDATED: {
      return {
        ...state,
        users: produce(state.users, (users) => {
          const index = users.findIndex((u) => u.id === action.payload.id);
          users[index] = (action.payload as unknown) as UserManaged;
        })
      };
    }

    case UserManagementActions.Type.USER_DELETED: {
      return {
        ...state,
        users: produce(state.users, (users) => users.filter((u) => u.id !== action.payload))
      };
    }

    case UserManagementActions.Type.SET_INVITATIONS: {
      return {
        ...state,
        invitations: action.payload
      };
    }
  }

  return state;
};
