import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles } from '@material-ui/core/styles/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

interface VideoData {
  id: number;
  title: string;
  videoUrl: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    textAlign: 'center'
  },
  link: {
    display: 'block',
    padding: theme.spacing(1)
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  }
}));

const memberGuides: VideoData[] = [
  {
    id: 1,
    title: 'How to run scenarios',
    videoUrl: 'https://www.youtube.com/watch?v=usY88ed6Tl0'
  },
  {
    id: 2,
    title: 'How to view your KPI score',
    videoUrl: 'https://www.youtube.com/watch?v=MlVhIGPKHt8'
  },
  {
    id: 3,
    title: 'How to use HiSklls to practice use of procedures',
    videoUrl: 'https://www.youtube.com/watch?v=ZVGYZ04h3hQ'
  },
  {
    id: 4,
    title: 'How to access a course',
    videoUrl: 'https://www.youtube.com/watch?v=wpKLoKvrk-o'
  },
  {
    id: 5,
    title: 'How to enable the microphone in Google Chrome',
    videoUrl: 'https://www.youtube.com/watch?v=YWSbAreHlxo'
  },
  {
    id: 6,
    title: 'How to get a transcript from your training session',
    videoUrl: 'https://www.youtube.com/watch?v=1gfo-5vHQlU'
  }
];

const adminGuides: VideoData[] = [
  {
    id: 1,
    title: 'How to invite new users to your organization',
    videoUrl: 'https://www.youtube.com/watch?v=KgJVUU64EIo'
  },
  {
    id: 2,
    title: 'How to set up a course',
    videoUrl: 'https://www.youtube.com/watch?v=TlhQgAdsDcI'
  },
  {
    id: 3,
    title: 'How to give users access to courses',
    videoUrl: 'https://www.youtube.com/watch?v=RbrG6M-Xe8Y'
  },
  {
    id: 4,
    title: 'How to send email reminders to course attendees',
    videoUrl: 'https://www.youtube.com/watch?v=KpDwcpmBgQo'
  },
  {
    id: 5,
    title: 'How to schedule calls',
    videoUrl: 'https://www.youtube.com/watch?v=baWgbrd1tzI'
  },
  {
    id: 6,
    title: 'How to use the changelog',
    videoUrl: 'https://www.youtube.com/watch?v=EV_u8p7czYs'
  },
  {
    id: 7,
    title: 'How to use the NextGen AI engine',
    videoUrl: 'https://www.youtube.com/watch?v=RqUqzrfUHJE'
  },
  {
    id: 8,
    title: 'How to guide the AI with context',
    videoUrl: 'https://www.youtube.com/watch?v=9xeATD6aGjY'
  },
  {
    id: 9,
    title: 'How to use AI personas',
    videoUrl: 'https://www.youtube.com/watch?v=Afi2J7-ahFE'
  },
  {
    id: 10,
    title: 'How to set up KPI procedure scores',
    videoUrl: 'https://www.youtube.com/watch?v=KrbE3OSg90k&feature=youtu.be'
  },
  {
    id: 11,
    title: 'How to add samples to global intents',
    videoUrl: 'https://www.youtube.com/watch?v=hvhYnIuGCRE'
  },
  {
    id: 12,
    title: 'How to change the voice in a scenario',
    videoUrl: 'https://www.youtube.com/watch?v=LNfDERhWdtQ'
  },
  {
    id: 13,
    title: 'How to modify AI responses and scenario facts',
    videoUrl: 'https://www.youtube.com/watch?v=ykEtgCZyvQ4'
  },
  {
    id: 14,
    title: 'How to create a folder structure',
    videoUrl: 'https://www.youtube.com/watch?v=dpmslaGQoK4'
  },
  {
    id: 15,
    title: 'How to hide intent texts when playing a scenario',
    videoUrl: 'https://www.youtube.com/watch?v=J_KfCCe2E2Y'
  },
  {
    id: 16,
    title: 'How to add and remove an intent',
    videoUrl: 'https://www.youtube.com/watch?v=NuWbg5iKVeI'
  },
  {
    id: 17,
    title: 'How to hide intent content for users',
    videoUrl: 'https://www.youtube.com/watch?v=kGr0A-kRSV4'
  },
  {
    id: 18,
    title: 'How to schedule a call',
    videoUrl: 'https://www.youtube.com/watch?v=x4-DfrMyHdU'
  },
  {
    id: 19,
    title: 'How to create and use a new global intent',
    videoUrl: 'https://www.youtube.com/watch?v=migi1sW4lDY'
  }
];

const UserGuidesPage: React.FC = () => {
  const classes = useStyles();
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Box m={4} pt={4}>
      <Typography variant="h4" component="h1" className={classes.pageTitle}>
        {translateCommon(nameof.full<I18nCommonNs>((n) => n.navigation.userGuides))}
      </Typography>
      <Typography variant="h5" component="h2" className={classes.sectionTitle}>
        {translateCommon(nameof.full<I18nCommonNs>((n) => n.memberGuides))}
      </Typography>
      {memberGuides.map((video) => (
        <Link
          key={video.id}
          component="a"
          href={video.videoUrl}
          target="_blank"
          rel="noopener"
          variant="h6"
          className={classes.link}
        >
          {video.title}
        </Link>
      ))}
      <Typography variant="h5" component="h2" className={classes.sectionTitle}>
        {translateCommon(nameof.full<I18nCommonNs>((n) => n.adminGuides))}
      </Typography>
      {adminGuides.map((video) => (
        <Link
          key={video.id}
          component="a"
          href={video.videoUrl}
          target="_blank"
          rel="noopener"
          variant="h6"
          className={classes.link}
        >
          {video.title}
        </Link>
      ))}
    </Box>
  );
};

export default UserGuidesPage;
